import { connect } from "react-redux";
import { Screen } from "./screen";
const {
  defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);
const { changePassword } = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
  return {
    adminDetails: state.UserReducer.adminDetails
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data, success, error) => dispatch(changePassword(data, success, error))
  };
};
export const ProfileScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);
