export const FETCH_UNBLOCK_REQUEST_LIST = 'FETCH_UNBLOCK_REQUEST_LIST';
export const SAVE_UNBLOCK_REQUEST_LIST = 'SAVE_UNBLOCK_REQUEST_LIST';
export const UPDATE_BLOCK_STATUS = 'UPDATE_BLOCK_STATUS';
export const FETCH_USER_TEST_LIST = 'FETCH_USER_TEST_LIST';
export const SAVE_USER_TEST_LIST = 'SAVE_USER_TEST_LIST';
export const SEND_TEST_REMINDER = "SEND_TEST_REMINDER";

export const fetchUnblockRequestList = (data, success, failure) => {
    return {
        type: FETCH_UNBLOCK_REQUEST_LIST,
        data,
        success,
        failure
    }
};

export const saveUnblockRequestList = data => {
    return {
        type: SAVE_UNBLOCK_REQUEST_LIST,
        data
    }
};

export const updateBlockStatus = (data, success, failure) => {
    return {
        type: UPDATE_BLOCK_STATUS,
        data, success, failure
    }
};

export const fetchUsersTestList = (data, success, failure) => {
    return {
        type: FETCH_USER_TEST_LIST,
        data, success, failure
    }
};

export const saveUsersTestList = data => {
    return {
        type: SAVE_USER_TEST_LIST,
        data
    }
}

export const sendTestReminder = (data, success, failure) => {
    return {
        type: SEND_TEST_REMINDER,
        data,
        success,
        failure
    };
}