export const FETCH_ALL_USERS_STATS = 'FETCH_ALL_USERS_STATS';
export const FETCH_CHAMPIONSHIP_USER_STATS = 'FETCH_CHAMPIONSHIP_USER_STATS';
export const SAVE_CHAMPIONSHIP_USER_STATS = 'SAVE_CHAMPIONSHIP_USER_STATS';
export const FETCH_USERS_REQUESTS = 'FETCH_USERS_REQUESTS';
export const SAVE_USERS_LIST = 'SAVE_USERS_LIST';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const UPDATE_APPROVAL_STATUS = 'UPDATE_APPROVAL_STATUS';
export const SAVE_USERS_REQUEST_LIST = 'SAVE_USERS_REQUEST_LIST';
export const UPLOAD_CSV_FILE = 'UPLOAD_CSV_FILE';
export const UPDATE_ACTIVATION_STATUS = 'UPDATE_ACTIVATION_STATUS';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const RE_SEND_INVITE = 'RE_SEND_INVITE';
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const UPDATE_AMBASSADOR_ACCESS = "UPDATE_AMBASSADOR_ACCESS";
export const ADD_USER_TYPE = 'ADD_USER_TYPE';
export const EDIT_USER_TYPE = 'EDIT_USER_TYPE';
export const FETCH_USER_TYPES = 'FETCH_USER_TYPES';
export const SAVE_AMBASSADORS_LIST = 'SAVE_AMBASSADORS_LIST';
export const FETCH_AMBASSADORS_LIST = 'FETCH_AMBASSADORS_LIST';
export const SAVE_USER_TYPE_LIST = 'SAVE_USER_TYPE_LIST';
export const GET_HOLDING_AREA_USERS = 'GET_HOLDING_AREA_USERS';
export const SAVE_HOLDING_AREA_USERS = 'SAVE_HOLDING_AREA_USERS';
export const FETCH_HOLDING_ROLES = 'FETCH_HOLDING_ROLES';
export const SAVE_HOLDING_ROLES = 'SAVE_HOLDING_ROLES';
export const SAVE_ADMIN_INFO = 'SAVE_ADMIN_INFO';

export const addNewUserType = (data, success, failure) => {
    return {
        type: ADD_USER_TYPE,
        data, success, failure
    }
};

export const editUserType = (data, success, failure) => {
    return {
        type: EDIT_USER_TYPE,
        data, success, failure
    }
};

export const fetchUserTypes = (success, failure) => {
    return {
        type: FETCH_USER_TYPES,
        success, failure
    }
};

export const saveUserTypeList = (data) => {
    return {
        type: SAVE_USER_TYPE_LIST,
        data
    }
}

export const fetchUserInfo = (id, success, error) => {
    return {
        type: FETCH_USER_INFO,
        id,
        success,
        error
    }
};

export const saveUserInfo = (data) => {
    return {
        type: SAVE_USER_INFO,
        data
    }
}

export const saveAdminInfo = (data) => {
    return {
        type: SAVE_ADMIN_INFO,
        data
    }
}

export const fetchAllUsersStats = () => {
    return {
        type: FETCH_ALL_USERS_STATS
    }
}

export const fetchChampionshipUserStats = () => {
    return {
        type: FETCH_CHAMPIONSHIP_USER_STATS,
    }
};

export const saveChampionshipUserStats = (data) => {
    return {
        type: SAVE_CHAMPIONSHIP_USER_STATS,
        data
    }
};

export const fetchUsersRequests = (data, success, failure) => {
    return {
        type: FETCH_USERS_REQUESTS,
        data,
        success,
        failure
    }
};

export const saveUsersRequestList = (data) => {
    return {
        type: SAVE_USERS_REQUEST_LIST,
        data
    }
};

export const fetchUsersList = (data, success, failure) => {
    return {
        type: FETCH_USERS_LIST,
        data,
        success,
        failure
    }
};

export const saveUsersList = (data) => {
    return {
        type: SAVE_USERS_LIST,
        data
    }
};

export const updateApprovalStatus = (data, success) => {
    return {
        type: UPDATE_APPROVAL_STATUS,
        data,
        success
    }
};

export const uploadCSV = (formData, success, error) => {
    return {
        type: UPLOAD_CSV_FILE,
        formData,
        success,
        error
    }
};

export const updateActivationStatus = (data, success) => {
    return {
        type: UPDATE_ACTIVATION_STATUS,
        data,
        success
    }
};

export const addNewUser = (data, success, failure) => {
    return {
        type: ADD_NEW_USER,
        data,
        success,
        failure
    }
};

export const reSendInvite = (data, success, failure) => {
    return {
        type: RE_SEND_INVITE,
        data,
        success,
        failure
    }
}

export const updateAmbassadorAccess = (data) => {
    return {
        type: UPDATE_AMBASSADOR_ACCESS,
        data: data
    };

}

export const fetchAmbassadorsList = (data, success, failure) => {
    return {
        type: FETCH_AMBASSADORS_LIST,
        data,
        success,
        failure
    }
};

export const saveAmbassadorsList = (data) => {
    return {
        type: SAVE_AMBASSADORS_LIST,
        data
    }
};

export const getHoldingAreaUsers = (data, success, failure) => {
    return {
        type: GET_HOLDING_AREA_USERS,
        data,
        success,
        failure
    }
};

export const saveHoldingAreaUser = data => {
    return {
        type: SAVE_HOLDING_AREA_USERS,
        data
    }
};

export const fetchHoldingRoles = (success, failure) => {
    return {
        type: FETCH_HOLDING_ROLES,
        success,
        failure
    }
};

export const saveHoldingRoles = data => {
    return {
        type: SAVE_HOLDING_ROLES,
        data
    }
}