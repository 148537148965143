module.exports = `<style>
	.privacy_policy {margin:20px 0;}
	.privacy_policy p, .privacy_policy li{font-size: 16px;}
	.privacy_policy .top_head{width: 100%; float: left; text-align: center; margin-bottom: 1rem;}
	.privacy_policy .top_head h1{line-height: 30px;font-size: 1.8rem;}
	.privacy_policy li, .privacy_policy p{line-height: 30px; width: 100%; float: left;}
	.privacy_policy table {border-collapse: collapse;}
	.privacy_policy table, .privacy_policy th, .privacy_policy td {border-width: 1px;border-style: dotted;border-color: #c9c9c9; border-image: initial; line-height: 30px;padding: 15px; text-align: left;}
	.privacy_policy h4, .privacy_policy h2, .privacy_policy h1{color: #4d4d4f; display:block; margin: 0.6rem 0; font-weight: 700;}
	.privacy_policy ul {list-style-type: circle; padding-top: 0.6em;}
	.privacy_policy ul > li {display: table; margin: 0.4em 0; position: relative; padding-left: 25px;}
	.privacy_policy ul > li::before {content: ""; position: absolute; left: 0; padding:4px; border-radius: 50px; top: 11px;background: #4d4d4f;}
	.privacy_policy ol {padding-inline-start: 40px;padding-top: 0.6em;}
	.privacy_policy ol li{list-style-type: upper-roman; }
	.privacy_policy a{color: #f22736;font-weight: 700;} 
	@media (max-width: 768px){
.privacy_policy .top_head h1{font-size: 1.2rem;}
	}
	</style>

<div class="privacy_policy">
<div class="container">
	<div class="row">
		<div class="top_head">
			<h1>WELCOME TO SEF RACE CONTROL (“SEF”) PRIVACY POLICY.</h1>
		</div>
		<p>Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data.   Please read this Privacy Policy carefully and do not install the mobile application device (the <strong>“App”</strong>)or the SEF Race Control platform made available by us, and any associated services (together, the <strong>“Service”</strong>) if you do not agree to its terms.</p>
		
		<h4>WHO WE ARE</h4>
		<p>The Service is provided by SEF Racing Limited (<strong>“SEF”</strong>, <strong>“us” </strong>or <strong>“we”</strong>). We are a limited company registered in England and Wales under company number 11341871 whose registered office is at 239 Kensington High Street 1st Floor, London, England, W8 6SN.</p>
		<p>In this Privacy Policy, <strong>the “Company” “we”, or “our”</strong> means:
			<ul>
				<li>SEF Racing Limited, company number 11341871</li>
				<li>Sean Edwards Foundation, charity registration number 1173947 </li>
			</ul>
		</p>
		<h4>HOW WE COLLECT INFORMATION ABOUT YOU </h4>
		<p><strong>Information you provide to us directly</strong> <br/>This is information including Identity, Contact and Communications Data that you consent to giving us by completing a form through the App, or by corresponding with us (for example by e mail, or a chat service).   It also includes information that you provide when you register to use the App, share data on the App’s social media functions, subscribe to any of our Services, or enter a competition, promotion or survey.</p>
		<p>
			Information you provide to us indirectly
			<ul>
				<li><strong>Information we collect about you and your Device.</strong> Each time you use the App, we will automatically collect personal data including Device, Content and Usage Data. We collect this data using cookies and other similar technologies. 
				</li>
				<li><strong>Location Data.</strong> We also use GPS technology to determine your current location. Some of our location-enabled Services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time by disabling Location Data in your settings.
				</li>
			</ul>
		</p>
		<p>
			Information from other sources  
			<ul>
			<li><strong>Information we receive from other sources including third parties and publicly available sources.</strong> We will receive personal data about you from various third parties as set out below:</li>
			<li>Device Data from the following parties:  analytics providers (such as Google), advertising networks based in and outside the EU, search information providers based in and outside the EU.</li>
			<li>Contact Data from providers of technical and delivery services.</li>
			</ul>
		</p>
		<h4>WHAT PERSONAL INFORMATION WE COLLECT</h4>
		<p>
			We may collect, store and use the following kinds of personal information:
			<ul>
				<li>your name;</li>
				<li>your contact details (including postal address, telephone number, e-mail address and/or social media identity);</li>
				<li>your date of birth;</li>
				<li>your gender;</li>
				<li><strong>if you are a Driver,</strong> information to complete your driver profile.  This will contain detailed sensitive personal information (known as <strong>‘special category personal data’</strong>) –please see below for further information.</li>
				<li>Device Data;</li>
				<li>Content Data;</li>
				<li>Profile Data;</li>
				<li>Usage Data;</li>
				<li>Marketing and Communications Data;</li>
				<li>Location Data; and</li>
				<li>any other personal information you provide to us. </li> 
			</ul>
		</p>
		<h4>SPECIAL CATEGORY PERSONAL DATA (SCPD)</h4>
		<p>Some of the personal information we collect and process for <strong>Drivers </strong>will include health data such as blood type, medical history and next of kin in case of emergency.   The General Data Protection Regulation (EU Regulation 2016/679) <strong>(GDPR) </strong>imposes tighter restrictions on how we may use this information.   Part 4 of Schedule 1 of the Data Protection Act 2018 requires us to explain how and why we use these special categories of personal information.</p>
		<p><strong>Why do we process SCPD?</strong>   
			We process SCPD in order to:
			<ol>
			<li> provide services to you, and to meet our goal of improving track safety; </li>
			<li> provide relevant information to team participants; and</li>
			<li> comply with our legal obligations.</li>
			</ol>
		</p>
		<p><strong>What lawful basis do we have to process SCPD? </strong> We will only process SCPD relating to your medical history where the processing is necessary so that we can provide our services to you, where we have a legitimate interest in doing so or where we have otherwise obtained your consent to such processing.</p>
		<p>How we will safeguard SCPD   We have procedures in place to ensure that we only process SCPD in accordance with the law.  In particular we ensure that:
		<ol>
			<li> SCPD is processed lawfully, fairly and in a transparent manner in accordance with this Privacy Policy.</li>
			<li> we only collect SCPD for specified, explicit and legitimate purposes and we only process the SCPD for these purposes as set out in this Privacy Policy.</li>
			<li> any SCPD we collect is adequate, relevant and limited to what is necessary in order to provide you with our services in relation to track safety;</li>
			<li> this SCPD is accurate and kept up to date as we require you to input your SCPD on the App;</li>
			<li> we keep this SCPD in a form which allows identification; and </li>
			<li> we keep this SCPD secure as we have put in place appropriate security measures to protect SCPD from being accidentally lost, used or accessed in an unauthorised way. </li>
		</ol>
		</p>
		<p><strong>Retention of SCPD:</strong>  We keep SCPD for up to six (6) years following the date on which it is provided to us or only for as long as is reasonably necessary thereafter in order to:
		<ol>
			<li> respond to any emergency incidents;</li>
			<li> provide such information to Team Participants, medical personnel and a driver’s next of kin; and</li>
			<li> establish, exercise or defend legal claims in accordance with statutory limitation periods.</li>
		</ol>
		</p>
		<h4>COOKIES</h4>
		<p>We use cookies to distinguish you from other users of the Service, and to remember your preferences. This helps us to provide you with the best experience when you use the Service. Please refer to our cookies policy at (<a href="https://sefracing.com/cookies-policy">https://sefracing.com/cookies-policy</a>) for further information.</p>
		<h4>HOW WE USE YOUR PERSONAL DATA</h4>
			<p>We will only use your personal data when the law allows us to do so. Most commonly we will use your personal data in the following circumstances: 
				<ul>
					<li>register your profile on the App;</li>
					<li>provide you with the information you asked for;</li>
					<li>keep a record of your relationship with us;</li>
					<li>respond to or fulfil any requests, complaints or queries you make to us;</li>
					<li>manage events in which you have expressed an interest;</li>
					<li>contact you if enter your details onto one of our online forms; </li>
					<li>ensure that our Team Participants have up to date information on Drivers;</li>
					<li>where we need to perform a contract that we are about to enter or have entered with you;</li>
					<li>where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; and </li>
					<li>where we need to comply with a legal or regulatory obligation. </li>
				</ul>
			</p>
			<p>Data protection laws mean that each use we make of personal information must have a “legal basis”.  The relevant legal bases are set out in the GDPR and in current UK data protection legislation.</p>
			<p><strong>Specific consent</strong>
			Consent is where we ask you if we can use your information in a certain way, and you agree to this.  Where we use your information for a purpose based on consent, you have the right to withdraw consent for any future use of your information for this purpose at any time.</p>
			<p><strong>Legal obligation</strong>
			We have a basis to use your personal information where we need to do so to comply with one of our legal or regulatory obligations.  For example, in some cases we may need to share your information with our various regulators such as the Charity Commission, Information Commissioner or to use information we collect about you for due diligence or ethical screening purposes.</p>
			<p><strong>Legitimate interests</strong>
				We have a basis to use your personal information if it is reasonably necessary for us (or others) to do so and in our/their “legitimate interests” (provided that what the information is used for is fair and does not unduly impact your rights).
				Some examples not mentioned under the other bases above where we are relying on legitimate interests are:
				<ul>
					<li>use of personal information to administer, review and keep an internal record of the people we work with, including medical staff and event organisers and sponsors; and </li>
					<li>sharing of personal information between Authorized Users.</li>
				</ul>
			</p>
			<p>We only rely on legitimate interests where we consider that any potential impact on you (positive and negative), how intrusive it is from a privacy perspective and your rights under data protection laws do not override our (or others’) interests in us using your information in this way.</p>
			<h4>HOW WE KEEP YOUR INFORMATION SAFE </h4>
			<p>We, and our suppliers, ensure that there are appropriate technical and organisational controls (including physical, electronic and managerial measures) in place to protect your personal information.</p>
			<h4>HOW LONG FOR? </h4>
				<p>We take into account various criteria when determining the appropriate retention period for personal data including: 
				<ul>
					<li>the purposes for which we process your personal data and how long we need to keep the data to achieve these purposes;</li>
					<li>how long personal data is likely to remain accurate and up-to-date; and </li>
					<li>any applicable legal, accounting, reporting or regulatory requirements which specify how long certain records must be kept.  </li>
				</ul>
				</p>
				<p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
				<p>In the event that you do not use the App for a period of twelve (12) months then we will treat the account as expired and your personal data may be deleted.</p>
				<h4>SHARING YOUR INFORMATION </h4>
				<p>We will never sell or rent your information to third parties for marketing purposes.  However we may disclose your information to third parties in connection with the other purposes set out in this Privacy Policy.  These third parties may include:
					<ul>
						<li>medical staff who require access to the information in order to provide assistance to a Driver or Marshall;</li>
						<li>business partners, suppliers and sub-contractors who may process information on our behalf;</li>
						<li>organisations with whom we undertake joint fundraising campaigns; </li>
						<li>approved promoters and event organisers; and </li>
						<li>IT service providers.</li>
					</ul>
				</p>
				<p>Where we are under a legal or regulatory duty to do so, we may disclose your details to the police, regulatory bodies or legal advisors, and/or, where we consider this necessary, to protect our rights, property or safety.</p>
				<p>We reserve the right to disclose your personal information to third parties:
				<ol>
					<li>if we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets; and/or</li>
					 
					<li>if substantially all of our assets are acquired by a third party, personal information held by us may be one of the transferred assets.</li>
					</ol>
				</p>				
				<h4>INTERNATIONAL TRANSFERS</h4>
				<p>Many of our external third parties are based outside the EEA so their processing of your personal data will involve a transfer of data outside the EEA.Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it.  We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission.</p>
				<h4>YOUR RIGHTS</h4> 
				<p>Under UK data protection law, you have rights over personal information that we hold about you.  We’ve summarised these below:
				<strong>Right to access your personal information</strong>
				You have a right to request access to the personal data that we hold about you. You also have the right to request a copy of the information we hold about you, and we will provide you with this unless legal exceptions apply.   If you want to access your information, send a description of the information you want to see by post to Lyndsey Cook, Sean Edwards Foundation, 96 Kensington High Street, London W8 4SG.
				</p>
				<p><strong>Right to have your inaccurate personal information corrected</strong>
				You have the right to have inaccurate or incomplete information we hold about you corrected.  If you believe that the information we hold about you is inaccurate or incomplete, please provide us with details and we will investigate and, where applicable, correct any inaccuracies.  
				</p>
				<p><strong>Right to restrict use of your personal information</strong>
				You have a right to ask us to restrict the processing of some or all of your personal information in the following situations:  if some information we hold on you isn’t right; we’re not lawfully allowed to use it; you need us to retain your information in order for you to establish, exercise or defend a legal claim; or you believe your privacy rights outweigh our legitimate interests to use your information for a particular purpose and you have objected to us doing so.</p>
				<p><strong>Right to erasure of your personal information</strong>
				You may ask us to delete some or all of your personal information and in certain cases, and subject to certain exceptions, you have the right for this to be done. If we are unable to delete your information, we will explain why this is the case.</p>
				<p><strong>Right for your personal information to be portable</strong>
				If we are processing your personal information (1) based on your consent, or in order to enter into or carry out a contract with you, and (2) the processing is being done by automated means, you may ask us to provide it to you or another service provider in a machine-readable format.</p>
				<p><strong>Right to object to the use of your personal information</strong>
				If we are processing your personal information based on our legitimate interests and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
				</p>
				<p>If we are processing your personal information for direct marketing purposes, and you wish to object, we will stop processing your information for these purposes as soon as reasonably possible.
				</p>
				<p>If you want to exercise any of the above rights, please contact us by email at pa@seanedwardsfoundation.com.  We may be required to ask for further information and/or evidence of identity. We will endeavour to respond fully to all requests within one month of receipt of your request, however if we are unable to do so we will contact you with reasons for the delay.</p>
				<p>Please note that exceptions apply to a number of these rights, and not all rights will be applicable in all circumstances. For more details we recommend you consult the guidance published by the <a href="http://www.ico.org.uk/">UK’s Information Commissioner’s Office (ICO).</a>) </p>
				<h4>Complaints</h4>
				<p>If you are unhappy with any aspect of how we are using your personal information, please e mail us at:  <a href="mailto:pa@seanedwardsfoundation.com">pa@seanedwardsfoundation.com  </a>
				You also have the right to lodge a complaint about any use of your information with the Information Commissioners Office, the UK data protection regulator, or other competent supervisory authority of an EU member state if the App is downloaded outside the United Kingdom.</p>
				<h4>CHANGES TO THE POLICY </h4>
				<p>We may change this Privacy Policy from time to time.  If we make any significant changes in the way we treat your personal information we will make this clear on the App or by contacting you directly.</p>
				<h4>CONTACT US </h4>
				<p>By mail: Lyndsey Cook, Sean Edwards Foundation, 96 Kensington High Street, London W8 4SG.
				By e mail: pa@seanedwardsfoundation.com  </p>
				<h4>GLOSSARY</h4>
				<div class="table-responsive">
                  <table>
                     <tbody>
                        <tr>
                           <th>Administrator</th>
                           <td>an organisation with administrator rights over your use of the Service.</td>
                        </tr>
						<tr>
                           <th>Authorised Users  </th>
                           <td>a person with access to the App, who falls within the following roles: Administrator, Driver, Fan, Marshall, Media or any other person who has been given access to the App.</td>
                        </tr>
						<tr>
                           <th>Contact Data  </th>
                           <td>e-mail address and telephone number.</td>
                        </tr>
						<tr>
                          <th>Content Data</th>
                           <td>information stored on your Device.</td>
                        </tr>
						<tr>
                          <th>Device Data</th>
                           <td>includes the type of mobile device you use, a unique device identifier, the MAC address of the Device's wireless network interface, or the mobile phone number used by the Device.</td>
                        </tr>
						<tr>
                          <th>Driver  </th>
                           <td>motor racing drivers participating in races or other events operated by a Race Championship.</td>
                        </tr>
						<tr>
                          <th>Fan</th>
                           <td>motor racing fans and spectators using the Service solely for private recreational purposes.</td>
                        </tr>
						<tr>
                           <th>Identity Data  </th>
                           <td>includes first and last name, username and gender.</td>
                        </tr>
						<tr>
                           <th>Location Data </th>
                           <td>includes your current location disclosed by GPS technology.</td>
                        </tr>
						<tr>
                          <th>Marketing and Communications Data </th>
                           <td>includes your preferences in receiving marketing from us and our third parties and your communication preferences.  </td>
                        </tr>
						<tr>
                           <th>Marshalls </th>
                           <td>race marshalls at races or other events operated by a Race Championship</td>
                        </tr>
						<tr>
                           <th>Media </th>
                           <td>journalists, media and press representatives attending races or other events operated by a Race Championship.</td>
                        </tr>
						<tr>
                           <th>Profile Data</th>
                           <td>includes your username and password, in-App purchase history, your interests, preferences, feedback and survey responses.</td>
                        </tr>
						<tr>
                           <th>Race Championship </th>
                           <td>an organisation responsible for a motor racing championship, competition, league or other event, which acts as an Administrator.</td>
                        </tr><tr>
                           <th>Usage Data</th>
                           <td>includes details of your use of the App.</td>
                        </tr>
						
                     </tbody>
                  </table>
				</div>
	</div>
</div>
</div>

</tbody>
</table>`;
