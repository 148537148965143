const {
    defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);
const { STRINGS } = require(`../../../../../../shared/sef/constants/us/strings`);
const { VALIDATION_MESSAGES } = require(`../../../../../../shared/sef/constants`)



export const validator = (values) => {
    const errors = {};
    if (!values[STRINGS.TEST_INSTRUCTION]) {
        errors[STRINGS.TEST_INSTRUCTION] =
            VALIDATION_MESSAGES.TEST_INSTRUCTION_REQUIRED;
    }
    if (values[STRINGS.TEST_INSTRUCTION] && !values[STRINGS.TEST_INSTRUCTION].trim()) {
        errors[STRINGS.TEST_INSTRUCTION] =
            VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
    }
    return errors;
}