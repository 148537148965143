import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { TextField } from "@material-ui/core";
import classNames from "classnames";
import { useEffect } from "react";
import { updateAmbassadorAccess } from "../../../../../../redux/sef/actions";
const {
  defaultConfig: { PLATFORM, LOCATION }
} = require(`../../../../../../config/default`);
const {
  LAYOUTS,
  LIMITS,
  NEXT_ARROW,
  PREV_ARROW,
  PAGE_TITLES,
  LABELS,
  MESSAGES,
  DELETE_ICON,
  EMPTY_AMBASSADOR_ICON,
  SEARCH_ICON,
  TABLE_ARROW_DOWN,
  ROLE_LABELS,
  TABLE_SIZE_ITEMS,
  TABLE_ARROW_UP,
  EDIT_ICON,
  ROUTES,
  SUB_ADMIN_PLATFORM,
  ACTIVE_PAGE_STYLE
} = require(`../../../../../../shared/${PLATFORM}/constants`);

const {
  CustomDropdown
} = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);

const {
  CustomTable
} = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const {
  SnackbarWrapper
} = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const {
  CustomPagination
} = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);

const {
  DecisionPopup
} = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const {
  STRINGS
} = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);

let driversExists = true; // you should get this value from backend

export const AmbassadorsList = ({
  history,
  startLoader,
  fetchAmbassadorsList,
  stopLoader,
  ambassadorsList,
  revertAmbassadorAccess,
  platformType,
  usersList,
  fetchUsersList,
  updateAmbassadorAccess
}) => {
  const [popupVisible, setPopVisible] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [sortType, setSortType] = useState(-1);
  const [sortByKey, setSortByKey] = useState("date");
  const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
  const [openSnackBar, setOpenSnackbar] = useState(false);

  const [ambassadorAccess, setAmbassadorAccess] = useState(true);
  const [snackbarData, setSnackBarData] = useState({
    variant: "",
    message: ""
  });
  const [popupData, setPopupData] = useState({
    popupContent: "",
    popupTitle: "",
    confirmButton: "Confirm",
    onConfirmation: () => { }
  });

  const updateAmbassadorAccessOfUserLocally = (userIdx, checked) => {
    let updatedUsersList = [...ambassadorsList.data];

    // toggling checkbox state
    // let user = { ...ambassadorsList.data[userIdx], ambassadorAccess: checked };
    // updatedUsersList[userIdx] = user;

    // removing from list
    updatedUsersList.splice(userIdx, 1);
    if (updatedUsersList.length === 0) {
      updatedUsersList = null;
    }

    updateAmbassadorAccess(updatedUsersList);
  };

  useEffect(() => {
    fetchUsersList({
      searchString: searchString,
      filterRole: 2,
      filterStatus: 1,
      sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
      sortType: sortType,
      limit: tableSize.label,
      page: activePage
    }, () => {
      stopLoader();
    },
      (response) => {
        setSnackBarData({
          variant: response.status ? 'success' : 'error',
          message: response.msg
        });
        setOpenSnackbar(true);
        stopLoader();
      });
  }, [])

  useEffect(() => {
    startLoader();
    fetchAmbassadorsList(
      {
        searchString: searchString,
        filterStatus: 0,
        ambassadorAccess: ambassadorAccess ? true : false,
        sortKey: sortByKey === "date" ? "createdAt" : sortByKey,
        sortType: sortType,
        limit: tableSize.label,
        page: activePage
      },
      () => {
        stopLoader();
      },
      response => {
        setSnackBarData({
          variant: response.status ? "success" : "error",
          message: response.msg
        });
        setOpenSnackbar(true);
        stopLoader();
      }
    );
  }, [tableSize.value, activePage, sortType, sortByKey, ambassadorAccess]);

  return (
    <div>
      <React.Fragment>
        <Helmet>
          <title>{PAGE_TITLES.ambassadors}</title>
        </Helmet>
        <div className="content-wrapper">
          <DecisionPopup
            modalVisibility={popupVisible}
            dialogContent={popupData.popupContent}
            dialogTitle={popupData.popupTitle}
            confirmButtonTitle={popupData.confirmButton}
            rejectButtonTitle={popupData.rejectButton}
            toggleDialogModal={() => setPopVisible(!popupVisible)}
            onConfirmation={popupData.onConfirmation}
            onRejection={() => setPopVisible(false)}
          />
          <SnackbarWrapper
            visible={openSnackBar}
            onClose={() => setOpenSnackbar(false)}
            variant={snackbarData.variant}
            message={snackbarData.message}
          />

          <div className="align-items-center justify-content-between flex-column flex-md-row flex-wrap">
            <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
              <h5 className="mb-2">{STRINGS.AMBASSADORS_TITLE}</h5>
              {(ambassadorsList && ambassadorsList.data && ambassadorsList.data.length > 0 && ambassadorAccess) || (searchString && ambassadorAccess) ? (
                <button
                  className={"btn btn-lg btn-primary text-capitalize"}
                  onClick={() => {
                    setAmbassadorAccess(false);
                    setSearchString("");
                  }}
                >
                  {
                    STRINGS.ADD_USER
                  }
                </button>
              ) : null}
              {!ambassadorAccess && (
                <button
                  className={"btn btn-lg btn-primary text-capitalize"}
                  onClick={() => {
                    setAmbassadorAccess(true);
                    setSearchString("");
                  }}
                >
                  {
                    'BACK'
                  }
                </button>
              )}
            </div>
            <div className={"card border-0 shadow"}>
              <div
                className={classNames([
                  {
                    "card-body p-0 height-vh d-flex align-items-center justify-content-center":
                      !(ambassadorsList &&
                        ambassadorsList.metaData &&
                        (ambassadorsList.metaData.total !== 0 || !!searchString) || !ambassadorAccess)
                  },
                  {
                    "p-0":
                      ambassadorsList &&
                      ambassadorsList.metaData &&
                      (ambassadorsList.metaData.total !== 0 || !!searchString)
                  },
                  {
                    "d-flex align-items-center justify-content-center": !(
                      ambassadorsList &&
                      ambassadorsList.metaData &&
                      (ambassadorsList.metaData.total !== 0 || !!searchString)
                    )
                  }
                ])}
              >
                <div className="event-screen w-100">

                  <React.Fragment>

                    {ambassadorsList &&
                      ambassadorsList.metaData &&
                      (ambassadorsList.metaData.total !== 0 ||
                        !!searchString) && (
                        <React.Fragment>
                          <div className="table_filter">
                            <div className="form-row table-listing">
                              <div className="col-md-3">
                                <div className="table-search">
                                  {<TextField
                                    className="text-field"
                                    label={LABELS.search}
                                    type="text"
                                    value={searchString}
                                    placeholder={STRINGS.SEARCH}
                                    onChange={e => {
                                      setSearchString(e.target.value);
                                      if (!e.target.value) {
                                        startLoader();
                                      }
                                      fetchAmbassadorsList(
                                        {
                                          ambassadorAccess: ambassadorAccess ? true : false,
                                          searchString: e.target.value,
                                          filterStatus: 0,
                                          sortKey:
                                            sortByKey === "date"
                                              ? "createdAt"
                                              : sortByKey,
                                          sortType: sortType,
                                          limit: tableSize.label,
                                          page: activePage
                                        },
                                        () => {
                                          stopLoader();
                                        },
                                        response => {
                                          setSnackBarData({
                                            variant: response.status
                                              ? "success"
                                              : "error",
                                            message: response.msg
                                          });
                                          setOpenSnackbar(true);
                                          stopLoader();
                                        }
                                      );
                                    }}
                                    margin="0"
                                  />}
                                  {<i>
                                    <img
                                      className="search-icon"
                                      src={SEARCH_ICON}
                                      alt={""}
                                    />
                                  </i>}

                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                    <SnackbarWrapper
                      visible={openSnackBar}
                      onClose={() => setOpenSnackbar(false)}
                      variant={snackbarData.variant}
                      message={snackbarData.message}
                    />

                    {(ambassadorsList?.data &&
                      ambassadorsList.metaData &&
                      (ambassadorsList.metaData.total !== 0 ||
                        !!searchString)) || !ambassadorAccess ? (
                        <div className="table-responsive">
                          <CustomTable
                            rows={
                              ambassadorsList && ambassadorsList.data
                                ? ambassadorsList.data
                                : []
                            }
                            rowsPerPage={tableSize.label}
                            showProfile={true}
                            isEvent={true}
                            columns={LAYOUTS.ambassadorTable}
                            activePage={1}
                            sortByKey={sortByKey}
                            sortType={sortType}
                            TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                            emptyDataMessage={MESSAGES.noRecordsFound}
                            TABLE_ARROW_UP={TABLE_ARROW_UP}
                            ROLE_LABELS={ROLE_LABELS}
                            EDIT_ICON={EDIT_ICON}
                            DELETE_ICON={DELETE_ICON}
                            onRowClick={id => {
                              // history.push(`${ROUTES.USER_DETAIL}?id=${id}`)
                            }}
                            onStatusChange={(checked, userId, userIdx) => {
                              setPopupData({
                                popupContent: checked
                                  ? STRINGS.ARE_YOU_SURE_YOU_WANT_TO_GRANT_AMBASSADOR_ACCESS
                                  : STRINGS.ARE_YOU_SURE_YOU_WANT_TO_REVOKE_AMBASSADOR_ACCESS,
                                popupTitle: checked
                                  ? STRINGS.GRANT_AMBASSADOR_ACCESS
                                  : STRINGS.REVOKE_AMBASSADOR_ACCESS,
                                onConfirmation: () => {
                                  setPopVisible(false);
                                  startLoader();
                                  revertAmbassadorAccess(
                                    { userId: userId, access: checked },
                                    response => {
                                      stopLoader();
                                      setSnackBarData({
                                        variant: "success",
                                        message: response.msg
                                      });
                                      setOpenSnackbar(true);
                                      // updateAmbassadorAccessOfUserLocally(
                                      //   userIdx,
                                      //   checked
                                      // );
                                      startLoader();
                                      fetchAmbassadorsList(
                                        {
                                          searchString: searchString,
                                          filterStatus: 0,
                                          ambassadorAccess: ambassadorAccess ? true : false,
                                          sortKey: sortByKey === "date" ? "createdAt" : sortByKey,
                                          sortType: sortType,
                                          limit: tableSize.label,
                                          page: activePage
                                        },
                                        () => {
                                          stopLoader();
                                        },
                                        response => {
                                          setSnackBarData({
                                            variant: response.status ? "success" : "error",
                                            message: response.msg
                                          });
                                          setOpenSnackbar(true);
                                          stopLoader();
                                        }
                                      );
                                    },
                                    response => {
                                      stopLoader();
                                      setSnackBarData({
                                        variant: "error",
                                        message: response.msg
                                      });
                                      setOpenSnackbar(true);
                                    }
                                  );
                                }
                              });
                              setPopVisible(true);
                            }}
                            onEditClick={(id, index) => {
                              history.push(`${ROUTES.EVENTS_DETAILS}?id=${id}`);
                            }}
                            setSortKey={key => {
                              if (sortByKey === key) {
                                setSortType(-1 * sortType);
                              } else {
                                setSortType(1);
                              }
                              setSortByKey(key);
                            }}
                          />

                          {ambassadorsList &&
                            ambassadorsList.data &&
                            !!ambassadorsList.data.length &&
                            ambassadorsList.metaData.total >
                            TABLE_SIZE_ITEMS[0].label && (
                              <div className="text-center px-3">
                                <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                  <CustomDropdown
                                    labelText={LABELS.noOfRows}
                                    dataItems={TABLE_SIZE_ITEMS}
                                    value={tableSize.value}
                                    dropDataSet={value => {
                                      setActivePage(1);
                                      setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                    }}
                                  />
                                  {ambassadorsList &&
                                    ambassadorsList.metaData &&
                                    ambassadorsList.metaData.total >
                                    tableSize.label ? (
                                      <CustomPagination
                                        displayingString={STRINGS.DISPLAYING}
                                        outOfString={STRINGS.OUT_OF}
                                        totalPages={
                                          ambassadorsList.metaData.total
                                        }
                                        itemsCount={ambassadorsList.data.length}
                                        pageCount={LIMITS.paginationPageCount}
                                        limit={tableSize.label}
                                        currentPage={activePage}
                                        nextArrow={NEXT_ARROW}
                                        prevArrow={PREV_ARROW}
                                        onPageChange={selectedPage => {
                                          let page = selectedPage.selected + 1;
                                          document
                                            .getElementById("screen-content")
                                            .scroll({
                                              top: 0,
                                              left: 0,
                                              behavior: "smooth"
                                            });
                                          setActivePage(page);
                                        }}
                                        activePageStyle={ACTIVE_PAGE_STYLE}
                                      />
                                    ) : null}
                                </div>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className={"row p-4 p-md-4"}>
                          <div className="col-md-8 offset-md-2 text-center">
                            <figure className="mb-3 mb-md-4">
                              <img
                                src={EMPTY_AMBASSADOR_ICON}
                                alt={"dashboard-logo"}
                                className="img-fluid"
                              />
                            </figure>
                            <h5 className="mb-3">
                              {STRINGS.EMPTY_AMBASSADOR_HEADING}
                            </h5>
                            <p>
                              {ambassadorsList?.driverExist && usersList && usersList.data && usersList.data.length !== 0
                                ? STRINGS.EMPTY_AMBASSADOR_DESCRIPTION
                                : STRINGS.EMPTY_AMBASSADOR_NO_USERS_DESCRIPTION}
                            </p>

                            {ambassadorsList?.driverExist && usersList && usersList.data && usersList.data.length !== 0 && (
                              <div className="group_btn mt-3 mt-md-4">
                                <button
                                  className={
                                    "btn btn-lg btn-primary text-capitalize"
                                  }
                                  onClick={() => {
                                    setAmbassadorAccess(false);
                                  }}
                                >
                                  {STRINGS.ADD_AMBASSADORS}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                  </React.Fragment>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};