import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    uploadCSV,
    addNewUser,
    reSendInvite,
    getHoldingAreaUsers,
    fetchUserTypes,
    fetchHoldingRoles,
    updateActivationStatus
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        holdingList: state.UserReducer.holdingList,
        championshipData: state.CommonReducer.championshipData,
        userTypeList: state.UserReducer.userTypeList,
        holdingUserRoles: state.UserReducer.holdingUserRoles
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader()),
        reSendInvite: (data, success, failure) => dispatch(reSendInvite(data, success, failure)),
        addNewUser: (data, success, failure) => dispatch(addNewUser(data, success, failure)),
        uploadCSV: (formData, success, error) => dispatch(uploadCSV(formData, success, error)),
        updateActivationStatus: (data, success) => dispatch(updateActivationStatus(data, success)),
        getHoldingAreaUsers: (data, success, failure) => dispatch(getHoldingAreaUsers(data, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure)),
        fetchHoldingRoles: (success, failure) => dispatch(fetchHoldingRoles(success, failure)),
    }
}
export const HoldingArea = connect(mapStateToProps, mapDispatchToProps)(Screen);



