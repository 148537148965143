import { takeLatest, all, put, delay } from "redux-saga/effects";

import {
    FETCH_UNBLOCK_REQUEST_LIST,
    UPDATE_BLOCK_STATUS,
    FETCH_USER_TEST_LIST,
    SEND_FORGOT_EMAIL,
    setAuthorization,
    startLoader,
    stopLoader,
    setPlatformType,
    LOGOUT_USER,
    saveChampionship,
    saveUsersTestList,
    saveUnblockRequestList,
    SEND_TEST_REMINDER
} from '../actions';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const { putRequest, postRequest } = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);


function* fetchUnblockRequestList({ data, success, failure }) {
    try {
        yield put(startLoader())
        const response = yield postRequest({ API: `${api.URL.FETCH_UNBLOCK_REQUEST_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data);
            yield put(stopLoader());
        }
        else {
            yield put(saveUnblockRequestList(response.data.data));
            success();
            yield put(stopLoader());
        }
    }
    catch (error) {
        return;
    }
}

function* updateUnblockStatus({ data, success, failure }) {
    try {
        yield put(startLoader())
        const response = yield putRequest({ API: `${api.URL.UPDATE_BLOCK_STATUS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* fetchUsersTestList({ data, success, failure }) {
    try {
        yield put(startLoader())
        const response = yield postRequest({ API: `${api.URL.TEST_USERS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data);
            yield put(stopLoader());
        }
        else {
            yield put(saveUsersTestList(response.data.data));
            success();
            yield put(stopLoader());
        }
    }
    catch (error) {
        return;
    }
}

function* sendTestReminderSaga({ data, success, failure }) {
  try {
    yield put(startLoader());
    const response = yield postRequest({
      API: `${api.URL.TEST_REMINDER}`,
      DATA: data
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(setAuthorization(null));
      return;
    }
      if (response.status !== STATUS_CODE.successful) {
      yield put(stopLoader());
      yield failure(response.data.msg);
      
    } else {
      yield put(stopLoader());
      yield success();
    }
  } catch (error) {
      yield put(stopLoader());
      yield failure('Something went wrong');
    return;
  }
}

function* RaceDirectorSaga() {
    yield all([
        takeLatest(FETCH_UNBLOCK_REQUEST_LIST, fetchUnblockRequestList),
        takeLatest(UPDATE_BLOCK_STATUS, updateUnblockStatus),
        takeLatest(FETCH_USER_TEST_LIST, fetchUsersTestList),
        takeLatest(SEND_TEST_REMINDER, sendTestReminderSaga)
    ]);
}

export default RaceDirectorSaga;
