import { takeLatest, all, put, delay } from "redux-saga/effects";
import {
    GIVE_EMERGENCY_ACCESS,
    REVERT_EMERGENCY_ACCESS,
    setAuthorization,
} from '../actions';
const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const {
    postRequest,
    deleteRequest
} = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);

function* provideEmergencyAccess({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.EMERGENCY_ACCESS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* revertAccessFromUser({ data, success, failure }) {
    try {
        const response = yield deleteRequest({ API: `${api.URL.EMERGENCY_ACCESS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* EmergencySaga() {
    yield all([
        takeLatest(GIVE_EMERGENCY_ACCESS, provideEmergencyAccess),
        takeLatest(REVERT_EMERGENCY_ACCESS, revertAccessFromUser)
    ]);
}

export default EmergencySaga;