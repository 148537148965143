import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { UserTypeReduxForm } from './form';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const {
    PAGE_TITLES,
    LAYOUTS,
    EMPTY_USER_TYPE_ICON,
    TABLE_SIZE_ITEMS,
    MESSAGES,
    ROUTES,
    ROLE_LABELS,
    ACTIVE_PAGE_STYLE,
    LABELS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    EDIT_ICON,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);

export const Screen = ({
    userTypeList,
    addNewUserType,
    editUserType,
    fetchUserTypes,
    startLoader,
    stopLoader
}) => {
    const [activePage, setActivePage] = useState(1);
    const [formVisible, setFormVisible] = useState(false);
    const [editableIndex, setEditableIndex] = useState(null);
    const [editForm, setEditForm] = useState(false);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortByKey, setSortByKey] = useState('name');
    const [sortType, setSortType] = useState(-1);
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    });
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });

    useEffect(() => {
        startLoader();
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
        stopLoader();
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.roles}</title>
            </Helmet>
            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />

                    {formVisible && <div className='dropzone-div'>
                        <div className="overlay"></div>
                        <div className="dropzone-dialog">
                            <div className="dropzone-content">
                                <div className="dropzone-body">
                                    <h3 className="mb-4 text-center">{editableIndex !== null?STRINGS.EDIT_USER_TYPE:STRINGS.ADD_USER_TYPE}</h3>
                                    <UserTypeReduxForm
                                        editForm={editForm}
                                        formText={editableIndex !== null ? userTypeList[editableIndex]&& userTypeList[editableIndex].value : ''}
                                        onSubmit={(formData) => {
                                            startLoader();
                                            editForm ? editUserType({
                                                roleId: userTypeList[editableIndex]._id,
                                                value: formData['user_type']
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setFormVisible(false);
                                                fetchUserTypes((response) => {
                                                    stopLoader();
                                                    setOpenSnackbar(true);
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    setOpenSnackbar(true);
                                                    stopLoader();
                                                })
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                setOpenSnackbar(true);
                                                stopLoader();
                                            }) :
                                                addNewUserType({
                                                    value: formData['user_type']
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    stopLoader();
                                                    setFormVisible(false);
                                                    fetchUserTypes((response) => {
                                                        stopLoader();
                                                        setOpenSnackbar(true);
                                                    }, (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        setOpenSnackbar(true);
                                                        stopLoader();
                                                    })
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    setOpenSnackbar(true);
                                                    stopLoader();
                                                })
                                        }}
                                        onCancel={() => {
                                            setFormVisible(false);
                                            setEditableIndex(null);
                                            setEditForm(false);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <h5 className="my-2 text-center text-md-left">{STRINGS.MANAGE_USER_TYPE}</h5>
                        {(userTypeList && userTypeList.length !== 0) && !formVisible && <div className="group_btn">
                            <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => {
                                setEditForm(false);
                                setEditableIndex(null);
                                setFormVisible(true);
                            }}>{STRINGS.ADD_USER_TYPE}</button>
                        </div>
                        }
                    </div>

                    <div className={'card border-0 shadow'}>
                        <div className={classNames([
                            'card-body p-0 height-vh',
                            { 'd-flex align-items-center justify-content-center': !(userTypeList && userTypeList.length !== 0) && !formVisible }
                        ])}>
                            {!(userTypeList && userTypeList.length !== 0) && !formVisible && <div className={'row py-4 py-md-4'}>
                                <div className="col-md-12 text-center">
                                    <figure className="mb-3 mb-md-4">
                                        <img src={EMPTY_USER_TYPE_ICON} alt={'dashboard-logo'} className="img-fluid" />
                                    </figure>
                                    <h5 className="mb-3">{STRINGS.EMPTY_USERTYPE_HEADING}</h5>
                                    <p>{STRINGS.EMPTY_USERTYPE_DESCRIPTION}</p>

                                    <div className="group_btn mt-3 mt-md-4">
                                        <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => {
                                            setEditForm(false);
                                            setEditableIndex(null)
                                            setFormVisible(true)
                                        }}>{STRINGS.ADD_USER_TYPE}</button>
                                    </div>
                                </div>
                            </div>}
                            {(userTypeList && userTypeList.length !== 0) && !formVisible &&
                                <React.Fragment>
                                    <div className="table-responsive">
                                        <CustomTable
                                            rows={userTypeList}
                                            rowsPerPage={userTypeList.length}
                                            columns={LAYOUTS.userTypes}
                                            activePage={1}
                                            sortByKey={sortByKey}
                                            sortType={sortType}
                                            userType={true}
                                            emptyDataMessage={MESSAGES.noRecordsFound}
                                            TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                            TABLE_ARROW_UP={TABLE_ARROW_UP}
                                            ROLE_LABELS={ROLE_LABELS}
                                            EDIT_ICON={EDIT_ICON}
                                            onEditClick={(id, index) => {
                                                setEditForm(true);
                                                setEditableIndex(index)
                                                setFormVisible(true);
                                            }}
                                            onStatusChange={(checked, index) => {
                                                setPopupData({
                                                    popupContent: checked ? 'Are you sure you want activate this User type?' : 'Are you sure you want to deactivate this User type?',
                                                    popupTitle: checked ? 'Activate User Type' : 'Deactivate User Type',
                                                    onConfirmation: () => {
                                                        setPopVisible(false);
                                                        startLoader();
                                                        editUserType({
                                                            roleId: userTypeList[index]._id,
                                                            status: checked
                                                        }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            stopLoader();
                                                            setFormVisible(false);
                                                            fetchUserTypes((response) => {
                                                                stopLoader();
                                                                setOpenSnackbar(true);
                                                            }, (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                setOpenSnackbar(true);
                                                                stopLoader();
                                                            })
                                                        }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        })
                                                    }
                                                })
                                                setPopVisible(true);
                                            }}
                                            setSortKey={(key) => {
                                                if (sortByKey === key) {
                                                    setSortType(-1 * sortType);
                                                }
                                                else {
                                                    setSortType(1)
                                                }
                                                setSortByKey(key)
                                            }}
                                        />

                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}