import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import query from 'query-string';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { useEffect } from "react";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { LAYOUTS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    PAGE_TITLES,
    LABELS,
    MESSAGES,
    DELETE_ICON,
    SEARCH_ICON,
    TABLE_ARROW_DOWN,
    ROLE_LABELS,
    TABLE_SIZE_ITEMS,
    EDIT_ICON,
    ROUTES,
    USER_ROLE_ITEMS,
    ACTIVE_PAGE_STYLE, } = require(`../../../../../../shared/${PLATFORM}/constants`);

const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { MultipleSelect } = require(`../../../../../../components/${PLATFORM}/atoms/multi-select`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);

const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

export const Screen = ({
    history,
    location,
    startLoader,
    attendees,
    stopLoader,
    deleteEvent,
    fetchUserTypes,
    userTypeList,
    platformType,
    fetchAttendeesList,
    championshipData
}) => {
    const parameters = query.parse(location.search);
    const [popupVisible, setPopVisible] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [roleItems, setRoleItems] = useState([USER_ROLE_ITEMS[0]]);
    const [searchString, setSearchString] = useState('');
    const [sortType, setSortType] = useState(-1);
    const [sortByKey, setSortByKey] = useState('date')
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [filterRole, setFilterRole] = useState([])
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        onConfirmation: () => { }
    });

    useEffect(() => {
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
    }, [])

    useEffect(() => {
        fetchAttendeesList({
            eventId: parameters.id,
            searchString: searchString,
            sortKey: sortByKey === 'date' ? "createdAt" : sortByKey,
            sortType: sortType,
            filterRole: filterRole.length ? filterRole : null,
            limit: tableSize.label,
            page: activePage
        }, () => { }, (response) => {
            setSnackBarData({
                variant: response.status ? STRINGS.SUCCESS : STRINGS.ERROR,
                message: response.msg
            });
            stopLoader();
            setOpenSnackbar(true);
        })
    }, [tableSize.value, activePage, searchString, sortType, sortByKey, parameters.id, filterRole.length])

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    return (
        <div>
            <React.Fragment>
                <Helmet>
                    <title>{PAGE_TITLES.events}</title>
                </Helmet>
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />

                    <div className="align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
                            <h5><label onClick={() => { history.replace(`${ROUTES.EVENTS_DETAILS}?id=${parameters.id}`) }}>{STRINGS.DESCRIPTION}</label> <span>{STRINGS.ATTENDEES}</span></h5>
                        </div>
                        <div className={'card border-0 shadow'}>
                            <div className={classNames([
                                'card-body height-vh',
                                { 'p-0': attendees && attendees.metaData && (attendees.metaData.total !== 0 || !!searchString) },
                                { 'd-flex justify-content-center': !(attendees && attendees.metaData && attendees.metaData.total !== 0) }
                            ])}>

                                <div className="event-screen w-100">
                                    <div className={'card-body p-0'}>
                                        <React.Fragment>
                                            <div className="table_filter">
                                                <div className="form-row table-listing">
                                                    <div className="col-md-3">
                                                        <div className='table-search'>
                                                            <TextField
                                                                className='text-field'
                                                                label={LABELS.search}
                                                                type="text"
                                                                value={searchString}
                                                                placeholder={STRINGS.SEARCH}
                                                                onChange={e => { setSearchString(e.target.value) }}
                                                                margin="0"
                                                            />
                                                            <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                                                        </div>
                                                    </div>

                                                    <div className={'col-md-9'}>
                                                        <div className="d-flex justify-content-between justify-content-md-end select_multi">
                                                            <MultipleSelect
                                                                labelText={STRINGS.USER_TYPE_COLON}
                                                                withCheckbox={true}
                                                                dataItems={roleItems}
                                                                value={tableSize.value}
                                                                dropDataSet={(value) => {
                                                                    if (value.includes(1)) {
                                                                        value.splice(value.indexOf(1), 1)
                                                                    }
                                                                    setFilterRole(value)
                                                                    setActivePage(1);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <SnackbarWrapper
                                                visible={openSnackBar}
                                                onClose={() => setOpenSnackbar(false)}
                                                variant={snackbarData.variant}
                                                message={snackbarData.message}
                                            />


                                            <div className="table-responsive">
                                                <CustomTable
                                                    rows={attendees && attendees.data ? attendees.data : []}
                                                    rowsPerPage={tableSize.label}
                                                    showProfile={true}
                                                    isEvent={true}
                                                    columns={LAYOUTS.attendeesList}
                                                    activePage={1}
                                                    sortByKey={sortByKey}
                                                    sortType={sortType}
                                                    emptyDataMessage={MESSAGES.noRecordsFound}
                                                    TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                                    ROLE_LABELS={ROLE_LABELS}
                                                    EDIT_ICON={EDIT_ICON}
                                                    DELETE_ICON={DELETE_ICON}
                                                    setSortKey={(key) => {
                                                        if (sortByKey === key) {
                                                            setSortType(-1 * sortType);
                                                        }
                                                        else {
                                                            setSortType(1)
                                                        }
                                                        setSortByKey(key)
                                                    }}
                                                />
                                                {attendees && attendees.data && !!attendees.data.length && (attendees.metaData.total > TABLE_SIZE_ITEMS[0].label)
                                                    && <div className="text-center px-3">
                                                        <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                                            <CustomDropdown
                                                                labelText={LABELS.noOfRows}
                                                                dataItems={TABLE_SIZE_ITEMS}
                                                                value={tableSize.value}
                                                                dropDataSet={(value) => {
                                                                    setActivePage(1);
                                                                    setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                                }}
                                                            />
                                                            {(attendees && attendees.metaData && attendees.metaData.total > tableSize.label)
                                                                ? <CustomPagination
                                                                    displayingString={STRINGS.DISPLAYING}
                                                                    outOfString={STRINGS.OUT_OF}
                                                                    totalPages={attendees.metaData.total}
                                                                    itemsCount={attendees.data.length}
                                                                    pageCount={LIMITS.paginationPageCount}
                                                                    limit={tableSize.label}
                                                                    currentPage={activePage}
                                                                    nextArrow={NEXT_ARROW}
                                                                    prevArrow={PREV_ARROW}
                                                                    onPageChange={(selectedPage) => {
                                                                        let page = selectedPage.selected + 1;
                                                                        document.getElementById('screen-content').scroll(
                                                                            {
                                                                                top: 0,
                                                                                left: 0,
                                                                                behavior: 'smooth'
                                                                            }
                                                                        )
                                                                        setActivePage(page);

                                                                    }}
                                                                    activePageStyle={ACTIVE_PAGE_STYLE}
                                                                />
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>}
                                            </div>
                                        </React.Fragment>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div >

    );
}