const {
    defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);
const { STRINGS } = require(`../../../../../../shared/sef/constants/us/strings`);
const { VALIDATION_MESSAGES, ELEMENT_ID } = require(`../../../../../../shared/sef/constants`)



export const testValidator = (values) => {
    const errors = {};

    if (!values[ELEMENT_ID.testType]) {
        errors[ELEMENT_ID.testType] = VALIDATION_MESSAGES.TEST_TYPE_IS_REQUIRED;
    }
    if (!values[ELEMENT_ID.noOfQuestions]) {
        errors[ELEMENT_ID.noOfQuestions] = VALIDATION_MESSAGES.NO_OF_QUESTION_REQUIRED;
    }
    if (values[ELEMENT_ID.noOfQuestions] && parseInt(values[ELEMENT_ID.noOfQuestions]) > 1000) {
        errors[ELEMENT_ID.noOfQuestions] = 'Test size cannot be >1000.';
    }
    // if (values[ELEMENT_ID.noOfQuestions] && !values[ELEMENT_ID.noOfQuestions].trim()) {
    //     errors[ELEMENT_ID.noOfQuestions] = VALIDATION_MESSAGES.NO_OF_QUESTION_REQUIRED;
    // }
    if (values[ELEMENT_ID.noOfQuestions]) {
        if (isNaN(values[ELEMENT_ID.noOfQuestions])) {
            errors[ELEMENT_ID.noOfQuestions] = VALIDATION_MESSAGES.NUMBER_REQUIRED
        }
        else if (values[ELEMENT_ID.noOfQuestions] < 0) {
            errors[ELEMENT_ID.noOfQuestions] = VALIDATION_MESSAGES.QUESTION_CANNOT_BE_NEGATIVE
        }
    }
    return errors;
}