import React, { useState } from "react";
import { reduxForm, Field, FieldArray, change as changeField } from "redux-form";
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { TextArea } = require(`../../../../../../components/${PLATFORM}/atoms/text-area`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { DatePickerInput } = require(`../../../../../../components/${PLATFORM}/atoms/date-picker`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);

const renderMembers = ({ fields, value }) => {
    fields.length === 0 && fields.push({})
    return (
        <React.Fragment>
            <div className={'d-flex justify-content-between add-button my-2 align-tems-center'}>
                <h6>{STRINGS.ADD_ADMIN}</h6>
                <Tooltip title={STRINGS.ADD_ADMIN} aria-label="add">
                    <button type={'button'}
                        className={'add-admin-button rounded-circle'}
                        onClick={() => fields.push({})}>{STRINGS.PLUS}</button>
                </Tooltip>
            </div>
            {
                fields.map((member, index) => {
                    return (
                        <React.Fragment>
                            {!!index && <div className={'remove-button-wrapper'}>
                                <Tooltip title={STRINGS.REMOVE_ADMIN} aria-label="add">
                                    <button type={'button'}
                                        className={'remove-admin-button rounded-circle'}
                                        onClick={() => fields.remove(index)}>{STRINGS.MINUS}</button>
                                </Tooltip>
                            </div>}
                            <div className="row">
                                <Field
                                    name={`${member}.${STRINGS.NAME_INPUT}`}
                                    component={Input}
                                    label={STRINGS.NAME_LABEL}
                                    type={'text'}
                                    placeholder={STRINGS.ADMIN_NAME_PLACEHOLDER}
                                    widthStyle={'col-md-6'}
                                />

                                <Field
                                    name={`${member}.${STRINGS.EMAIL_INPUT_NAME}`}
                                    component={Input}
                                    label={STRINGS.EMAIL_LABEL}
                                    type={'text'}
                                    placeholder={STRINGS.ADMIN_EMAIL_PLACEHOLDER}
                                    widthStyle={'col-md-6'}
                                />
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    );
}
const AddChampionshipForm = ({
    championshipName,
    description,
    handleSubmit = () => { },
    onSubmit = () => { },
    onCancel = () => { },
    changeField
}) => {
    let [startDate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')
    let [minEndDate, setMinEndDate] = useState('')

    const onChangeDate = (value) => {
        var copiedDate = new Date(value);
        changeField("championship", "start-date", value)
        setStartDate(value)
        setMinEndDate(copiedDate.setDate(value.getDate() + 1))

    }
    const onChangeEndDate = (value) => {
        setEndDate(value);
        changeField("championship", "end-date", value)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.CHAMPIONSHIP_INPUT}
                    component={Input}
                    label={STRINGS.CHAMPIONSHIP_NAME}
                    type={'text'}
                    placeholder={STRINGS.CHAMPIONSHIP_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.CHAMPIONSHIP_DETAILS}
                    component={TextArea}
                    label={STRINGS.CHAMPIONSHIP_DESCRIPTION_LABEL}
                    type={'text'}
                    placeholder={STRINGS.CHAMPIONSHIP_DETAILS_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.START_DATE_NAME}
                    component={DatePickerInput}
                    minDate={new Date()}
                    label={STRINGS.START_DATE}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    dateValue={startDate}
                    onChangeDate={onChangeDate}
                />
                <Field
                    name={STRINGS.END_DATE_NAME}
                    component={DatePickerInput}
                    minDate={minEndDate}
                    label={STRINGS.END_DATE}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    disabled={startDate ? false : true}
                    dateValue={endDate}
                    onChangeDate={onChangeEndDate}
                />
            </div>


            <FieldArray name="members" component={renderMembers} />
            <hr className="mt-0" />
            <div className="d-flex flex-column flex-sm-row justify-content-sm-start re_btn_custom">
                <button type={'button'} className={'reject-button ml-0'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit
                    buttonType={'submit'}
                    buttonLabel={STRINGS.ADD_CHAMPIONSHIP} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const afterSubmit = (result, dispatch) => {
}

const mapStateToProps = (state, props) => {
    return {
        championship: state.form.championship || {}
    };
}

const reduxFormFunction = reduxForm({
    form: "championship",
    onSubmitSuccess: afterSubmit,
    validate: validator,
    enableReinitialize: true,

})(AddChampionshipForm);

export const ChampionshipReduxForm = connect(mapStateToProps, { changeField })(reduxFormFunction);