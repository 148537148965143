import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    addChampionshipAdmin,
    updateChampionshipStatus,
    fetchChampionshipDetails,
    stopLoader,
    startLoader,
    deleteChampionshipAdmin,
    updateChampionshipDetails
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        championshipDetails: state.ChampionshipReducer.championshipDetails,
        admin: state.form.admin || {},
        edit: state.form.edit || {}
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        stopLoader: () => dispatch(stopLoader()),
        startLoader: () => dispatch(startLoader()),
        fetchChampionshipDetails: (id, success, error) => dispatch(fetchChampionshipDetails(id, success, error)),
        deleteChampionshipAdmin: (data, success, error) => dispatch(deleteChampionshipAdmin(data, success, error)),
        addChampionshipAdmin: (data, success, error) => dispatch(addChampionshipAdmin(data, success, error)),
        updateChampionshipStatus: (data, success, failure) => dispatch(updateChampionshipStatus(data, success, failure)),
        updateChampionshipDetails: (data, success, failure) => dispatch(updateChampionshipDetails(data, success, failure))
    }
}
export const ChampionshipDetailsScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);