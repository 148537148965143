const {
    defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);

const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/us/strings`);
const { VALIDATION_MESSAGES, EMAIL_REGX, NAME_REGX } = require(`../../../../../../shared/${PLATFORM}/constants`)

const validator = (values) => {
    const errors = {};
    if (!values[STRINGS.USER_TYPE_INPUT]) {
        errors[STRINGS.USER_TYPE_INPUT] =
            'User type is required.';
    }
    if (values[STRINGS.USER_TYPE_INPUT] && !values[STRINGS.USER_TYPE_INPUT].trim()) {
        errors[STRINGS.USER_TYPE_INPUT] =
            VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
    }
    if (values[STRINGS.USER_TYPE_INPUT] && values[STRINGS.USER_TYPE_INPUT].length > 30) {
        errors[STRINGS.USER_TYPE_INPUT] = 'User type cannot have more than 30 characters.';
    }
    else if (!NAME_REGX.test(values[STRINGS.USER_TYPE_INPUT])) {
        errors[STRINGS.USER_TYPE_INPUT] = 'User type cannot have special characters or digits.';
    }

    return errors;
};

export default validator;
