import React, { useState, useEffect } from "react";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from 'react-redux';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

const AddUserForm = ({
    name,
    email,
    role,
    userTypeList,
    fetchUserTypes,
    handleSubmit = () => { },
    onEmailChange = () => { },
    onNameChange = () => { },
    onRoleChange = () => { },
    onSubmit = () => { },
    onCancel = () => { }
}) => {
    const [roleItems, setRoleItems] = useState([]);

    useEffect(() => {
        fetchUserTypes((response) => {
        }, (response) => {
        });
    }, [])

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([...items]);
        }
    }, [userTypeList && userTypeList.length])

    return (
        <Form onSubmit={
            handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.FIRST_NAME_INPUT}
                    component={Input}
                    label={STRINGS.FIRST_NAME_LABEL}
                    type={'text'}
                    placeholder={STRINGS.NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.LAST_NAME_INPUT}
                    component={Input}
                    label={STRINGS.LAST_NAME_LABEL}
                    type={'text'}
                    placeholder={STRINGS.NAME_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.EMAIL_INPUT_NAME}
                    component={Input}
                    label={STRINGS.EMAIL_LABEL}
                    type={'text'}
                    placeholder={STRINGS.EMAIL_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.USER_ROLE}
                    component={Select}
                    label={STRINGS.USER_ROLE_LABEL}
                    options={roleItems}
                    onValueChange={(value) => onRoleChange(value)}
                    placeholde={STRINGS.USER_ROLE_PLACEHOLDER}
                    isSearchable={false}
                />
            </div>
            <hr className="mt-0" />
            <div className="d-flex flex-column flex-md-row justify-content-sm-end">
                <button type={'button'} className={'reject-button'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit
                    buttonLabel={STRINGS.ADD_USER} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            'firstName': '',
            'lastName': '',
            'email': '',
            'role': ''
        }
    };
}

const afterSubmit = (result, dispatch) => {
    dispatch(reset('user'));
}

const reduxFormFunction = reduxForm({
    form: "user",
    fields: ['firstName', 'lastName', 'email', 'role'],
    onSubmitFail,
    validate: validator,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(AddUserForm);

export const UserReduxForm = connect(mapStateToProps, null)(reduxFormFunction);