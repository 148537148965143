import { STRINGS, VALIDATION_MESSAGES } from "./constants";

const validator = (values) => {
  const errors = {};
  if (!values[STRINGS.OLD_PASSWORD_KEY]) {
    errors[STRINGS.OLD_PASSWORD_KEY] =
      VALIDATION_MESSAGES.OLD_PASSWORD_REQUIRED;
  }
  if (!values[STRINGS.NEW_PASSWORD_KEY]) {
    errors[STRINGS.NEW_PASSWORD_KEY] =
      VALIDATION_MESSAGES.NEW_PASSWORD_REQUIRED;
  }
  else if (values[STRINGS.NEW_PASSWORD_KEY].length < 6) {
    errors[STRINGS.NEW_PASSWORD_KEY] = VALIDATION_MESSAGES.PASSWORD_MIN_LENGTH;
  }
  if (!values[STRINGS.CONFIRM_PASSWORD_KEY]) {
    errors[STRINGS.CONFIRM_PASSWORD_KEY] =
      VALIDATION_MESSAGES.CONFIRM_PASSWORD_REQUIRED;
  }
  else if (values[STRINGS.CONFIRM_PASSWORD_KEY] !== values[STRINGS.NEW_PASSWORD_KEY]) {
    errors[STRINGS.CONFIRM_PASSWORD_KEY] = VALIDATION_MESSAGES.PASSWORDS_DONT_MATCH
  }
  return errors;
};

export default validator;
