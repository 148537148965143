const {
  defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);

const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/us/strings`);
const { VALIDATION_MESSAGES, EMAIL_REGX, NAME_REGX } = require(`../../../../../../shared/${PLATFORM}/constants`)

const validator = values => {
  const errors = {};
  if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] =
      VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (
    !EMAIL_REGX.test(
      values[STRINGS.EMAIL_INPUT_NAME].toLowerCase()
    )
  ) {
    errors[STRINGS.EMAIL_INPUT_NAME] =
      VALIDATION_MESSAGES.EMAIL_INVALID;
  }
  if (!values[STRINGS.FIRST_NAME_INPUT]) {
    errors[STRINGS.FIRST_NAME_INPUT] =
      VALIDATION_MESSAGES.NAME_REQUIRED;
  }
  if (
    !NAME_REGX.test(
      values[STRINGS.FIRST_NAME_INPUT]
    )
  ) {
    errors[STRINGS.FIRST_NAME_INPUT] =
      VALIDATION_MESSAGES.INVALID_NAME;
  }else{
    let fn = values.firstName || ""
    if (fn.startsWith(' ') || fn.endsWith(' ')){
      errors[STRINGS.FIRST_NAME_INPUT] =
        VALIDATION_MESSAGES.INVALID_NAME;
    }
  }
  if (!values[STRINGS.LAST_NAME_INPUT]) {
    errors[STRINGS.LAST_NAME_INPUT] =
      VALIDATION_MESSAGES.NAME_REQUIRED;
  } else {
    let fn = values.lastName || ""
    if (fn.startsWith(' ') || fn.endsWith(' ')) {
      errors[STRINGS.LAST_NAME_INPUT] =
        VALIDATION_MESSAGES.NAME_REQUIRED;
    }
  }
  if (
    !NAME_REGX.test(
      values[STRINGS.LAST_NAME_INPUT]
    )
  ) {
    errors[STRINGS.LAST_NAME_INPUT] =
      VALIDATION_MESSAGES.INVALID_NAME;
  }
  if (!values[STRINGS.USER_ROLE]) {
    errors[STRINGS.USER_ROLE] =
      VALIDATION_MESSAGES.USER_ROLE_REQUIRED;
  }
  return errors;
};

export default validator;
