
export const GIVE_EMERGENCY_ACCESS = 'GIVE_EMERGENCY_ACCESS';
export const REVERT_EMERGENCY_ACCESS = 'REVERT_EMERGENCY_ACCESS';

export const giveEmergencyAccess = (data, success, failure) => {
    return {
        type: GIVE_EMERGENCY_ACCESS,
        data,
        success,
        failure
    }
};

export const revertEmergencyAccess = (data, success, failure) => {
    return {
        type: REVERT_EMERGENCY_ACCESS,
        data,
        success,
        failure
    }
}