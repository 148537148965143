import React from "react";
import { reduxForm, Field, reset } from "redux-form";
import { connect } from 'react-redux';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);

const AddAdminForm = ({
    name,
    email,
    role,
    handleSubmit = () => { },
    onEmailChange = () => { },
    onNameChange = () => { },
    onRoleChange = () => { },
    onSubmit = () => { },
    onCancel = () => { }
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.NAME_INPUT}
                    component={Input}
                    label={STRINGS.NAME_LABEL}
                    type={'text'}
                    placeholder={STRINGS.NAME_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.EMAIL_INPUT_NAME}
                    component={Input}
                    label={STRINGS.EMAIL_LABEL}
                    placeholder={STRINGS.EMAIL_PLACEHOLDER}
                    type={'text'}
                />
            </div>
            <hr className="mt-0" />
            <div className="d-flex flex-row justify-content-end">
                <button type={'button'} className={'reject-button'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit buttonLabel={STRINGS.ADD} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    return {
    };
}
const afterSubmit = (result, dispatch) =>
    dispatch(reset('admin'));

const reduxFormFunction = reduxForm({
    form: "admin",
    fields: ['name', 'email'],
    onSubmitFail,
    validate: validator,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(AddAdminForm);

export const AdminReduxForm = connect(mapStateToProps, null)(reduxFormFunction);