import {
    SAVE_QUESTION_DETAILS,
    SET_TEST_LIST,
    SET_QUESTION_LIST,
    GET_INSTRUCTION
} from '../actions';

const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);

const initialCommonState = {
    testList: null,
    questionList: null,
    questionDetails: null,
    testInstruction: null
};

const TestReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case SET_TEST_LIST:
            return {
                ...state,
                testList: action.data
            }
        case SET_QUESTION_LIST:
            return {
                ...state,
                questionList: action.data
            }
        case SAVE_QUESTION_DETAILS:
            return {
                ...state,
                questionDetails: action.data
            }
        case GET_INSTRUCTION:
            return {
                ...state,
                testInstruction: action.data
            }
        default:
            return state;
    }
};

export default TestReducer;