import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { PAGE_TITLES,
    USER_TEST_LIST_TAB_VALUES,
    RACE_DIRECTOR_TABS,
    ACTIVE_PAGE_STYLE,
    NEXT_ARROW,
    PREV_ARROW,
    LIMITS,
    TABLE_SIZE_ITEMS,
    LAYOUTS,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
    ROUTES,
    MESSAGES,
    ROLE_LABELS,
    USER_ROLE_ITEMS,
    LABELS,
    SUB_ADMIN_PLATFORM
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { CustomTabs } = require(`../../../../../../components/sef/atoms/tab-drawer`);
const { STRINGS } = require(`../../../../../../shared/sef/constants/us/strings`)

export const Screen = ({
    fetchUnblockRequestList,
    championshipData,
    usersTestList,
    fetchUserTypes,
    userTypeList,
    startLoader,
    stopLoader,
    history,
    fetchUsersTestList,
    updateBlockStatus,
    sendTestReminder
}) => {
    const [tabValue, setTabValue] = useState(USER_TEST_LIST_TAB_VALUES.attempted);
    const [activePage, setActivePage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [roleItems, setRoleItems] = useState([]);
    const [selectedRole, setSelectedRole] = useState(USER_ROLE_ITEMS[0]);
    const [lastActivePage, setLastActivePage] = useState(1);
    const [sortByKey, setSortByKey] = useState('date');
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortType, setSortType] = useState(1);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    })
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        rejectButton: STRINGS.CANCEL,
        onConfirmation: () => { }
    });

    useEffect(() => {
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
    }, [])

    useEffect(() => {
        fetchUsersTestList({
            championshipId: championshipData && championshipData._id,
            attempted: tabValue === USER_TEST_LIST_TAB_VALUES.attempted ? true : false,
            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
            limit: tableSize.label,
            page: activePage,
        }, () => {
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
        setActivePage(1);
    }, [championshipData && championshipData._id && selectedRole, selectedRole.value, tableSize.label, tabValue]);


    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    return (
        <React.Fragment>
            <DecisionPopup
                modalVisibility={popupVisible}
                dialogContent={popupData.popupContent}
                dialogTitle={popupData.popupTitle}
                confirmButtonTitle={popupData.confirmButton}
                rejectButtonTitle={popupData.rejectButton}
                toggleDialogModal={() => setPopVisible(!popupVisible)}
                onConfirmation={popupData.onConfirmation}
                onRejection={() => setPopVisible(false)}
            />
            <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={snackbarData.variant}
                message={snackbarData.message}
            />

            <div className="content-wrapper">
                <div className="align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                    {/* {Condition for check if the test is empty or not} */}
                    <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <h5>{STRINGS.TEST_TITLE}</h5>

                    </div>
                    <div className={'card border-0 shadow'}>
                        <div className={'card-body p-0'}>
                            <div className="form-row table-listing user_test">

                                <div className={'col-md-12'}>
                                    <div className="d-flex justify-content-between select_multi">
                                        {true && < CustomTabs
                                            tabsItems={RACE_DIRECTOR_TABS}
                                            value={tabValue}
                                            handleTabChange={(newValue) => {
                                                setTabValue(newValue);
                                            }}
                                        />}

                                        <CustomDropdown
                                            labelText={LABELS.role}
                                            dataItems={roleItems}
                                            value={selectedRole.value}
                                            dropDataSet={(value) => {
                                                if (value < SUB_ADMIN_PLATFORM) {
                                                    setSelectedRole(roleItems[value - 1]);
                                                }
                                                else {
                                                    setSelectedRole(roleItems[value - 2]);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* {checking if there is any data in reduxfrom} */}
                            <div className="d-flex flex-column">
                                <div className="table-responsive">
                                    <CustomTable
                                        rows={usersTestList && usersTestList.data ? usersTestList.data : []}
                                        rowsPerPage={tableSize.label}
                                        showProfile={false}
                                        editAction={false}
                                        columns={tabValue ? LAYOUTS.unAttemptedTestList : LAYOUTS.userAttemptedTestList}
                                        activePage={1}
                                        sortByKey={sortByKey}
                                        sortType={sortType}
                                        emptyDataMessage={MESSAGES.noRecordsFound}
                                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                        TABLE_ARROW_UP={TABLE_ARROW_UP}
                                        ROLE_LABELS={ROLE_LABELS}
                                        onViewDetails={(id) => {
                                            history.push(`${ROUTES.USER_TEST_DETAILS}?id=${id}`)
                                        }}
                                        onTestReminderClick={(userId, userName) => {
                                            sendTestReminder({ userId: userId }, () => {
                                                setPopupData({
                                                    popupContent: STRINGS.TEST_REMINDER_CONTENT + ` ${userName}.`,
                                                    popupTitle: STRINGS.TEST_REMINDER_TITLE,
                                                    confirmButton: '',
                                                    rejectButton: STRINGS.OKAY,
                                                    onConfirmation: () => setPopVisible(false)
                                                });
                                                setPopVisible(true);
                                            }, (msg) => {
                                                setSnackBarData({
                                                    variant: 'error',
                                                    message: msg
                                                });
                                                setOpenSnackbar(true);
                                            })
                                        }}
                                        onStatusChange={(checked, id) => {
                                            setPopupData({
                                                popupContent: checked ? STRINGS.BLOCK_USER_FROM_GIVING_TEST_CONTENT : STRINGS.APPROVE_USER_FROM_GIVING_TEST_CONTENT,
                                                popupTitle: checked ? STRINGS.BLOCK_USER : STRINGS.UNBLOCK_USER,
                                                onConfirmation: () => {
                                                    setPopVisible(false);
                                                    startLoader();
                                                    updateBlockStatus({
                                                        championshipId: championshipData._id,
                                                        userId: id,
                                                        blockStatus: checked ? 2 : 1
                                                    }, (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        fetchUsersTestList({
                                                            championshipId: championshipData._id,
                                                            attempted: tabValue === USER_TEST_LIST_TAB_VALUES.attempted ? true : false,
                                                            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                            limit: tableSize.label,
                                                            page: activePage,
                                                        }, () => {
                                                            stopLoader();
                                                            setOpenSnackbar(true);
                                                        },
                                                            (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                setOpenSnackbar(true);
                                                                stopLoader();
                                                            });

                                                        stopLoader();
                                                    }, (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        setOpenSnackbar(true);
                                                        stopLoader();
                                                    });
                                                }
                                            })
                                            setPopVisible(true);
                                        }}
                                    />
                                    {usersTestList && usersTestList.data && !!usersTestList.data.length && (usersTestList.metaData.total > TABLE_SIZE_ITEMS[0].label) && <div className="text-center px-3">
                                        <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                            <CustomDropdown
                                                labelText={LABELS.noOfRows}
                                                dataItems={TABLE_SIZE_ITEMS}
                                                value={tableSize.value}
                                                dropDataSet={(value) => {
                                                    setActivePage(1);
                                                    setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                }}
                                            />

                                            {
                                                (usersTestList && usersTestList.metaData && usersTestList.metaData.total > tableSize.label) ? <CustomPagination
                                                    displayingString={STRINGS.DISPLAYING}
                                                    outOfString={STRINGS.OUT_OF}
                                                    totalPages={usersTestList.metaData.total}
                                                    itemsCount={usersTestList.data.length}
                                                    pageCount={LIMITS.paginationPageCount}
                                                    limit={tableSize.label}
                                                    currentPage={activePage}
                                                    nextArrow={NEXT_ARROW}
                                                    prevArrow={PREV_ARROW}
                                                    onPageChange={(selectedPage) => {
                                                        let page = selectedPage.selected + 1;
                                                        document.getElementById('screen-content').scroll(
                                                            {
                                                                top: 0,
                                                                left: 0,
                                                                behavior: 'smooth'
                                                            }
                                                        )
                                                        setActivePage(page);
                                                        setLastActivePage(page);
                                                        fetchUsersTestList({
                                                            championshipId: championshipData._id,
                                                            attempted: tabValue === USER_TEST_LIST_TAB_VALUES.attempted ? true : false,
                                                            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                            limit: tableSize.label,
                                                            page: page,
                                                        }, () => {
                                                            stopLoader();
                                                        },
                                                            (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                setOpenSnackbar(true);
                                                                stopLoader();
                                                            });
                                                    }}
                                                    activePageStyle={ACTIVE_PAGE_STYLE}
                                                />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment>
    );
}