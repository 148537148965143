import {
    SAVE_CHAMPIONSHIP_LIST,
    SAVE_CHAMPIONSHIP_DETAILS
} from '../actions';

const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);

const initialCommonState = {
    championshipList: null,
    championshipDetails: null
};

const ChampionshipReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case SAVE_CHAMPIONSHIP_LIST:
            return {
                ...state,
                championshipList: action.data
            };
        case SAVE_CHAMPIONSHIP_DETAILS:
            return {
                ...state,
                championshipDetails: action.data
            }
        default:
            return state;
    }
};

export default ChampionshipReducer;