import React, { useState, useEffect } from "react";
import {
  reduxForm,
  Field,
  reset,
  FieldArray,
  change as changeField,
} from "redux-form";
import { connect } from "react-redux";
import { testValidator } from "./testValidator";
import { Checkbox, FormControlLabel } from "@material-ui/core";
const {
  defaultConfig: { PLATFORM, LOCATION },
} = require(`../../../../../../config/default`);
// const Checkbox  = require(`../../../../../../components/${PLATFORM}/atoms/checkbox`);
const { Form } = require(`../../../../../../components/sef/atoms/form`);
const {
  InputSubmit,
} = require(`../../../../../../components/sef/atoms/input-submit`);
const {
  STRINGS,
} = require(`../../../../../../shared/sef/constants/us/strings`);
const { Select } = require(`../../../../../../components/sef/atoms/select`);
const {
  ADD_USER_ROLE_ITEMS,
  ELEMENT_ID,
  INSTRUCTION_CHECKBOX,
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { Input } = require(`../../../../../../components/sef/atoms/input`);
export const Screen = ({
  widthStyle,
  buttonStyle,
  answerType,
  questionType,
  onCancel = () => {},
  handleSubmit = () => {},
  createTest,
  userTypeList,
  changeField,
  fetchUserTypes,
  onEdit,
  id,
  data,
  ...props
}) => {
  const [roleItems, setRoleItems] = useState([]);
  const [selectedUserType, setUserType] = useState(
    data && data.userRole
      ? roleItems.length
        ? roleItems[data.userRole - 2]
        : null
      : null
  );
  const [totalQuestion, setQuestion] = useState(
    data && data.totalQuestions ? data.totalQuestions : ""
  );
  const [documentData, setDocumentData] = useState([]);

  const renderCheckbox = ({ input, label }) => (
    <Checkbox
      label={label}
      checked={input.value ? true : false}
      onCheck={input.onChange}
    />
  );

  React.useEffect(() => {
    fetchUserTypes(
      (response) => {},
      (response) => {}
    );
  }, [data && data.userRole]);

  React.useEffect(() => {
    let items = [];
    if (userTypeList) {
      userTypeList.map((item) => {
        items.push({
          label: item.value,
          value: item.key,
        });
      });
      setRoleItems([...items]);
      setUserType(data && data.userRole ? items[data.userRole - 2] : items[0]);
    }
  }, [userTypeList && userTypeList.length]);

  React.useEffect(() => {
    !onEdit
      ? changeField("createTest", "testType", null)
      : changeField(
          "createTest",
          "testType",
          data && data.userRole
            ? roleItems.length
              ? roleItems[data.userRole - 2]
              : null
            : null
        );
    onEdit && setDocumentData(data.documentsRequired || []);
  }, [data && data.userRole, roleItems.length]);

  const onChangeInput = (value) => {
    let data = [...documentData];
    if (data.includes(value)) {
      let deleteIndex = null;
      deleteIndex = data.findIndex((index) => index === value);
      data.splice(deleteIndex, 1);
    } else {
      data.push(value);
    }
    setDocumentData(data);
  };

  return (
    <div className="p-4">
      <Form onSubmit={handleSubmit((data) => createTest(data, documentData))}>
        <div className="row">
          <Field
            name={ELEMENT_ID.testType}
            component={Select}
            label={STRINGS.TEST_TYPE}
            widthStyle={"col-md-6"}
            options={roleItems}
          />
          <Field
            name={ELEMENT_ID.noOfQuestions}
            component={Input}
            config={{
              max: 1000,
              min: 0,
              // defaultValue: 1,
              type: "number",
            }}
            label={STRINGS.NO_OF_QUESTION}
            widthStyle={"col-md-6"}
          />
        </div>

        <div className="row">
          {INSTRUCTION_CHECKBOX.map((item) => (
            <div className="col-md-4">
                <div className="form-group">
                <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    checked={documentData?.includes(item?.value)}
                    onChange={() => onChangeInput(item?.value)}
                  />
                }
              />
            </div>
            </div>
          ))}
        </div>

        <hr className="mt-0" />
        
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-row btn-res mt-2">
              <button
                type={"button"}
                className={"btn btn-lg btn-outline-primary mr-2"}
                onClick={() => onCancel()}
              >
                {STRINGS.CANCEL}
              </button>
              <InputSubmit buttonLabel={"Save"} />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  let initialValues = {
    testType:
      props && props.data && props.data.userRole
        ? ADD_USER_ROLE_ITEMS[props.data.userRole - 2]
        : null,
    noOfQuestion: props.data && props.data.totalQuestions,
    document: props.data && props.data.documentsRequired,
  };
  return {
    initialValues: initialValues,
    answerType:
      state.form &&
      state.form.addTest &&
      state.form.addTest.values &&
      state.form.addTest.values.answer_type &&
      state.form.addTest.values.answer_type.value,
    questionType:
      state.form &&
      state.form.addTest &&
      state.form.addTest.values &&
      state.form.addTest.values.question_type &&
      state.form.addTest.values.question_type.value,
    document:
      state.form &&
      state.form.addTest &&
      state.form.document.values &&
      state.form.addTest.values.document &&
      state.form.addTest.values.document.value,
  };
};

const reduxFormFunction = reduxForm({
  form: "createTest",
  fields: ["testType", "noOfQuestion", "document"],
  validate: testValidator,
  enableReinitialize: true,
})(Screen);

export const CreateTest = connect(mapStateToProps, { changeField })(
  reduxFormFunction
);
