import { takeLatest, all, put, delay } from "redux-saga/effects";

import {
    SET_AUTHORIZATION,
    CHECK_LOGIN,
    SEND_FORGOT_EMAIL,
    setAuthorization,
    startLoader,
    stopLoader,
    setPlatformType,
    LOGOUT_USER,
    saveChampionship, 
    CHANGE_PASSWORD, 
    UPLOAD_PROFILE_PIC, 
    saveAdminInfo,
    setFirstLogin
} from '../actions';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const { updateAuthToken, postRequestNoAuth, postRequest, putRequest } = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);

function* setUserToken({ userToken }) {
    try {
        yield updateAuthToken(userToken);
    }
    catch (error) {
        return;
    }
}

function* checkAdminLogin({ credentials, success, onError }) {
    try {
        yield put(startLoader())
        const response = yield postRequestNoAuth({ API: `${api.URL.LOGIN}`, DATA: credentials });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            onError(response.data);
            yield put(stopLoader());
        }
        else {
            yield put(setAuthorization(response.data.data.token));
            yield put(setPlatformType(response.data.data.role));
            yield put(saveChampionship(response.data.data.championship));
            yield put(setFirstLogin(response.data.data.isFirstLoggedIn));
            yield put(
                saveAdminInfo({
                  name: response.data.data.name,
                  email: response.data.data.email,
                  profileUrl: response.data.data.profileUrl,
                  role: response.data.data.role
                })
              );
            success();
            yield put(stopLoader());
        }
    }
    catch (error) {
        return;
    }
}

function* sendRecoveryMail({ email, success, error }) {
    try {
        yield put(startLoader());
        const response = yield postRequestNoAuth({ API: `${api.URL.FORGOT_PASSWORD}`, DATA: email });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data);
            yield put(stopLoader());
        }
        else {
            success(response.data);
            yield put(stopLoader());
        }
    }
    catch (error) {
        return;
    }
}

function* logoutUser({ token, success }) {

    try {
        const response = yield postRequest({ API: `${api.URL.LOGOUT}`, DATA: {} });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            yield put(setAuthorization(null))
        }
        else {
            success();
        }
    }
    catch (error) {
        return;
    }
}

function* changePasswordSaga({ data, success, error }) {
    try {
        yield put(startLoader());
        const response = yield postRequest({ API: `${api.URL.CHANGE_PASSWORD}`, DATA: {...data} });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data.msg);
        }
        else {
            success(response.data.msg);
        }
    }
    catch (error) {
        return;
    }
    finally {
        yield put(stopLoader())
    }
}

function* uploadProfilePicSaga(action) {
  try {
    yield put(startLoader());
    var response = yield putRequest({
      API: `${api.URL.PROFILE_PIC_UPLOAD}`,
      DATA: {
        ...action.data
      }
    });
    if (response.status === STATUS_CODE.unAuthorized) {
        yield put(setAuthorization(null));
        return;
    }
    if (response.status === STATUS_CODE.successful) {
      yield action.handleSuccess(response.data.msg);
    } else {
      yield action.handleError(response.data.msg);
    }
  } catch (error) {
    yield action.handleError("Something went wrong");
  } finally {
    yield put(stopLoader());
  }
}

function* AuthSaga() {
    yield all([
        takeLatest(SET_AUTHORIZATION, setUserToken),
        takeLatest(CHECK_LOGIN, checkAdminLogin),
        takeLatest(SEND_FORGOT_EMAIL, sendRecoveryMail),
        takeLatest(LOGOUT_USER, logoutUser),
        takeLatest(CHANGE_PASSWORD, changePasswordSaga),
        takeLatest(UPLOAD_PROFILE_PIC, uploadProfilePicSaga)
    ]);
}

export default AuthSaga;
