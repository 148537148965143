import {
    SAVE_UNBLOCK_REQUEST_LIST,
    SAVE_USER_TEST_LIST,
} from '../actions';

const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);

const initialCommonState = {
    unBlockRequestList: null,
    usersTestList: null
};

const RaceDirectorReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case SAVE_UNBLOCK_REQUEST_LIST:
            return {
                ...state,
                unBlockRequestList: action.data
            };
        case SAVE_USER_TEST_LIST:
            return {
                ...state,
                usersTestList: action.data
            }
        default:
            return state;
    }
};

export default RaceDirectorReducer;