import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    revertEmergencyAccess,
    giveEmergencyAccess,
    fetchUsersList
} = require(`../../../../../../redux/${PLATFORM}/actions`)

const mapStateToProps = (state) => {
    return ({
        eventList: state.EventReducer.eventList,
        platformType: state.CommonReducer.platformType,
        championshipData: state.CommonReducer.championshipData,
        usersList: state.UserReducer.usersList,
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader()),
        giveEmergencyAccess: (data, success, failure) => dispatch(giveEmergencyAccess(data, success, failure)),
        fetchUsersList: (data, success, failure) => dispatch(fetchUsersList(data, success, failure)),
        revertEmergencyAccess: (data, success, failure) => dispatch(revertEmergencyAccess(data, success, failure)),
    }
}
export const EmergencyScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);