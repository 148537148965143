const {
  defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);

const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/us/strings`);
const { VALIDATION_MESSAGES, EMAIL_REGX, NAME_REGX, CHAMPIONSHIP_NAME_REGEX } = require(`../../../../../../shared/${PLATFORM}/constants`)

const validator = (values) => {
  const errors = {};
  if (!values[STRINGS.CHAMPIONSHIP_INPUT]) {
    errors[STRINGS.CHAMPIONSHIP_INPUT] =
      VALIDATION_MESSAGES.CHAMPIONSHIP_NAME_REQUIRED;
  }
  if (values[STRINGS.CHAMPIONSHIP_INPUT] && !values[STRINGS.CHAMPIONSHIP_INPUT].trim()) {
    errors[STRINGS.CHAMPIONSHIP_INPUT] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (
    !CHAMPIONSHIP_NAME_REGEX.test(
      values[STRINGS.CHAMPIONSHIP_INPUT]
    )
  ) {
    errors[STRINGS.CHAMPIONSHIP_INPUT] =
      "Championship Name cannot have special symbols."
  }
  if (!values[STRINGS.CHAMPIONSHIP_DETAILS]) {
    errors[STRINGS.CHAMPIONSHIP_DETAILS] =
      VALIDATION_MESSAGES.CHAMPIONSHIP_DESCRIPTION_REQUIRED;
  }
  if (values[STRINGS.CHAMPIONSHIP_DETAILS] && !values[STRINGS.CHAMPIONSHIP_DETAILS].trim()) {
    errors[STRINGS.CHAMPIONSHIP_DETAILS] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (!values[STRINGS.START_DATE_NAME]) {
    errors[STRINGS.START_DATE_NAME] =
      VALIDATION_MESSAGES.START_DATE_REQUIRED;
  }
  if (!values[STRINGS.END_DATE_NAME]) {
    errors[STRINGS.END_DATE_NAME] =
      VALIDATION_MESSAGES.END_DATE_REQUIRED;
  }
  if (values[STRINGS.START_DATE_NAME] && values[STRINGS.END_DATE_NAME]) {
    if (new Date(values[STRINGS.END_DATE_NAME]).getTime() <= new Date(values[STRINGS.START_DATE_NAME]).getTime()) {
      errors[STRINGS.END_DATE_NAME] = VALIDATION_MESSAGES.END_DATE_MUST_BE_GREATER;
    }
  }


  if (values.members && values.members.length) {
    const membersArrayErrors = []
    values.members.forEach((member, memberIndex) => {
      const memberErrors = {}
      if (!member.name) {
        memberErrors.name = VALIDATION_MESSAGES.NAME_REQUIRED
        membersArrayErrors[memberIndex] = memberErrors
      }
      else if (
        !NAME_REGX.test(member.name)
      ) {
        memberErrors.name =
          VALIDATION_MESSAGES.INVALID_NAME;
        membersArrayErrors[memberIndex] = memberErrors
      }
      else if (member.name && !member.name.trim()) {
        memberErrors.name = VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
        membersArrayErrors[memberIndex] = memberErrors
      }
      if (!member.email) {
        memberErrors.email = VALIDATION_MESSAGES.EMAIL_REQUIRED
        membersArrayErrors[memberIndex] = memberErrors
      }
      else if (member.email && !EMAIL_REGX.test(member.email)) {
        memberErrors.email = VALIDATION_MESSAGES.EMAIL_INVALID
        membersArrayErrors[memberIndex] = memberErrors
      }

    })
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors
    }

  }

  return errors;
};

export default validator;
