import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    addTest,
    updateTest,
    stopLoader,
    startLoader,
    deleteTest,
    fetchTestList,
    createQuestion,
    saveInstruction,
    fetchQuestionList,
    fetchChampionships,
    deleteQuestion,
    createTest,
    updateTestQuestion,
    fetchQuestionDetails,
    uploadFile,
    fetchUserTypes
} = require(`../../../../../../redux/sef/actions`)


const mapStateToProps = (state) => {

    return ({
        testList: state.TestReducer.testList,
        questionList: state.TestReducer.questionList,
        questionDetails: state.TestReducer.questionDetails,
        platformType: state.CommonReducer.platformType,
        championshipData: state.CommonReducer.championshipData,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        stopLoader: () => dispatch(stopLoader()),
        startLoader: () => dispatch(startLoader()),
        fetchTestList: data => dispatch(fetchTestList(data)),
        fetchQuestionList: (data, success, failure) => dispatch(fetchQuestionList(data, success, failure)),
        addTest: data => dispatch(addTest(data)),
        updateTest: (data, success, failure) => dispatch(updateTest(data, success, failure)),
        deleteTest: data => dispatch(deleteTest(data)),
        saveInstruction: (data, success, failure) => dispatch(saveInstruction(data, success, failure)),
        fetchChampionships: (data, success, failure) => dispatch(fetchChampionships(data, success, failure)),
        createQuestion: data => dispatch(createQuestion(data)),
        createTest: (data, success, failure) => dispatch(createTest(data, success, failure)),
        deleteQuestion: (data, success, failure) => dispatch(deleteQuestion(data, success, failure)),
        uploadFile: (data, success, failure) => dispatch(uploadFile(data, success, failure)),
        fetchQuestionDetails: (id, success, failure) => dispatch(fetchQuestionDetails(id, success, failure)),
        updateTestQuestion: (id, success, failure) => dispatch(updateTestQuestion(id, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure))
    }
}
export const EditTestScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);
