import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    updateEvent,
    fetchEventDetails
} = require(`../../../../../../redux/${PLATFORM}/actions`)

const mapStateToProps = (state) => {
    return ({
        eventDetails: state.EventReducer.eventDetails,
        platformType: state.CommonReducer.platformType,
        championshipData: state.CommonReducer.championshipData
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchEventDetails: (id, success, failure) => dispatch(fetchEventDetails(id, success, failure)),
        updateEvent: (id, success, failure) => dispatch(updateEvent(id, success, failure)),
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader())
    }
}
export const EventDetailsScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);