const {
    defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);
const { STRINGS } = require(`../../../../../../shared/sef/constants/us/strings`);
const { VALIDATION_MESSAGES } = require(`../../../../../../shared/sef/constants`)



export const validator = (values) => {
    const errors = {};
    if (!values[STRINGS.USER_TYPE_INPUT]) {
        errors[STRINGS.USER_TYPE_INPUT] = VALIDATION_MESSAGES.USER_TYPE_REQUIRED;
    }
    if (!values[STRINGS.QUESTION_TITLE]) {
        errors[STRINGS.QUESTION_TITLE] = VALIDATION_MESSAGES.QUESTION_TITLE_REQUIRED;
    }
    if (values[STRINGS.QUESTION_TITLE] && !values[STRINGS.QUESTION_TITLE].trim()) {
        errors[STRINGS.QUESTION_TITLE] = VALIDATION_MESSAGES.QUESTION_TITLE_REQUIRED;
    }
    if (!values[STRINGS.QUESTION_TYPE]) {
        errors[STRINGS.QUESTION_TYPE] = VALIDATION_MESSAGES.QUESTION_TYPE_REQUIRED;
    }
    if (!values[STRINGS.ANSWER_TYPE]) {
        errors[STRINGS.ANSWER_TYPE] = VALIDATION_MESSAGES.ANSWER_TYPE_REQUIRED;
    }
    if (!values[STRINGS.RIGHT_OPTION_LIST]) {
        errors[STRINGS.RIGHT_OPTION_LIST] = VALIDATION_MESSAGES.ANSWER_TYPE_REQUIRED;
    }
    if (values[STRINGS.TEST_INSTRUCTION] && !values[STRINGS.TEST_INSTRUCTION].trim()) {
        errors[STRINGS.TEST_INSTRUCTION] =
            VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
    }
    if (values[STRINGS.QUESTION_TYPE] && values[STRINGS.QUESTION_TYPE].value > 1 && !values[STRINGS.FILE]) {
        errors[STRINGS.FILE] = 'Please select file.';
    }
    if (values[STRINGS.ANSWER_TYPE] && values[STRINGS.RIGHT_OPTION_LIST] && values[STRINGS.ANSWER_TYPE].value === 2 && values[STRINGS.RIGHT_OPTION_LIST].length === 1) {
        errors[STRINGS.RIGHT_OPTION_LIST] = 'Please select atleast one more answer.';
    }

    if (values.editTime && values.editTime.length) {
        const timeArrayErrors = []
        values.editTime.forEach((timeField, memberIndex) => {
            const timeErrors = {}
            if (!timeField.question_minute && timeField.question_minute !== 0) {
                timeErrors.question_minute = 'Minutes are required.'
                timeArrayErrors[memberIndex] = timeErrors
            }
            if (!timeField.question_seconds && timeField.question_seconds !== 0) {
                timeErrors.question_seconds = 'Seconds are required.'
                timeArrayErrors[memberIndex] = timeErrors
            }
            if (timeField.question_minute && (parseInt(timeField.question_minute) > 60)) {
                timeErrors.question_minute = 'Minutes should be less than or equal to 60.'
                timeArrayErrors[memberIndex] = timeErrors
            }
            if (timeField.question_minute && (parseInt(timeField.question_minute) < 0)) {
                timeErrors.question_minute = 'Minutes cannot be negative.'
                timeArrayErrors[memberIndex] = timeErrors
            }
            if (timeField.question_seconds && (parseInt(timeField.question_seconds) > 59)) {
                timeErrors.question_seconds = 'Seconds should be less than or equal to 59.'
                timeArrayErrors[memberIndex] = timeErrors
            }
            if (timeField.question_seconds && (parseInt(timeField.question_seconds) < 0)) {
                timeErrors.question_seconds = 'Seconds cannot be negative.'
                timeArrayErrors[memberIndex] = timeErrors
            }
            if ((parseInt(timeField.question_minute) === 0 && parseInt(timeField.question_seconds) === 0)) {
                timeErrors.question_seconds = 'You cannot set 00:00 seconds as question timing.'
                timeArrayErrors[memberIndex] = timeErrors
            }
        })
        if (timeArrayErrors.length) {
            errors.editTime = timeArrayErrors
        }

    }

    if (values.members && values.members.length) {
        const membersArrayErrors = []
        values.members.forEach((member, memberIndex) => {
            const memberErrors = {}
            if (!member.answer) {
                memberErrors.answer = 'Answer option is required.'
                membersArrayErrors[memberIndex] = memberErrors
            }
        })
        if (membersArrayErrors.length) {
            errors.members = membersArrayErrors
        }
    }

    return errors;
}