import {
    SAVE_EVENT_LIST,
    SAVE_EVENT_DETAILS,
    SAVE_ATTENDEES_LIST
} from '../actions';

const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);

const initialCommonState = {
    eventList: null,
    eventDetails: null,
    attendees: null
};

const EventReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case SAVE_EVENT_LIST:
            return {
                ...state,
                eventList: action.data
            }
        case SAVE_EVENT_DETAILS:
            return {
                ...state,
                eventDetails: action.data
            }
        case SAVE_ATTENDEES_LIST:
            return {
                ...state,
                attendees: action.data
            }
        default:
            return state;
    }
};

export default EventReducer;