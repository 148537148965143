import React, { useEffect, useState } from "react";
import "./style.scss";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
const {
  defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);
const { CountTeller } = require(`../../../../../../components/${PLATFORM}/atoms/count-teller`);
const {
  DASHBOARD_PAGE_LOGO,
  ROLE_STATS_ICONS,
  PAGE_TITLES,
  DEFAULT_STAT_RED,
  DEFAULT_STAT_BLUE,
  SUB_ADMIN_PLATFORM,
  ROUTES
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);

export const Screen = ({
  platformType,
  fetchAllUsersStats,
  fetchChampionshipUserStats,
  championshipUserStat,
  isFirstLogin,
  setFirstLogin = () => {}
}) => {
  let history = useHistory();
  const [popupVisible, setPopVisible] = useState(false);
  const [popupData, setPopupData] = useState({
    popupContent: "",
    popupTitle: "",
    onConfirmation: () => {}
  });

  const showChangePasswordDialog = () => {
    setPopupData({
      popupTitle: "Change Password?",
      popupContent: "You can change your password from the profile screen. Do you wish to change now?",
      confirmButton: "Change",
      rejectButton: "Cancel",
      onConfirmation: () => {
        history.push(ROUTES.PROFILE);
        setFirstLogin(true);
      }
    });
    setPopVisible(true);
  };

  useEffect(() => {
    platformType === SUB_ADMIN_PLATFORM ? fetchChampionshipUserStats() : fetchAllUsersStats();
  }, []);

  useEffect(() => {
    if (platformType === SUB_ADMIN_PLATFORM && !isFirstLogin) {
      showChangePasswordDialog();
    }
  }, [isFirstLogin]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.dashboard}</title>
      </Helmet>
      <div className="main-panel d-flex flex-column">
        <div className="content-wrapper">
          <div className="row row-eq-height das_blocks">
            <DecisionPopup
              modalVisibility={popupVisible}
              dialogContent={popupData.popupContent}
              dialogTitle={popupData.popupTitle}
              confirmButtonTitle={popupData.confirmButton}
              rejectButtonTitle={popupData.rejectButton}
              toggleDialogModal={() => setPopVisible(!popupVisible)}
              onConfirmation={popupData.onConfirmation}
              onRejection={() => {
                setPopVisible(false);
                setFirstLogin(true);
              }}
            />
            {championshipUserStat &&
              championshipUserStat.length &&
              championshipUserStat.map((item, index) => (
                <CountTeller
                  tellerIcon={ROLE_STATS_ICONS[item.role - 1] ? ROLE_STATS_ICONS[item.role - 1] : DEFAULT_STAT_RED}
                  count={item.total}
                  title={`Total ${item.label}${index < 3 ? "s" : ""}`}
                />
              ))}
          </div>
          <div className="row text-center align-items-center py-3 py-md-5 mt-2 mt-md-5">
            <div className="col-12">
              <figure className="mb-0">
                <img src={DASHBOARD_PAGE_LOGO} alt={"dashboard-logo"} className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
