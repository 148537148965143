import { REHYDRATE } from 'redux-persist';
import {
    FETCH_CHAMPIONSHIP_USER_STATS,
    SAVE_USER_STATISTICS,
    SAVE_CHAMPIONSHIP_USER_STATS,
    SAVE_USERS_REQUEST_LIST,
    SAVE_USERS_LIST,
    SAVE_USER_INFO,
    UPDATE_AMBASSADOR_ACCESS,
    SAVE_AMBASSADORS_LIST,
    SAVE_USER_TYPE_LIST,
    SAVE_HOLDING_AREA_USERS,
    SAVE_HOLDING_ROLES, 
    SAVE_ADMIN_INFO
} from '../actions';

const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);

const initialCommonState = {
    championshipUserStat: null,
    usersRequestList: null,
    usersList: null,
    userDetails: null,
    userStats: null,
    userTypeList: null,
    ambassadorsList: null,
    holdingList: null,
    holdingUserRoles: null,
    adminDetails: null,
};

const UserReducer = (state = { ...initialCommonState }, action) => {

    switch (action.type) {
        case SAVE_CHAMPIONSHIP_USER_STATS:
            return {
                ...state,
                championshipUserStat: action.data
            };
        case SAVE_USERS_REQUEST_LIST:
            return {
                ...state,
                usersRequestList: action.data
            }
        case SAVE_USERS_LIST:
            return {
                ...state,
                usersList: action.data
            }
        case SAVE_USER_INFO:
            return {
                ...state,
                userDetails: action.data
            }
        case SAVE_ADMIN_INFO:{
            return {
                ...state,
                adminDetails: action.data
            }
        }
        case SAVE_USER_STATISTICS:
            return {
                ...state,
                userStats: action.data
            }
        case SAVE_USER_TYPE_LIST:
            return {
                ...state,
                userTypeList: action.data
            }
        case UPDATE_AMBASSADOR_ACCESS:
            return {
                ...state,
                ambassadorsList: {
                    ...state.ambassadorsList,
                    data: action.data
                }
            }
        case SAVE_AMBASSADORS_LIST:
            return {
                ...state,
                ambassadorsList: action.data
            }
        case SAVE_HOLDING_AREA_USERS:
            return {
                ...state,
                holdingList: action.data
            }
        case SAVE_HOLDING_ROLES:
            return {
                ...state,
                holdingUserRoles: action.data
            }
            case REHYDRATE:
                let user = ((action || {}).payload || {}).UserReducer || initialCommonState;
                return {
                    ...state,
                    adminDetails: user.adminDetails,
                    ...(action.payload || {}).user
                };
        default:
            return state;
    }
};

export default UserReducer;