import React, { useState } from "react";
import { reduxForm, reset, Field, change as changeField } from "redux-form";
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { TextArea } = require(`../../../../../../components/${PLATFORM}/atoms/text-area`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { DatePickerInput } = require(`../../../../../../components/${PLATFORM}/atoms/date-picker`);
const { TimePickerInput } = require(`../../../../../../components/${PLATFORM}/atoms/time-picker`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { CHECK_IN_HOURS_ITEMS } = require(`../../../../../../shared/${PLATFORM}/constants`)
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

const AddEventForm = ({
    handleSubmit = () => { },
    onSubmit = () => { },
    onCancel = () => { },
    onHoursChange = () => { },
    changeField
}) => {
    let [startDate, setStartDate] = useState('')
    let [startTime, setStartTime] = useState('')
    let [minEndDate, setMinEndDate] = useState('')

    const onChangeDate = (value) => {
        var copiedDate = new Date(value);
        changeField("event", "start-date", value)
        setStartDate(value)
        setMinEndDate(copiedDate.setDate(value.getDate() + 1))
    }

    const onChangeTime = (value) => {
        setStartTime(value);
        changeField("event", "start-time", value)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.EVENT_INPUT}
                    component={Input}
                    label={STRINGS.EVENT_NAME}
                    type={'text'}
                    placeholder={STRINGS.EVENT_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.EVENT_DETAILS}
                    component={TextArea}
                    label={STRINGS.EVENT_DESCRIPTION_LABEL}
                    type={'text'}
                    placeholder={STRINGS.EVENT_DETAILS_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.START_DATE_NAME}
                    component={DatePickerInput}
                    minDate={new Date()}
                    label={STRINGS.DATE}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    dateValue={startDate}
                    onChangeDate={onChangeDate}
                />
                <Field
                    name={STRINGS.START_TIME_NAME}
                    component={TimePickerInput}
                    minDate={minEndDate}
                    label={STRINGS.START_TIME}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    disabled={startDate ? false : true}
                    timeValue={startTime}
                    onChangeTime={onChangeTime}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.COUNTRY_INPUT}
                    component={Input}
                    label={STRINGS.COUNTRY}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    placeholder={STRINGS.COUNTRY_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.CITY_INPUT}
                    component={Input}
                    label={STRINGS.CITY}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    placeholder={STRINGS.CITY_NAME_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.CIRCUIT_INPUT}
                    component={Input}
                    label={STRINGS.CIRCUIT_NAME}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    placeholder={STRINGS.CIRCUIT_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.CHECKIN_HOURS_INPUT}
                    label={STRINGS.CHECKIN_HOURS}
                    component={Select}
                    isSearchable={false}
                    options={CHECK_IN_HOURS_ITEMS}
                    onValueChange={(value) => onHoursChange(value)}
                    placeholder={STRINGS.CHECKIN_PLACEHOLDER}
                    widthStyle={'col-md-6'}
                />
            </div>


            <hr className="mt-0" />
            <div className="d-flex flex-column flex-sm-row justify-content-sm-start re_btn_custom">
                <button type={'button'} className={'reject-button ml-0'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit
                    buttonType={'submit'}
                    buttonLabel={STRINGS.ADD_EVENT} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const afterSubmit = (result, dispatch) => {
}

const mapStateToProps = (state, props) => {
    return {
        event: state.form.event || {}

    };
}

const reduxFormFunction = reduxForm({
    form: "event",
    onSubmitSuccess: afterSubmit,
    validate: validator,
    enableReinitialize: true
})(AddEventForm);

export const EventReduxForm = connect(mapStateToProps, { changeField })(reduxFormFunction);