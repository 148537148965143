import { combineReducers } from "redux";
import CommonReducer from './common';
import UserReducer from './users';
import ChampionshipReducer from './championships';
import RaceDirectorReducer from './race-director';
import EventReducer from './events';
import TestReducer from './test';
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
    CommonReducer,
    form: formReducer,
    UserReducer,
    ChampionshipReducer,
    EventReducer,
    TestReducer, RaceDirectorReducer
});

export default rootReducer;
