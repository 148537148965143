import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { fetchUserInfo, fetchUserStatistics,
    startLoader,
    stopLoader,
    fetchUserTypes,
    updateBlockStatus
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        userDetails: state.UserReducer.userDetails,
        userStats: state.UserReducer.userStats,
        userTypeList: state.UserReducer.userTypeList,
        championshipData: state.CommonReducer.championshipData,
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        stopLoader: () => dispatch(stopLoader()),
        startLoader: () => dispatch(startLoader()),
        fetchUserInfo: (id, success, error) => dispatch(fetchUserInfo(id, success, error)),
        fetchUserStatistics: (id, success, failure) => dispatch(fetchUserStatistics(id, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure)),
        updateBlockStatus: (data, success, failure) => dispatch(updateBlockStatus(data, success, failure)),

    }
}
export const UserTestDetails = connect(mapStateToProps, mapDispatchToProps)(Screen);
