import React, { useEffect, useState } from 'react';
import {
    Route,
    Switch,
    Redirect,
    useHistory
} from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
const { defaultConfig: { PLATFORM, LOCATION, VERSION } } = require(`../../../../config/default`);
const { STRINGS } = require(`../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { DecisionPopup } = require(`../../../../components/${PLATFORM}/atoms/decision-popup`);
const { ScreenHOC } = require(`../../../../components/${PLATFORM}/hoc/screen-hoc`);
const { PrivacyPolicy } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/privacy-policy`);
const { HomeScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/home`);
const { FeedScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/feeds`);
const { ChatScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/chats`);
const { EventScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/events`);
const { TestScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/tests`);
const { AttendeesScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/attendees`);
const { EventDetailsScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/event-details`);
const { ChampionshipScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/super-admin/championships`);
const { RoleScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/super-admin/manage-roles`);
const { HoldingArea } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/super-admin/holding-area`);
const { ChampionshipDetailsScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/super-admin/championship-details`);
const { UserDetailScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/super-admin/user-details`);
const {ProfileScreen} = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/super-admin/profile`);
const { styles } = require(`../../../../theme/${PLATFORM}`);
const {
    SUPER_ADMIN_DRAWER_ITEMS,
    HAMBURGER_ICON,
    HEADER_LOGO,
    CLOSE_ICON,
    ROUTES,
    HEADER_PROFILE_ITEMS
} = require(`../../../../shared/${PLATFORM}/constants`);
const {
    setAuthorization,
    stopLoader,
    logout
} = require(`../../../../redux/${PLATFORM}/actions`);

const SuperAdminRouter = (props) => {
    const { classes, setAuthorization, userToken, logout } = props;
    const [popupVisible, setPopVisible] = useState(false);
    let history = useHistory();
    return (
        <div>
            <ScreenHOC
                containerStyle={classes.screenHocContainer}
                childrenStyle={classes.screenContentContainer}
                drawerItems={SUPER_ADMIN_DRAWER_ITEMS}
                headerMenuItem={HEADER_PROFILE_ITEMS}
                onClickAction={(changedUrl) => {
                    if (changedUrl === ROUTES.LOGOUT) {
                        setPopVisible(true);
                    }
                    else {
                        history.push(changedUrl);
                    }
                }}
                hamburgerIcon={HAMBURGER_ICON}
                headerLogo={HEADER_LOGO}
                headerLogoAction={() => {
                    history.replace(ROUTES.DASHBOARD)
                }}
                backArrow={CLOSE_ICON}
            >
                <DecisionPopup
                    modalVisibility={popupVisible}
                    dialogContent={STRINGS.LOGOUT_CONTENT}
                    dialogTitle={STRINGS.LOGOUT}
                    confirmButtonTitle={STRINGS.CONFIRM}
                    rejectButtonTitle={STRINGS.CANCEL}
                    toggleDialogModal={() => setPopVisible(!popupVisible)}
                    onConfirmation={() => {
                        logout(userToken, () => {
                            setPopVisible(false)
                            setAuthorization(null);
                            return <Redirect to={ROUTES.ROOT} />
                        });
                    }}
                    onRejection={() => setPopVisible(false)}
                />
                <Switch>
                    <Route exact path={`${ROUTES.DASHBOARD}`} component={HomeScreen} />
                    <Route exact path={`${ROUTES.NEWS_FEEDS}`} component={FeedScreen} />
                    <Route exact path={`${ROUTES.EVENTS}`} component={EventScreen} />
                    <Route exact path={`${ROUTES.CHATS}`} component={ChatScreen} />
                    <Route exact path={`${ROUTES.CHAMPIONSHIPS}`} component={ChampionshipScreen} />
                    <Route exact path={`${ROUTES.TESTS}`} component={TestScreen} />
                    <Route exact path={`${ROUTES.CHAMPIONSHIP_DETAIL}`} component={ChampionshipDetailsScreen} />
                    <Route exact path={`${ROUTES.EVENTS_DETAILS}`} component={EventDetailsScreen} />
                    <Route exact path={`${ROUTES.ATTENDEES}`} component={AttendeesScreen} />
                    <Route exact path={`${ROUTES.USER_DETAIL}`} component={UserDetailScreen} />
                    <Route exact path={`${ROUTES.MANAGE_ROLES}`} component={RoleScreen} />
                    <Route exact path={`${ROUTES.HOLDING_AREA}`} component={HoldingArea} />
                    <Route exact path={`${ROUTES.PRIVACY_POLICY}`} component={PrivacyPolicy} />
                    <Route exact path={`${ROUTES.PROFILE}`} component={ProfileScreen} />
                    <Redirect to={ROUTES.DASHBOARD} />
                </Switch>
            </ScreenHOC>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        userToken: state.CommonReducer.userToken,
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAuthorization: (userData) => dispatch(setAuthorization(userData)),
        stopLoader: () => dispatch(stopLoader()),
        logout: (token, success) => dispatch(logout(token, success))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuperAdminRouter));
