import { takeLatest, all, put, delay } from "redux-saga/effects";

import {
    saveUsersRequestList,
    FETCH_USERS_REQUESTS,
    UPDATE_APPROVAL_STATUS,
    FETCH_CHAMPIONSHIP_USER_STATS,
    saveChampionshipUserStats,
    FETCH_USERS_LIST,
    UPLOAD_CSV_FILE,
    RE_SEND_INVITE,
    FETCH_ALL_USERS_STATS,
    FETCH_USER_STATISTICS,
    saveUsersList,
    saveUserStatistics,
    saveUserInfo,
    setAuthorization,
    FETCH_USER_INFO,
    FETCH_USER_TYPES,
    ADD_NEW_USER,
    EDIT_USER_TYPE,
    ADD_USER_TYPE,
    UPDATE_ACTIVATION_STATUS,
    FETCH_AMBASSADORS_LIST,
    GET_HOLDING_AREA_USERS,
    saveHoldingRoles,
    FETCH_HOLDING_ROLES,
    saveAmbassadorsList,
    saveUserTypeList,
    startLoader,
    stopLoader,
    saveHoldingAreaUser
} from '../actions';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const { getRequest, postRequest, putRequest } = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);

const createFormData = (fileData) => {
    const data = new FormData();
    data.append("file", fileData.file);

    return data;
};

function* fetchUserTypes(success, failure) {
    try {
        const response = yield getRequest({ API: api.URL.GET_USER_TYPES });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveUserTypeList(response.data.data));
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* addNewUserType({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.ADD_USER_TYPE}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        failure({ status: false, msg: "Something went wrong" });
        return;
    }
}

function* updateUserType({ data, success, failure }) {
    try {
        const response = yield putRequest({ API: `${api.URL.ADD_USER_TYPE}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* fetchChampionshipUserStats() {
    try {
        const response = yield getRequest({ API: api.URL.CHAMPIONSHIP_USERS_STATS });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
        }
        else {
            yield put(saveChampionshipUserStats(response.data.data))
        }
    }
    catch (error) {
        return;
    }
}

function* fetchAllUserStats() {
    try {
        const response = yield getRequest({ API: api.URL.USER_STAT });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
        }
        else {
            yield put(saveChampionshipUserStats(response.data.data))
        }
    }
    catch (error) {
        return;
    }
}

function* fetchUserDetails({ id, success, error }) {
    try {
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.USER_INFO}/${id}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            yield put(stopLoader());
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data)
            yield put(stopLoader());
        }
        else {
            yield put(saveUserInfo(response.data.data))
            yield put(stopLoader());
        }
    }
    catch (error) {
        return;
    }
}

function* fetchUserStatistics({ id, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.USER_STATISTICS}`, DATA: id });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveUserStatistics(response.data.data));
            success(response.data)

        }
    }
    catch (error) {
        return;
    }
}

function* fetchChampionshipRequests({ data, success, failure }) {
    try {
        if (!data.searchString) {
            delete data.searchString;
        }
        const response = yield postRequest({ API: `${api.URL.USERS_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveUsersRequestList(response.data.data));
            success();
        }
    }
    catch (error) {
        return;
    }
}

function* reSendInvitation({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.REINVITE_USER}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveUsersRequestList(response.data.data[0]));
            success();
        }
    }
    catch (error) {
        return;
    }
}

function* uploadCSV({ formData, success, error }) {
    const file = createFormData(formData);
    try {
        const response = yield postRequest({ API: `${api.URL.UPLOAD_CSV}`, DATA: file, HEADER: { "Content-Type": "multipart/form-data" } });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data);
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* fetchChampionshipUsers({ data, success, failure }) {
    try {
        if (!data.searchString) {
            delete data.searchString;
        }
        const response = yield postRequest({ API: `${api.URL.USERS_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveUsersList(response.data.data));
            success();
        }
    }
    catch (error) {
        return;
    }
}

function* addNewUsers({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.ADD_NEW_USER}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveUsersList(response.data.data[0]));
            success(response.data);
        }
    }
    catch (error) {
        failure({ status: false, msg: "Something went wrong" });
        return;
    }
}

function* updateUserApproval({ data, success }) {
    try {
        const response = yield putRequest({ API: `${api.URL.APPROVE_STATUS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* updateActivationStatus({ data, success }) {
    try {
        const response = yield putRequest({ API: `${api.URL.UPDATE_ACTIVE_STATUS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* fetchChampionshipAmbassadors({
    data,
    success,
    failure
}) {
    try {
        if (!data.searchString) {
            yield delete data.searchString;
        }
        const response = yield postRequest({
            API: `${api.URL.AMBASSADORS_LIST}`,
            DATA: data
        });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            yield failure(response.data)
        } else {
            yield put(saveAmbassadorsList(response.data.data));
            yield success();
        }
    } catch (error) {
        yield failure({ msg: 'Something Went Wrong', status: false });
        return;
    }
}

function* fetchHoldingAreaList({ data, success, failure }) {
    try {
        if (!data.searchString) {
            delete data.searchString;
        }
        const response = yield postRequest({ API: `${api.URL.HOLDING_AREA_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveHoldingAreaUser(response.data.data[0]));
            success(response.data);
        }
    }
    catch (error) {
        console.log('error', error)
        failure({ status: false, msg: "Something went wrong" });
        return;
    }
}

function* fetchHoldingRolesList({ id, success, error }) {
    try {
        yield put(startLoader());
        const response = yield getRequest({ API: `${api.URL.HOLDING_AREA_ROLES}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            yield put(stopLoader());
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data)
            yield put(stopLoader());
        }
        else {
            yield put(saveHoldingRoles(response.data.data.userTypes))
            yield put(stopLoader());
        }
    }
    catch (error) {
        return;
    }
}

function* UserSaga() {
    yield all([
        takeLatest(FETCH_ALL_USERS_STATS, fetchAllUserStats),
        takeLatest(FETCH_CHAMPIONSHIP_USER_STATS, fetchChampionshipUserStats),
        takeLatest(FETCH_USERS_REQUESTS, fetchChampionshipRequests),
        takeLatest(UPDATE_APPROVAL_STATUS, updateUserApproval),
        takeLatest(FETCH_USERS_LIST, fetchChampionshipUsers),
        takeLatest(UPLOAD_CSV_FILE, uploadCSV),
        takeLatest(UPDATE_ACTIVATION_STATUS, updateActivationStatus),
        takeLatest(ADD_NEW_USER, addNewUsers),
        takeLatest(RE_SEND_INVITE, reSendInvitation),
        takeLatest(FETCH_USER_INFO, fetchUserDetails),
        takeLatest(FETCH_USER_STATISTICS, fetchUserStatistics),
        takeLatest(FETCH_AMBASSADORS_LIST, fetchChampionshipAmbassadors),
        takeLatest(FETCH_USER_TYPES, fetchUserTypes),
        takeLatest(ADD_USER_TYPE, addNewUserType),
        takeLatest(EDIT_USER_TYPE, updateUserType),
        takeLatest(GET_HOLDING_AREA_USERS, fetchHoldingAreaList),
        takeLatest(FETCH_HOLDING_ROLES, fetchHoldingRolesList)
    ]);
}

export default UserSaga;
