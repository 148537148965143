export const ROUTES = {
    ROOT: '/',
    DASHBOARD: '/dashboard',
    LOGIN: '/login',
    REDIRECT: '/redirect',
    LOGOUT: '/logout',
    FORGOT_PASSWORD: '/forgot-password',
    NEWS_FEEDS: '/feeds',
    USERS: '/users',
    TESTS: '/tests',
    CHATS: '/chats',
    EVENTS: '/events',
    REQUESTS: '/requests',
    AMBASSADORS: '/ambassadors',
    EMERGENCY_ACCESS: '/emergency-access',
    USER_DETAIL: '/users-details',
    CHAMPIONSHIPS: '/championships',
    CHAMPIONSHIP_DETAIL: '/championships-details',
    EVENTS_DETAILS: '/events-details',
    ATTENDEES: '/events-attendees',
    EDIT_TEST_QUESTION: '/test-question',
    MANAGE_ROLES: '/user-types',
    USERS_TESTS: '/users-tests',
    USER_TEST_DETAILS: '/user-test-details',
    HOLDING_AREA: '/holding-area',
    PRIVACY_POLICY: '/privacy-policy',
    PROFILE:'/profile'

}