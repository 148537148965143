import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { fetchUserInfo, fetchUserStatistics } = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        userDetails: state.UserReducer.userDetails,
        userStats: state.UserReducer.userStats,
        championshipData: state.CommonReducer.championshipData,
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserInfo: (id, success, error) => dispatch(fetchUserInfo(id, success, error)),
        fetchUserStatistics: (id, success, failure) => dispatch(fetchUserStatistics(id, success, failure))
    }
}
export const UserDetailScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);