const {
  defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);

const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/us/strings`);
const { VALIDATION_MESSAGES, EMAIL_REGX, NAME_REGX, CHAMPIONSHIP_NAME_REGEX } = require(`../../../../../../shared/${PLATFORM}/constants`)

const validator = values => {
  const errors = {};
  if (!values[STRINGS.EMAIL_INPUT_NAME]) {
    errors[STRINGS.EMAIL_INPUT_NAME] =
      VALIDATION_MESSAGES.EMAIL_REQUIRED;
  } else if (
    !EMAIL_REGX.test(
      values[STRINGS.EMAIL_INPUT_NAME].toLowerCase()
    )
  ) {
    errors[STRINGS.EMAIL_INPUT_NAME] =
      VALIDATION_MESSAGES.EMAIL_INVALID;
  }
  if (!values[STRINGS.CHAMPIONSHIP_INPUT]) {
    errors[STRINGS.CHAMPIONSHIP_INPUT] =
      VALIDATION_MESSAGES.CHAMPIONSHIP_NAME_REQUIRED;
  } else if (
    !CHAMPIONSHIP_NAME_REGEX.test(values[STRINGS.CHAMPIONSHIP_INPUT])
  ) {
    errors[STRINGS.CHAMPIONSHIP_INPUT] =
      "Championship Name cannot have special symbols."
  }
  if (values[STRINGS.CHAMPIONSHIP_INPUT] && !values[STRINGS.CHAMPIONSHIP_INPUT].trim()) {
    errors[STRINGS.CHAMPIONSHIP_INPUT] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }

  if (!values[STRINGS.NAME_INPUT]) {
    errors[STRINGS.NAME_INPUT] =
      VALIDATION_MESSAGES.NAME_REQUIRED;
  }
  else if (
    !NAME_REGX.test(
      values[STRINGS.NAME_INPUT]
    )
  ) {
    errors[STRINGS.NAME_INPUT] =
      VALIDATION_MESSAGES.INVALID_NAME;
  }
  if (values[STRINGS.NAME_INPUT] && !values[STRINGS.NAME_INPUT].trim()) {
    errors[STRINGS.NAME_INPUT] = VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (!values[STRINGS.USER_ROLE]) {
    errors[STRINGS.USER_ROLE] =
      VALIDATION_MESSAGES.USER_ROLE_REQUIRED;
  }
  if (values[STRINGS.START_DATE_NAME] && values[STRINGS.END_DATE_NAME]) {
    if (new Date(values[STRINGS.END_DATE_NAME]).getTime() <= new Date(values[STRINGS.START_DATE_NAME]).getTime()) {
      errors[STRINGS.END_DATE_NAME] = VALIDATION_MESSAGES.END_DATE_MUST_BE_GREATER;
    }
  }
  return errors;
};

export default validator;
