import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import query from 'query-string';
import moment from 'moment';
import './style.scss';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const {
    LAYOUTS,
    PAGE_TITLES,
    LOCATION_ICON,
    MEDICAL_ICON,
    DOB_ICON,
    MESSAGES,
    CITY_ICON,
    ROUTES,
    PLUS_ICON,
    MINUS_ICON,
    ROLE_LABELS,
    NATIONALITY_ICON,
    EMERGENCY_ICON,
    VEHICLE_ICON,
    INFO_ICON,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
    PHONE_ICON,
    USER_ROLE_ITEMS,
    BLOOD_GROUPS,
    RELATION_TYPE,
    TABLE_SIZE_ITEMS,
    FIA_CATEGORIES,
    DEFAULT_USER_ICON,
    STATS_ICON
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);

export const Screen = ({
    userDetails,
    location,
    history,
    userStats,
    userTypeList,
    fetchUserInfo,
    startLoader,
    stopLoader,
    fetchUserTypes,
    championshipData,
    updateBlockStatus,
    fetchUserStatistics
}) => {
    const [sortByKey, setSortByKey] = useState('date');
    const [sortType, setSortType] = useState(1);
    const [roleItems, setRoleItems] = useState([]);
    const [expandRow, setExpandRow] = useState(false);
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    })
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    });
    const parameters = query.parse(location.search);
    const { vehicleInfo, emergencyInfo, medicalInfo } = userDetails || {};

    useEffect(() => {
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
    }, [])

    useEffect(() => {
        fetchUserInfo(parameters.id);
        fetchUserStatistics({
            championshipId: championshipData && championshipData._id,
            userId: parameters.id
        }, () => { }, () => { });
    }, [championshipData && championshipData._id]);

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.userDetail}</title>
            </Helmet>
            <DecisionPopup
                modalVisibility={popupVisible}
                dialogContent={popupData.popupContent}
                dialogTitle={popupData.popupTitle}
                confirmButtonTitle={STRINGS.CONFIRM}
                rejectButtonTitle={STRINGS.CANCEL}
                toggleDialogModal={() => setPopVisible(!popupVisible)}
                onConfirmation={popupData.onConfirmation}
                onRejection={() => setPopVisible(false)}
            />
            <SnackbarWrapper
                visible={openSnackBar}
                onClose={() => setOpenSnackbar(false)}
                variant={snackbarData.variant}
                message={snackbarData.message}
            />
            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />

                    {userDetails &&
                        <React.Fragment>
                            <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
                                <h5><label onClick={() => { history.replace(ROUTES.USERS) }}>{'Users Test List'}</label> <span>{userDetails.name ? userDetails.name : STRINGS.NA}</span></h5>
                                <button className="btn btn-md btn-outline-secondary edit_btn mb-3 mb-md-0" onClick={() => {
                                    setPopupData({
                                        popupContent: userDetails.blockStatus && userDetails.blockStatus === 1 ? STRINGS.BLOCK_USER_FROM_GIVING_TEST_CONTENT : STRINGS.APPROVE_USER_FROM_GIVING_TEST_CONTENT,
                                        popupTitle: userDetails.blockStatus && userDetails.blockStatus === 1 ? STRINGS.BLOCK_USER : STRINGS.UNBLOCK_USER,
                                        onConfirmation: () => {
                                            setPopVisible(false);
                                            startLoader();
                                            updateBlockStatus({
                                                championshipId: championshipData._id,
                                                userId: parameters.id,
                                                blockStatus: userDetails.blockStatus && userDetails.blockStatus === 2 ? 1 : 2
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });

                                                fetchUserInfo(parameters.id);
                                                fetchUserStatistics({
                                                    championshipId: championshipData && championshipData._id,
                                                    userId: parameters.id
                                                }, () => { }, () => { });
                                                stopLoader();
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                setOpenSnackbar(true);
                                                stopLoader();
                                            });
                                        }
                                    })
                                    setPopVisible(true);
                                }}>{userDetails.blockStatus && userDetails.blockStatus === 2 || userDetails.blockStatus === 3 ? 'Unblock' : 'Block'}</button>
                            </div>
                            <div className={'card border-0 shadow'}>
                                <div className={'card-body p-0'}>

                                    <div className={'basic-details-wrapper'}>

                                        <div className={'row justify-content-center align-items-center'}>
                                            <div className={'col-md-4 text-center'}>
                                                <div className={'user-profile-pic p-3'}>
                                                    <figure><img src={userDetails.profileUrl ? userDetails.profileUrl : DEFAULT_USER_ICON} alt="" className={'rounded-circle'} width={150} height={150} /></figure>
                                                    <h3>{userDetails.name ? userDetails.name : STRINGS.NA}</h3>
                                                    <span className="email_address">{userDetails.email ? userDetails.email : STRINGS.NA}</span>
                                                    <label>{roleItems.length && userDetails.role ? roleItems[userDetails.role - 1].label : STRINGS.NA}</label>
                                                </div>
                                            </div>
                                            <div className={'col-md-8'}>
                                                <div className={'user-address p-2 p-md-4'}>
                                                    <ul className="row">
                                                        <li className="col-sm-6">
                                                            <div className="info_user">
                                                                <label><i><img src={LOCATION_ICON} alt="" width={15} height={20} /></i>
                                                                    {STRINGS.ADDRESS}</label>
                                                                <span>{userDetails.address ? userDetails.address : STRINGS.NA}</span>
                                                            </div>
                                                        </li>

                                                        <li className={'col-sm-6'}>
                                                            <div className="info_user">
                                                                <label><i><img src={CITY_ICON} alt="" width={15} height={20} /></i>
                                                                    {STRINGS.CITY}</label>
                                                                <span>{userDetails.city ? userDetails.city : STRINGS.NA}</span>
                                                            </div>
                                                        </li>

                                                        <li className={'col-sm-6'}>
                                                            <div className="info_user">
                                                                <label><i><img src={NATIONALITY_ICON} alt="" width={20} height={20} /></i>
                                                                    {STRINGS.NATIONALITY}</label>
                                                                <span>{userDetails.nationality ? userDetails.nationality : STRINGS.NA}</span>
                                                            </div>
                                                        </li>
                                                        <li className={'col-sm-6'}>
                                                            <div className="info_user">
                                                                <label><i><img src={DOB_ICON} alt="" width={20} height={20} /></i>
                                                                    {STRINGS.DOB}</label>
                                                                <span>{userDetails.dob ? moment(new Date(userDetails.dob)).format('DD-MM-YYYY') : STRINGS.NA}</span>
                                                            </div>
                                                        </li>
                                                        <li className={'col-sm-6'}>
                                                            <div className="info_user">
                                                                <label><i><img src={PHONE_ICON} alt="" width={15} /></i>
                                                                    {STRINGS.PHONE_NO}</label>
                                                                <span>{userDetails && userDetails.phoneNumber ? `${userDetails.phoneNumber.code} ${userDetails.phoneNumber.phone}` : STRINGS.NA}</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <hr className="m-0" />

                                    {true && <div className={'group-details'}>
                                        <div className="px-4 mb-2">
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <h6><i className="mr-2"><img src={STATS_ICON} alt="" width={20} height={20} /></i> {STRINGS.STATISTICS}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <CustomTable
                                                        rows={userStats ? userStats : []}
                                                        rowsPerPage={tableSize.label}
                                                        showProfile={true}
                                                        columns={LAYOUTS.userStatsTable}
                                                        activePage={1}
                                                        sortByKey={sortByKey}
                                                        sortType={sortType}
                                                        emptyDataMessage={MESSAGES.noRecordsFound}
                                                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                                        TABLE_ARROW_UP={TABLE_ARROW_UP}
                                                        ROLE_LABELS={ROLE_LABELS}
                                                        INFO_ICON={INFO_ICON}
                                                        EDIT_ICON={expandRow ? MINUS_ICON : PLUS_ICON}
                                                        expandRow={expandRow}
                                                        onEditClick={() => {
                                                            setExpandRow(!expandRow);
                                                        }}
                                                        setSortKey={(key) => {
                                                            if (sortByKey === key) {
                                                                setSortType(-1 * sortType);
                                                            }
                                                            else {
                                                                setSortType(1)
                                                            }
                                                            setSortByKey(key)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>}

                                    <hr className="m-0" />
                                    <div className={'user-personal-detail px-4 pt-4'}>
                                        {medicalInfo && Object.keys(medicalInfo).length &&
                                            <div className={'group-details'}>
                                                <div className="row align-items-center my-2">
                                                    <div className="col-md-12">
                                                        <h6>
                                                            <i className="mr-2"><img src={MEDICAL_ICON} alt="" width={20} height={20} /></i>
                                                            {STRINGS.MEDICAL_DETAILS}
                                                        </h6>
                                                    </div>
                                                </div>

                                                <div className={'descriptive-details-wrapper'}>
                                                    <div className="row">
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.BLOOD_GROUP}</h6>
                                                                <label>{BLOOD_GROUPS[medicalInfo.bloodGroup - 1]}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.ANY_KNOWN_ALLERGIES}</h6>
                                                                <label>{medicalInfo.knownBloodAllergies ? medicalInfo.knownBloodAllergies : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.RELEVANT_PAST_HISTORY}</h6>
                                                                <label>{medicalInfo.relevantPastHistory ? medicalInfo.relevantPastHistory : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.ISSUES_UNDER_TREATMENT}</h6>
                                                                <label>{medicalInfo.issueUnderTreatment ? medicalInfo.issueUnderTreatment : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.EXISTING_TUE}</h6>
                                                                <label>{medicalInfo.existingTUE ? medicalInfo.existingTUE : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.LAST_TETANUS}</h6>
                                                                <label>{medicalInfo.lastTetanusInjection ? medicalInfo.lastTetanusInjection : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        }

                                        {emergencyInfo && Object.keys(emergencyInfo).length &&
                                            <div className={'group-details'}>
                                                <div className="row align-items-center my-2">
                                                    <div className="col-md-12">
                                                        <h6><i className="mr-2"><img src={EMERGENCY_ICON} alt="" width={20} height={20} /></i> {STRINGS.EMERGENCY_DETAILS}</h6>
                                                    </div>
                                                </div>

                                                <div className={'descriptive-details-wrapper'}>
                                                    <div className="row">
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.CONTACT_NAME}</h6>
                                                                <label>{emergencyInfo.contactName ? emergencyInfo.contactName : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.RELATIONSHIP}</h6>
                                                                <label>{emergencyInfo.relationship ? RELATION_TYPE[emergencyInfo.relationship - 1] : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.CONTACT_NUMBER}</h6>
                                                                <label>{emergencyInfo.contactNumber && emergencyInfo.contactNumber.code && emergencyInfo.contactNumber.phone ? `${emergencyInfo.contactNumber.code} ${emergencyInfo.contactNumber.phone}` : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        }
                                        {vehicleInfo && Object.keys(vehicleInfo).length &&
                                            <div className={'group-details'}>
                                                <div className="row align-items-center my-2">
                                                    <div className="col-md-12">
                                                        <h6><i className="mr-2"><img src={VEHICLE_ICON} alt="" width={25} height={20} /></i> {STRINGS.VEHICLE_DETAILS}
                                                        </h6>
                                                    </div>
                                                </div>

                                                <div className={'descriptive-details-wrapper'}>
                                                    <div className="row">
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.TEAM_NAME}</h6>
                                                                <label>{vehicleInfo.teamName ? vehicleInfo.teamName : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.ASN_CATEGORY}</h6>
                                                                <label>{vehicleInfo.category ? vehicleInfo.category : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.FIA_CATEGORIZATION}</h6>
                                                                <label>{vehicleInfo.FIACategorisation ? FIA_CATEGORIES[vehicleInfo.FIACategorisation - 1] : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.LICENSE_NUMBER}</h6>
                                                                <label>{vehicleInfo.licenseNumber ? vehicleInfo.licenseNumber : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.VEHICLE_REGISTRATION_NUMBER}</h6>
                                                                <label>{vehicleInfo.vehicleRegistrationNumber ? vehicleInfo.vehicleRegistrationNumber : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                        <div className={'col-md-4'}>
                                                            <div className={'form-group'}>
                                                                <h6>{STRINGS.INSURANCE_POLICY_NUMBER}</h6>
                                                                <label>{vehicleInfo.insurancePolicyNumber ? vehicleInfo.insurancePolicyNumber : STRINGS.NA}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>}
                </div>
            </div >
        </React.Fragment >
    );
}