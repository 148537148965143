import React, { useEffect, useState } from 'react';
import "./style.scss";
import { Helmet } from "react-helmet";
import { TextField } from '@material-ui/core';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const {
    LAYOUTS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    PAGE_TITLES,
    LABELS,
    MESSAGES,
    SEARCH_ICON,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
    USER_ROLE_ITEMS,
    ROLE_LABELS,
    TABLE_SIZE_ITEMS,
    SUB_ADMIN_PLATFORM,
    ACTIVE_PAGE_STYLE,
    ROUTES
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

export const Screen = ({
    fetchUnblockRequestList,
    championshipData,
    unBlockRequestList,
    fetchUserTypes,
    userTypeList,
    startLoader,
    stopLoader,
    history,
    updateBlockStatus,
}) => {
    const [activePage, setActivePage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [roleItems, setRoleItems] = useState([]);
    const [selectedRole, setSelectedRole] = useState(USER_ROLE_ITEMS[0]);
    const [lastActivePage, setLastActivePage] = useState(1);
    const [sortByKey, setSortByKey] = useState('date');
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortType, setSortType] = useState(1);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    })
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    })
    let userLength = unBlockRequestList && unBlockRequestList.data ? unBlockRequestList.data.length : 0;


    useEffect(() => {
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
    }, [])

    useEffect(() => {
        fetchUnblockRequestList({
            championshipId: championshipData && championshipData._id,
            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
            limit: tableSize.label,
            page: activePage,
        }, () => {
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
        setActivePage(1);
    }, [championshipData && championshipData._id && selectedRole, selectedRole.value, tableSize.label]);


    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    useEffect(() => {
        setActivePage(activePage);
        setSelectedRole(selectedRole)
    }, [userLength])


    return (
        <>
            <Helmet>
                <title>{PAGE_TITLES.requests}</title>
            </Helmet>
            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={STRINGS.CONFIRM}
                        rejectButtonTitle={STRINGS.CANCEL}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />
                    <div className="card-title">
                        <h5>{LABELS.approvalRequests}  <span>{
                            championshipData && championshipData.name}</span></h5>
                    </div>

                    <div className={'card border-0 shadow'}>

                        <div className={'card-body p-0'}>
                            <div className="table_filter">
                                <div className="form-row table-listing">

                                    <div className={'col-md-12'}>
                                        <div className="d-flex justify-content-between justify-content-md-end select_multi">
                                            <CustomDropdown
                                                labelText={LABELS.role}
                                                dataItems={roleItems}
                                                value={selectedRole.value}
                                                dropDataSet={(value) => {
                                                    if (value < SUB_ADMIN_PLATFORM) {
                                                        setSelectedRole(roleItems[value - 1]);
                                                    }
                                                    else {
                                                        setSelectedRole(roleItems[value - 2]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <CustomTable
                                    rows={unBlockRequestList && unBlockRequestList.data ? unBlockRequestList.data : []}
                                    rowsPerPage={tableSize.label}
                                    showProfile={true}
                                    roleItems={roleItems}
                                    editAction={false}
                                    columns={LAYOUTS.requestTable}
                                    activePage={1}
                                    sortByKey={sortByKey}
                                    sortType={sortType}
                                    emptyDataMessage={MESSAGES.noRecordsFound}
                                    TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                    TABLE_ARROW_UP={TABLE_ARROW_UP}
                                    ROLE_LABELS={ROLE_LABELS}
                                    onRowClick={(id) => {
                                        history.push(`${ROUTES.USER_DETAIL}?id=${id}`)
                                    }}
                                    onAccept={(id) => {
                                        setPopupData({
                                            popupContent: STRINGS.ACCEPT_USER_CONTENT,
                                            popupTitle: STRINGS.ACCEPT_USER,
                                            onConfirmation: () => {
                                                setPopVisible(false);
                                                startLoader();
                                                updateBlockStatus({
                                                    championshipId: championshipData._id,
                                                    userId: id,
                                                    blockStatus: 1
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    fetchUnblockRequestList({
                                                        championshipId: championshipData && championshipData._id ? championshipData._id : null,
                                                        filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                        limit: tableSize.label,
                                                        page: activePage,
                                                    }, () => {
                                                        stopLoader();
                                                        setOpenSnackbar(true);
                                                    },
                                                        (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        });
                                                });
                                            }
                                        })
                                        setPopVisible(true);
                                    }}
                                    onReject={(id) => {
                                        setPopupData({
                                            popupContent: STRINGS.REJECT_USER_CONTENT,
                                            popupTitle: STRINGS.REJECT_USER,
                                            onConfirmation: () => {
                                                setPopVisible(false);
                                                startLoader();
                                                updateBlockStatus({
                                                    championshipId: championshipData._id,
                                                    userId: id,
                                                    blockStatus: 2
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    fetchUnblockRequestList({
                                                        championshipId: championshipData && championshipData._id ? championshipData._id : null,
                                                        filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                        limit: tableSize.label,
                                                        page: activePage,
                                                    }, () => {
                                                        stopLoader();
                                                        setOpenSnackbar(true);
                                                    },
                                                        (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        });
                                                });
                                            }
                                        })
                                        setPopVisible(true);

                                    }}
                                    setSortKey={(key) => {
                                        if (sortByKey === key) {
                                            setSortType(-1 * sortType);
                                        }
                                        else {
                                            setSortType(1)
                                        }
                                        setSortByKey(key)
                                    }}
                                />
                                {unBlockRequestList && unBlockRequestList.data && !!unBlockRequestList.data.length && (unBlockRequestList.metaData.total > TABLE_SIZE_ITEMS[0].label) && <div className="text-center px-3">
                                    <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                        <CustomDropdown
                                            labelText={LABELS.noOfRows}
                                            dataItems={TABLE_SIZE_ITEMS}
                                            value={tableSize.value}
                                            dropDataSet={(value) => {
                                                setActivePage(1);
                                                setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                            }}
                                        />

                                        {
                                            (unBlockRequestList && unBlockRequestList.metaData && unBlockRequestList.metaData.total > tableSize.label) ? <CustomPagination
                                                displayingString={STRINGS.DISPLAYING}
                                                outOfString={STRINGS.OUT_OF}
                                                totalPages={unBlockRequestList.metaData.total}
                                                itemsCount={unBlockRequestList.data.length}
                                                pageCount={LIMITS.paginationPageCount}
                                                limit={tableSize.label}
                                                currentPage={activePage}
                                                nextArrow={NEXT_ARROW}
                                                prevArrow={PREV_ARROW}
                                                onPageChange={(selectedPage) => {
                                                    let page = selectedPage.selected + 1;
                                                    document.getElementById('screen-content').scroll(
                                                        {
                                                            top: 0,
                                                            left: 0,
                                                            behavior: 'smooth'
                                                        }
                                                    )
                                                    setActivePage(page);
                                                    setLastActivePage(page);
                                                    fetchUnblockRequestList({
                                                        championshipId: championshipData && championshipData._id ? championshipData._id : null,
                                                        filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                        limit: tableSize.label,
                                                        page: page
                                                    }, () => {
                                                        stopLoader();
                                                    },
                                                        (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        });
                                                }}
                                                activePageStyle={ACTIVE_PAGE_STYLE}
                                            />
                                                :
                                                null
                                        }
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}