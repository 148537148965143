import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    fetchUserTypes,
    fetchUsersList,
    fetchUsersRequests,
    updateApprovalStatus,
    fetchChampionshipUserStats,
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        championshipUserStat: state.UserReducer.championshipUserStat,
        usersRequestList: state.UserReducer.usersRequestList,
        championshipData: state.CommonReducer.championshipData,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader()),
        fetchChampionshipUserStats: () => dispatch(fetchChampionshipUserStats()),
        updateApprovalStatus: (data, success) => dispatch(updateApprovalStatus(data, success)),
        fetchUsersRequests: (data, success, failure) => dispatch(fetchUsersRequests(data, success, failure)),
        fetchUsersList: (data, success, failure) => dispatch(fetchUsersList(data, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure))
    }
}
export const RequestScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);



