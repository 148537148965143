import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import query from 'query-string';
import moment from 'moment-timezone';
import './style.scss';
import { EditQuestions } from './form';
import ReactPlayer from 'react-player';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { Audio } = require(`../../../../../../components/${PLATFORM}/atoms/custom-audio-player-with-hooks/Audio`);
const {
    QUESTION_TYPE,
    PAGE_TITLES,
    TICK_ICON,
    SUB_ADMIN_PLATFORM,
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);

export const Screen = ({
    questionDetails,
    location,
    history,
    startLoader,
    uploadFile,
    stopLoader,
    updateEvent,
    platformType,
    fetchQuestionDetails,
    championshipData,
    updateTestQuestion,
    fetchEventDetails,
    fetchUserTypes,
    userTypeList,
}) => {
    const [popupVisible, setPopVisible] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [questionData, setQuestionData] = useState({
        question: questionDetails && questionDetails.question ? questionDetails.question : '',
        userRole: questionDetails && questionDetails.userRole ? questionDetails.userRole : '',
        startDate: questionDetails && questionDetails.startDate ? questionDetails.startDate : new Date(),
        endDate: questionDetails && questionDetails.endDate ? questionDetails.endDate : new Date()
    });
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        onConfirmation: () => { }
    });
    const parameters = query.parse(location.search);
    useEffect(() => {
        fetchQuestionDetails(parameters.id, () => { }, () => { });
    }, [])

    useEffect(() => {
        return () => {
        };
    }, []);
    useEffect(() => {
        setQuestionData({
            question: questionDetails && questionDetails.question ? questionDetails.question : '',
            userRole: questionDetails && questionDetails.userRole ? questionDetails.userRole : '',
            answerType: questionDetails && questionDetails.options && questionDetails.answer ? questionDetails.options.length === 2 && questionDetails.answer.length === 1 ? 0 : questionDetails.answer.length === 1 ? 1 : 2 : '',
            questionType: questionDetails && questionDetails.questionType ? questionDetails.questionType : '',
            options: questionDetails && questionDetails.options ? questionDetails.options : '',
            questionTime: questionDetails && questionDetails.questionTime ? questionDetails.questionTime : '',
            questionUrl: questionDetails && questionDetails.questionUrl ? questionDetails.questionUrl : '',
            rightOptionList: questionDetails && questionDetails.answer ? questionDetails.answer : ''
        })
    }, [questionDetails && Object.keys(questionDetails).length, editForm])

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.userDetail}</title>
            </Helmet>
            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />
                    {editForm && (platformType === SUB_ADMIN_PLATFORM) && <div className='dropzone-div'>
                        <div className="overlay"></div>
                        <div className="dropzone-dialog dialog-lg update_champ test_edit_modal">
                            <div className="dropzone-content">
                                <div className="dropzone-body">
                                    <h3 className="mb-4 text-center">{'Update Question Details'}</h3>

                                    <EditQuestions
                                        uploadFile={uploadFile}
                                        startLoader={startLoader}
                                        stopLoader={stopLoader}
                                        fetchUserTypes={fetchUserTypes}
                                        userTypeList={userTypeList}
                                        onCancel={() => {
                                            setEditForm(false);
                                        }}
                                        onSubmit={(formData) => {
                                            let options = [];
                                            formData['members'].map((item, index) => {
                                                options.push({
                                                    id: index + 1,
                                                    option: item.answer
                                                });
                                                return null;
                                            });
                                            let correctAnswers = [];
                                            formData['right_option_list'].map((item) => {
                                                correctAnswers.push(item + 1);
                                                return null;
                                            });
                                            let minutes = formData.editTime[0].question_minute;
                                            let seconds = formData.editTime[0].question_seconds;
                                            let questionTime = (parseInt(minutes) * 60) + parseInt(seconds);
                                            startLoader();
                                            updateTestQuestion({
                                                questionId: parameters.id,
                                                userRole: formData['user_type'].value,
                                                questionType: formData['question_type'].value,
                                                question: formData['question_title'],
                                                questionUrl: formData.file,
                                                questionTime: questionTime,
                                                options: [...options],
                                                totalAnswers: formData['right_option_list'].length,
                                                answer: [...correctAnswers]
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                setEditForm(false);
                                                fetchQuestionDetails(parameters.id, () => {
                                                    stopLoader();
                                                    setOpenSnackbar(true);
                                                }, () => { });
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                setOpenSnackbar(true);
                                            })
                                        }}
                                        questionType={questionData.questionType}
                                        questionTitle={questionDetails.question}
                                        questionTime={questionData.questionTime}
                                        userType={questionData.userRole}
                                        answerType={questionData.answerType}
                                        questionUrl={questionData.questionUrl}
                                        options={questionData.options}
                                        rightOptionList={questionData.rightOptionList}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {questionDetails &&
                        <React.Fragment>
                            <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
                                <h5><label onClick={() => { history.goBack() }}>{'Test'}</label> <span>{'Description'}</span></h5>
                                {(platformType === SUB_ADMIN_PLATFORM) && <>
                                    <button className="btn btn-sm btn-outline-secondary edit_btn mb-3 mb-md-0" onClick={() => {
                                        setEditForm(true);
                                    }}><i className="edit-icon mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="27.034" height="26.999" viewBox="0 0 27.034 26.999">
                                        <path id="Forma_1" d="M1738.813,731.506l5.5,5.519L1730.389,751l-5.493-5.519Zm9.675-1.331-2.451-2.462a2.433,2.433,0,0,0-3.442,0l-2.351,2.358,5.5,5.519,2.741-2.75A1.9,1.9,0,0,0,1748.489,730.175Zm-26.474,23.06a.629.629,0,0,0,.76.747l6.133-1.491-5.5-5.52Z" transform="translate(-1722 -727)" fill="#f42634" fillRule="evenodd" />
                                    </svg></i> {STRINGS.EDIT}</button>
                                </>}
                            </div>
                            <div className={'card border-0 shadow'}>
                                <div className={'card-body event_sec'}>

                                    <div className="row align-items-center mb-2">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="mb-1">{'Question Title: '}</label>
                                                <span className="d-block">{questionDetails.question}</span>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="mb-1">{'Question Type: '}</label>
                                                <span className="d-block">{QUESTION_TYPE[questionDetails.questionType - 1].label}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-9">
                                            <div className="question_sec">
                                                <div className='row justify-content-center align-items-center title_qus mb-3'>
                                                    <label className="col-lg-11 col-sm-10 col-9">Answer Options</label>
                                                    <label className='col-lg-1 col-sm-2 col-3 text-center px-0 text-nowrap'>Right<br />Answer</label>
                                                </div>
                                                <div className="question_group">
                                                    {
                                                        questionDetails.options.map((item) => (
                                                            <div className={'row'}>
                                                                <div className={'col-lg-11 col-sm-10 col-9'}>
                                                                    <div className={'form-group'}>
                                                                        <label>{item.option}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1 col-sm-2 col-3 text-center px-0">
                                                                    {questionDetails.answer.includes(item.id) && <img src={TICK_ICON} alt={'tick'} width={'26px'} />}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="clearfix" />

                                    <div className="row">
                                        <div className="col-md-12">
                                            {(QUESTION_TYPE[questionDetails.questionType - 1].label !== 'Text') &&
                                                <>
                                                    <label className="mb-3">{'Uploaded File'}</label>
                                                    <div className="row">
                                                        <div className="col-md-8 col-12">
                                                            {(QUESTION_TYPE[questionDetails.questionType - 1].label === 'Image') && <img src={questionDetails.questionUrl} alt={'tick'} height={500} width={500} className="img-fluid rounded" />}
                                                        </div>
                                                    </div>
                                                    {(QUESTION_TYPE[questionDetails.questionType - 1].label === 'Video') && <div className="row">
                                                        <div className="col-md-6 col-12 video-image">
                                                            <ReactPlayer
                                                                playing={true}
                                                                controls
                                                                loop={false}
                                                                className="react-player"
                                                                url={questionDetails.questionUrl}
                                                            />
                                                        </div>
                                                    </div>
                                                    }

                                                    {(QUESTION_TYPE[questionDetails.questionType - 1].label === 'Audio') && <div className="row">
                                                        <div className="col-md-6 col-12 video-image"><Audio
                                                            sourceUrl={questionDetails.questionUrl}
                                                        /> </div>
                                                    </div>}

                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                </div>
            </div>
        </React.Fragment >
    );
}