export const FETCH_TEST_LIST = "FETCH_TEST_LIST";
export const SET_TEST_LIST = "SET_TEST_LIST";
export const UPDATE_TEST = "UPDATE_TEST";
export const ADD_TEST = "ADD_TEST";
export const DELETE_TEST = 'DELETE_TEST';
export const BROWSE_FILE = "BROWSE_FILE";
export const FETCH_QUESTION_LIST = "FETCH_QUESTION_LIST";
export const SET_QUESTION_LIST = "SET_QUESTION_LIST";
export const SAVE_INSTRUCTION = "SAVE_INSTRUCTION";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const CREATE_TEST = "CREATE_TEST";
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const FETCH_QUESTION_DETAIL = 'FETCH_QUESTION_DETAIL';
export const SAVE_QUESTION_DETAILS = 'SAVE_QUESTION_DETAILS';
export const FETCH_INSTRUCTION = "FETCH_INSTRUCTION";
export const GET_INSTRUCTION = "GET_INSTRUCTION";
export const UPDATE_TEST_QUESTION = 'UPDATE_TEST_QUESTION';

export const createQuestion = (data, success, failure) => {
    return {
        type: CREATE_QUESTION,
        data,
        success,
        failure
    }
}


export const getInstruction = (data) => {
    return {
        type: GET_INSTRUCTION,
        data
    }
}

export const createTest = (data, success, failure) => {
    return {
        type: CREATE_TEST,
        data,
        success,
        failure
    }
}
export const fetchTestList = (data, success, failure) => {
    return {
        type: FETCH_TEST_LIST,
        data, success, failure
    }
}

export const fetchInstruction = (data) => {
    return {
        type: FETCH_INSTRUCTION,
        data
    }
}

export const saveInstruction = (data, success, failure) => {
    return {
        type: SAVE_INSTRUCTION,
        data, success, failure
    }
}

export const fetchQuestionList = (data, success, failure) => {
    return {
        type: FETCH_QUESTION_LIST,
        data,
        success,
        failure
    }
}
export const browseFile = () => {
    return {
        type: BROWSE_FILE
    }
}

export const setQuestionList = (data) => {
    return {
        type: SET_QUESTION_LIST,
        data
    }
}
export const setTestList = (data) => {
    return {
        type: SET_TEST_LIST,
        data
    }
}

export const updateTest = (data, success, failure) => {
    return {
        type: UPDATE_TEST,
        data,
        success,
        failure
    }
}

export const addTest = () => {
    return {
        type: ADD_TEST
    }
}

export const deleteQuestion = (data, success, failure) => {
    return {
        type: DELETE_QUESTION,
        data, success, failure
    }
}

export const deleteTest = () => {
    return {
        type: DELETE_TEST
    }
};

export const uploadFile = (data, success, failure) => {
    return {
        type: UPLOAD_FILE,
        data, success, failure
    }
};

export const fetchQuestionDetails = (id, success, failure) => {
    return {
        type: FETCH_QUESTION_DETAIL,
        id, success, failure
    }
};

export const saveQuestionDetails = data => {
    return {
        type: SAVE_QUESTION_DETAILS,
        data
    }
};

export const updateTestQuestion = (data, success, failure) => {
    return {
        type: UPDATE_TEST_QUESTION,
        data,
        success,
        failure
    }
}