import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    uploadCSV,
    addNewUser,
    reSendInvite,
    fetchUsersList,
    fetchUserTypes,
    updateActivationStatus
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        usersList: state.UserReducer.usersList,
        championshipData: state.CommonReducer.championshipData,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader()),
        reSendInvite: (data, success, failure) => dispatch(reSendInvite(data, success, failure)),
        addNewUser: (data, success, failure) => dispatch(addNewUser(data, success, failure)),
        uploadCSV: (formData, success, error) => dispatch(uploadCSV(formData, success, error)),
        updateActivationStatus: (data, success) => dispatch(updateActivationStatus(data, success)),
        fetchUsersList: (data, success, failure) => dispatch(fetchUsersList(data, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure))
    }
}
export const UserScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);



