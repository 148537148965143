const {
  defaultConfig: { PLATFORM }
} = require(`../../../../../../config/default`);

const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/us/strings`);
const { VALIDATION_MESSAGES, EMAIL_REGX, NAME_REGX } = require(`../../../../../../shared/${PLATFORM}/constants`)

const validator = (values) => {
  const errors = {};
  if (!values[STRINGS.EVENT_INPUT]) {
    errors[STRINGS.EVENT_INPUT] =
      VALIDATION_MESSAGES.EVENT_NAME_REQUIRED;
  }
  if (values[STRINGS.EVENT_INPUT] && !values[STRINGS.EVENT_INPUT].trim()) {
    errors[STRINGS.EVENT_INPUT] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (!values[STRINGS.EVENT_DETAILS]) {
    errors[STRINGS.EVENT_DETAILS] =
      VALIDATION_MESSAGES.EVENT_DESCRIPTION_REQUIRED;
  }
  if (
    !NAME_REGX.test(
      values[STRINGS.EVENT_INPUT]
    )
  ) {
    errors[STRINGS.EVENT_INPUT] =
      "Event name cannot have special characters and numbers.";
  }
  if (values[STRINGS.EVENT_DETAILS] && !values[STRINGS.EVENT_DETAILS].trim()) {
    errors[STRINGS.EVENT_DETAILS] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (!values[STRINGS.COUNTRY_INPUT]) {
    errors[STRINGS.COUNTRY_INPUT] =
      VALIDATION_MESSAGES.COUNTRY_REQUIRED;
  }
  if (values[STRINGS.COUNTRY_INPUT] && !values[STRINGS.COUNTRY_INPUT].trim()) {
    errors[STRINGS.COUNTRY_INPUT] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (
    !NAME_REGX.test(
      values[STRINGS.COUNTRY_INPUT]
    )
  ) {
    errors[STRINGS.COUNTRY_INPUT] =
      "Country name cannot have special characters and numbers.";
  }
  if (!values[STRINGS.CITY_INPUT]) {
    errors[STRINGS.CITY_INPUT] =
      VALIDATION_MESSAGES.CITY_REQUIRED;
  }
  if (values[STRINGS.CITY_INPUT] && !values[STRINGS.CITY_INPUT].trim()) {
    errors[STRINGS.CITY_INPUT] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (
    !NAME_REGX.test(
      values[STRINGS.CITY_INPUT]
    )
  ) {
    errors[STRINGS.CITY_INPUT] =
      "City name cannot have special characters and numbers.";
  }
  if (!values[STRINGS.CIRCUIT_INPUT]) {
    errors[STRINGS.CIRCUIT_INPUT] =
      VALIDATION_MESSAGES.CIRCUIT_NAME_REQUIRED;
  }
  if (values[STRINGS.CIRCUIT_INPUT] && !values[STRINGS.CIRCUIT_INPUT].trim()) {
    errors[STRINGS.CIRCUIT_INPUT] =
      VALIDATION_MESSAGES.VALUE_CANNOT_BE_ONLY_SPACES;
  }
  if (
    !NAME_REGX.test(
      values[STRINGS.CIRCUIT_INPUT]
    )
  ) {
    errors[STRINGS.CIRCUIT_INPUT] =
      "Circuit name cannot have special characters and numbers.";
  }
  if (!values[STRINGS.START_DATE_NAME]) {
    errors[STRINGS.START_DATE_NAME] =
      VALIDATION_MESSAGES.START_DATE_REQUIRED;
  }
  if (!values[STRINGS.START_TIME_NAME]) {
    errors[STRINGS.START_TIME_NAME] =
      VALIDATION_MESSAGES.START_TIME_REQUIRED;
  }
  if (values[STRINGS.START_TIME_NAME] && values[STRINGS.START_DATE_NAME] && new Date(values[STRINGS.START_DATE_NAME]).toLocaleDateString() === new Date().toLocaleDateString() && new Date(values[STRINGS.START_TIME_NAME]) < new Date()) {
    errors[STRINGS.START_TIME_NAME] = 'You cannot set Event time in past.';

  }
  if (values[STRINGS.START_DATE_NAME] && values[STRINGS.END_DATE_NAME]) {
    if (new Date(values[STRINGS.END_DATE_NAME]).getTime() <= new Date(values[STRINGS.START_DATE_NAME]).getTime()) {
      errors[STRINGS.END_DATE_NAME] = VALIDATION_MESSAGES.END_DATE_MUST_BE_GREATER;
    }
  }
  if (!values[STRINGS.CHECKIN_HOURS_INPUT]) {
    errors[STRINGS.CHECKIN_HOURS_INPUT] =
      VALIDATION_MESSAGES.CHECKIN_HOURS_REQUIRED;
  }


  if (values.members && values.members.length) {
    const membersArrayErrors = []
    values.members.forEach((member, memberIndex) => {
      const memberErrors = {}
      if (!member.name) {
        memberErrors.name = VALIDATION_MESSAGES.NAME_REQUIRED
        membersArrayErrors[memberIndex] = memberErrors
      }
      if (!member.email) {
        memberErrors.email = VALIDATION_MESSAGES.EMAIL_REQUIRED
        membersArrayErrors[memberIndex] = memberErrors
      }
      if (!EMAIL_REGX.test(member.email)) {
        memberErrors.email = VALIDATION_MESSAGES.EMAIL_INVALID
        membersArrayErrors[memberIndex] = memberErrors
      }

    })
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors
    }

  }

  return errors;
};

export default validator;
