export const FETCH_CHAMPIONSHIP_LIST = 'FETCH_CHAMPIONSHIP_LIST';
export const SAVE_CHAMPIONSHIP_LIST = 'SAVE_CHAMPIONSHIP_LIST';
export const CREATE_CHAMPIONSHIP = 'CREATE_CHAMPIONSHIP';
export const UPDATE_CHAMPIONSHIP_STATUS = 'UPDATE_CHAMPIONSHIP_STATUS';
export const FETCH_CHAMPIONSHIP_DETAILS = 'FETCH_CHAMPIONSHIP_DETAILS';
export const SAVE_CHAMPIONSHIP_DETAILS = 'SAVE_CHAMPIONSHIP_DETAILS';
export const DELETE_CHAMPIONSHIP_ADMIN = 'DELETE_CHAMPIONSHIP_ADMIN';
export const ADD_CHAMPIONSHIP_ADMIN = 'ADD_CHAMPIONSHIP_ADMIN';
export const FETCH_USER_STATISTICS = 'FETCH_USER_STATISTICS';
export const SAVE_USER_STATISTICS = 'SAVE_USER_STATISTICS';
export const UPDATE_CHAMPIONSHIP_DETAILS = 'UPDATE_CHAMPIONSHIP_DETAILS';

export const fetchChampionships = (data, success, failure) => {
    return {
        type: FETCH_CHAMPIONSHIP_LIST,
        data,
        success,
        failure
    }
};

export const saveChampionshipList = (data) => {
    return {
        type: SAVE_CHAMPIONSHIP_LIST,
        data
    }
};

export const createChampionships = (data, success, failure) => {
    return {
        type: CREATE_CHAMPIONSHIP,
        data,
        success,
        failure
    }
};

export const updateChampionshipStatus = (data, success, failure) => {
    return {
        type: UPDATE_CHAMPIONSHIP_STATUS,
        data,
        success,
        failure
    }
};

export const fetchChampionshipDetails = (id, success, error) => {
    return {
        type: FETCH_CHAMPIONSHIP_DETAILS,
        id, success, error
    }
};

export const saveChampionshipDetails = (data) => {
    return {
        type: SAVE_CHAMPIONSHIP_DETAILS,
        data
    }
};

export const updateChampionshipDetails = (data, success, failure) => {
    return {
        type: UPDATE_CHAMPIONSHIP_DETAILS,
        data,
        success,
        failure
    }
}

export const deleteChampionshipAdmin = (data, success, error) => {
    return {
        type: DELETE_CHAMPIONSHIP_ADMIN,
        data, success, error
    }
};

export const addChampionshipAdmin = (data, success, error) => {
    return {
        type: ADD_CHAMPIONSHIP_ADMIN,
        data, success, error
    }
};

export const fetchUserStatistics = (id, success, failure) => {
    return {
        type: FETCH_USER_STATISTICS,
        id,
        success,
        failure
    }
};

export const saveUserStatistics = data => {
    return {
        type: SAVE_USER_STATISTICS,
        data
    }
};