import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { TextField } from '@material-ui/core';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const { CustomFileDrop } = require(`../../../../../../components/${PLATFORM}/cells/custom-filedrop`);
const {
    LAYOUTS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    PAGE_TITLES,
    LABELS,
    MESSAGES,
    SEARCH_ICON,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
    USER_ROLE_ITEMS,
    USER_STATUS_ITEMS,
    ROUTES,
    ROLE_LABELS,
    EMPTY_USER_LIST_ICON,
    TABLE_SIZE_ITEMS,
    IMPORT_ICON,
    INFO_ICON,
    ACTIVE_PAGE_STYLE,
    SUB_ADMIN_PLATFORM
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

export const Screen = ({
    getHoldingAreaUsers,
    holdingList,
    uploadCSV,
    startLoader,
    stopLoader,
    history,
    userTypeList,
    addNewUser,
    reSendInvite,
    holdingUserRoles,
    fetchHoldingRoles,
    fetchUserTypes,
    championshipData,
    updateActivationStatus,
    updateApprovalStatus,
}) => {
    const [activePage, setActivePage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [credentials, setCredentials] = useState({
        name: '',
        email: '',
        role: 0
    })
    const [selectedRole, setSelectedRole] = useState(USER_ROLE_ITEMS[0]);
    const [roleItems, setRoleItems] = useState([]);
    const [holdingRoles, setHoldingRoles] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(USER_STATUS_ITEMS[0]);
    const [lastActivePage, setLastActivePage] = useState(1);
    const [sortByKey, setSortByKey] = useState('date');
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortType, setSortType] = useState(-1);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [uploader, openUploader] = useState(false);
    const [formData, setFormData] = useState('');
    const [hasFormData, setHasFormData] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    })
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    });

    useEffect(() => {
        startLoader();
        fetchHoldingRoles((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });

    }, [])

    useEffect(() => {
        getHoldingAreaUsers({
            searchString: searchString,
            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
            sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
            sortType: sortType,
            limit: tableSize.label,
            page: activePage
        }, () => {
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
        setActivePage(1);
    }, [selectedRole.value, sortByKey, sortType, selectedRole.value, selectedStatus.value, tableSize.label]);

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    useEffect(() => {
        let items = [];
        if (holdingUserRoles) {
            holdingUserRoles.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setHoldingRoles([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [holdingUserRoles && holdingUserRoles.length])

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.holdingArea}</title>
            </Helmet>

            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />

                    <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <h5 className="my-2 text-center text-md-left">{STRINGS.HOLDING_AREA}</h5>
                    </div>

                    <div className={'card border-0 shadow'}>
                        <div className={'card-body p-0'}>
                            <React.Fragment>
                                <div className="table_filter">
                                    <div className="form-row table-listing">
                                        <div className="col-md-3">
                                            <div className='table-search'>
                                                <TextField
                                                    className='text-field'
                                                    label={LABELS.search}
                                                    type="text"
                                                    value={searchString}
                                                    placeholder={STRINGS.SEARCH}
                                                    onChange={e => {
                                                        getHoldingAreaUsers({
                                                            searchString: e.target.value.trim(),
                                                            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                            sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                            sortType: sortType,
                                                            limit: tableSize.label,
                                                            page: activePage
                                                        }, () => {
                                                            stopLoader();
                                                        },
                                                            (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                setOpenSnackbar(true);
                                                                stopLoader();
                                                            });
                                                        if (e.target.value === '') {
                                                            setActivePage(lastActivePage);
                                                            setSearchString(e.target.value);
                                                        }
                                                        else {
                                                            setActivePage(1);
                                                            setSearchString((e.target.value));
                                                        }
                                                    }}
                                                    margin="0"
                                                />
                                                <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                                            </div>
                                        </div>

                                        <div className={'col-md-9'}>
                                            <div className="d-flex justify-content-between justify-content-md-end select_multi">
                                                <CustomDropdown
                                                    labelText={LABELS.role}
                                                    dataItems={holdingRoles}
                                                    value={selectedRole.value}
                                                    dropDataSet={(value) => {
                                                        value = parseInt(value)
                                                        let roleValues = [1];
                                                        holdingUserRoles.map((item) => {
                                                            roleValues.push(Object.values(item)[0])
                                                        })
                                                        setSelectedRole(holdingRoles[roleValues.indexOf(value)]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <SnackbarWrapper
                                    visible={openSnackBar}
                                    onClose={() => setOpenSnackbar(false)}
                                    variant={snackbarData.variant}
                                    message={snackbarData.message}
                                />

                                {
                                    // true
                                    holdingList && holdingList.metaData && (holdingList.metaData.total !== 0 || !!searchString)
                                        ?
                                        <div className="table-responsive">
                                            <CustomTable
                                                rows={holdingList && holdingList.data ? holdingList.data : []}
                                                rowsPerPage={tableSize.label}
                                                holdingArea={true}
                                                showProfile={false}
                                                columns={LAYOUTS.holdingArea}
                                                activePage={1}
                                                roleItems={roleItems}
                                                sortByKey={sortByKey}
                                                sortType={sortType}
                                                emptyDataMessage={MESSAGES.noRecordsFound}
                                                TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                                TABLE_ARROW_UP={TABLE_ARROW_UP}
                                                ROLE_LABELS={ROLE_LABELS}
                                                INFO_ICON={INFO_ICON}
                                                onEditClick={(id) => {
                                                    history.push(`${ROUTES.USER_DETAIL}?id=${id}`)
                                                }}
                                                onInfoClick={(data) => {
                                                    setPopupData({
                                                        popupContent: `${STRINGS.IT_SEEMS} ${data.name} ${STRINGS.DIDNT_RESPONDED_TO_INVITATION}`,
                                                        popupTitle: STRINGS.RESEND_INVITE,
                                                        confirmButton: STRINGS.CONFIRM,
                                                        rejectButton: STRINGS.CANCEL,
                                                        onConfirmation: () => {
                                                            setPopVisible(false);
                                                            startLoader();
                                                            reSendInvite({
                                                                name: data.name,
                                                                email: data.email,
                                                                role: data.role
                                                            }, (response) => {
                                                                setPopupData({
                                                                    popupContent: `${STRINGS.YOUR_INVITATION_SENT_TO} ${data.name} ${STRINGS.FOR} ${roleItems[data.role - 1].label} ${STRINGS.PROFILE}`,
                                                                    popupTitle: STRINGS.REMINDER_SENT,
                                                                    confirmButton: '',
                                                                    rejectButton: STRINGS.OKAY,
                                                                    onConfirmation: () => { }
                                                                });
                                                                stopLoader();
                                                                setPopVisible(true);


                                                            }, (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                setOpenSnackbar(true);
                                                                stopLoader();
                                                            })
                                                        }
                                                    })
                                                    setPopVisible(true);
                                                }}
                                                onStatusChange={(value, id) => {
                                                    updateActivationStatus({
                                                        userId: id,
                                                        status: value ? 1 : 0
                                                    }, (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        getHoldingAreaUsers({
                                                            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                            sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                            sortType: sortType,
                                                            limit: tableSize.label,
                                                            page: activePage
                                                        }, () => {
                                                            stopLoader();
                                                            setOpenSnackbar(true);
                                                        },

                                                            (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                stopLoader();
                                                            });
                                                        setOpenSnackbar(true);
                                                    })
                                                }}
                                                onAccept={(id) => {
                                                    setPopupData({
                                                        popupContent: STRINGS.ACCEPT_USER_CONTENT,
                                                        popupTitle: STRINGS.ACCEPT_USER,
                                                        confirmButton: STRINGS.CONFIRM,
                                                        rejectButton: STRINGS.CANCEL,
                                                        onConfirmation: () => {
                                                            setPopVisible(false);
                                                            startLoader();
                                                            updateApprovalStatus({
                                                                userId: id,
                                                                approved: 2
                                                            }, (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                getHoldingAreaUsers({
                                                                    filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                                    sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                                    sortType: sortType,
                                                                    limit: tableSize.label,
                                                                    page: activePage
                                                                }, () => {
                                                                    stopLoader();
                                                                    setOpenSnackbar(true);
                                                                },
                                                                    (response) => {
                                                                        setSnackBarData({
                                                                            variant: response.status ? 'success' : 'error',
                                                                            message: response.msg
                                                                        });
                                                                        setOpenSnackbar(true);
                                                                        stopLoader();
                                                                    });
                                                            });
                                                        }
                                                    })
                                                    setPopVisible(true);
                                                }}
                                                onReject={(id) => {
                                                    setPopupData({
                                                        popupContent: STRINGS.REJECT_USER_CONTENT,
                                                        popupTitle: STRINGS.REJECT_USER,
                                                        confirmButton: STRINGS.CONFIRM,
                                                        rejectButton: STRINGS.CANCEL,
                                                        onConfirmation: () => {
                                                            setPopVisible(false);
                                                            startLoader();
                                                            updateApprovalStatus({
                                                                userId: id,
                                                                approved: 3
                                                            }, (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                getHoldingAreaUsers({
                                                                    filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                                    sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                                    sortType: sortType,
                                                                    limit: tableSize.label,
                                                                    page: activePage
                                                                }, () => {
                                                                    stopLoader();
                                                                    setOpenSnackbar(true);
                                                                },
                                                                    (response) => {
                                                                        setSnackBarData({
                                                                            variant: response.status ? 'success' : 'error',
                                                                            message: response.msg
                                                                        });
                                                                        setOpenSnackbar(true);
                                                                        stopLoader();
                                                                    });

                                                                setOpenSnackbar(true);
                                                            });
                                                        }
                                                    })
                                                    setPopVisible(true);

                                                }}
                                                setSortKey={(key) => {
                                                    if (sortByKey === key) {
                                                        setSortType(-1 * sortType);
                                                    }
                                                    else {
                                                        setSortType(1)
                                                    }
                                                    setSortByKey(key)
                                                }}
                                            />
                                            {holdingList && holdingList.data && !!holdingList.data.length && (holdingList.metaData.total > TABLE_SIZE_ITEMS[0].label) && <div className="text-center px-3">
                                                <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                                    <CustomDropdown
                                                        labelText={LABELS.noOfRows}
                                                        dataItems={TABLE_SIZE_ITEMS}
                                                        value={tableSize.value}
                                                        dropDataSet={(value) => {
                                                            setActivePage(1);
                                                            setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                        }}
                                                    />
                                                    {
                                                        (holdingList && holdingList.metaData && holdingList.metaData.total > tableSize.label) ? <CustomPagination
                                                            displayingString={STRINGS.DISPLAYING}
                                                            outOfString={STRINGS.OUT_OF}
                                                            totalPages={holdingList.metaData.total}
                                                            itemsCount={holdingList.data.length}
                                                            pageCount={LIMITS.paginationPageCount}
                                                            limit={tableSize.label}
                                                            currentPage={activePage}
                                                            nextArrow={NEXT_ARROW}
                                                            prevArrow={PREV_ARROW}
                                                            onPageChange={(selectedPage) => {
                                                                let page = selectedPage.selected + 1;
                                                                document.getElementById('screen-content').scroll(
                                                                    {
                                                                        top: 0,
                                                                        left: 0,
                                                                        behavior: 'smooth'
                                                                    }
                                                                )
                                                                setActivePage(page);
                                                                setLastActivePage(page);
                                                                getHoldingAreaUsers({
                                                                    filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                                    sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                                    sortType: sortType,
                                                                    limit: tableSize.label,
                                                                    page: page
                                                                }, () => {
                                                                    stopLoader();
                                                                },
                                                                    (response) => {
                                                                        setSnackBarData({
                                                                            variant: response.status ? 'success' : 'error',
                                                                            message: response.msg
                                                                        });
                                                                        setOpenSnackbar(true);
                                                                        stopLoader();
                                                                    });
                                                            }}
                                                            activePageStyle={ACTIVE_PAGE_STYLE}
                                                        />
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>}
                                        </div> :
                                        holdingList ?
                                            <div className="row text-center justify-content-center align-items-center py-3 py-md-5 my-2 my-md-5">
                                                <div className="col-md-8 col-offset-2 px-5">
                                                    <figure>
                                                        <img src={EMPTY_USER_LIST_ICON} alt={'dashboard-logo'} className="img-fluid" />
                                                    </figure>
                                                    <h5 className="mb-3">{STRINGS.EMPTY_USER_HEADING}</h5>
                                                    <p>{STRINGS.EMPTY_USER_DESCRIPTION}</p>

                                                    <div className="group_btn mt-3 mt-md-4">
                                                        <label for="file" className={'btn btn-lg btn-outline-secondary'} onClick={() => openUploader(true)}>
                                                            <i className={'mr-2'}>
                                                                <img src={IMPORT_ICON} alt={'import-icon'} />
                                                            </i>
                                                            {STRINGS.IMPORT_CSV}
                                                        </label>
                                                        {uploader && <React.Fragment>
                                                            <div className='dropzone-div'>
                                                                <div className="overlay"></div>
                                                                <div className="dropzone-dialog">
                                                                    <div className="dropzone-content">
                                                                        <div className="dropzone-body">
                                                                            <CustomFileDrop
                                                                                acceptFiles={'.csv'}
                                                                                handleSubmit={({ meta, file }, status) => {
                                                                                    setFormData({ meta, file });
                                                                                    if (status === LABELS.removed) {
                                                                                        setHasFormData(false);
                                                                                    }
                                                                                    if (status === LABELS.rejectedFileType) {
                                                                                        setSnackBarData({
                                                                                            variant: 'error',
                                                                                            message: STRINGS.FILE_TYPE_REJECTED
                                                                                        });
                                                                                        setOpenSnackbar(true);
                                                                                    }
                                                                                    if (status === LABELS.preparing || status === LABELS.done) {
                                                                                        if (status === LABELS.done) {
                                                                                            setHasFormData(true)
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <hr className="mt-0" />
                                                                            <div className="d-flex flex-column flex-md-row justify-content-sm-end">
                                                                                <button className={'reject-button'} onClick={() => openUploader(false)}>{STRINGS.CANCEL}</button>
                                                                                <button className={'accept-button'}
                                                                                    onClick={(event) => {
                                                                                        if (hasFormData) {
                                                                                            uploadCSV(formData, (response) => {
                                                                                                setSortByKey('date');
                                                                                                setActivePage(1);
                                                                                                setSortType(1);
                                                                                                getHoldingAreaUsers({
                                                                                                    searchString: searchString,
                                                                                                    filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                                                                    sortKey: 'createdAt',
                                                                                                    sortType: -1,
                                                                                                    limit: tableSize.label,
                                                                                                    page: 1
                                                                                                }, () => {
                                                                                                    stopLoader();
                                                                                                },
                                                                                                    (response) => {
                                                                                                        setSnackBarData({
                                                                                                            variant: response.status ? 'success' : 'error',
                                                                                                            message: response.msg
                                                                                                        });
                                                                                                        setOpenSnackbar(true);
                                                                                                        stopLoader();
                                                                                                    });

                                                                                                setSnackBarData({
                                                                                                    variant: response.status ? 'success' : 'error',
                                                                                                    message: response.msg
                                                                                                });
                                                                                                openUploader(false);
                                                                                                setOpenSnackbar(true);
                                                                                                stopLoader();
                                                                                            },
                                                                                                (response) => {
                                                                                                    setSnackBarData({
                                                                                                        variant: response.status ? 'success' : 'error',
                                                                                                        message: response.msg || ''
                                                                                                    });
                                                                                                    setOpenSnackbar(true);
                                                                                                    stopLoader();
                                                                                                })

                                                                                        }
                                                                                        else {
                                                                                            setSnackBarData({
                                                                                                variant: 'error',
                                                                                                message: STRINGS.FILE_NOT_UPLOADED_YET
                                                                                            });
                                                                                            setOpenSnackbar(true);
                                                                                        }
                                                                                    }}>
                                                                                    {STRINGS.UPLOAD}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>}

                                                        <span className="or_txt">{STRINGS.OR}</span>
                                                        <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => { setAddUserVisible(true) }}>{STRINGS.ADD_USER}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className={'empty-records'}>{MESSAGES.noRecordsFound}</div>
                                }</React.Fragment>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}