import React, { useState, useEffect } from "react";
import { reduxForm, Field, reset, FieldArray, change as changeField } from "redux-form";
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { TextArea } = require(`../../../../../../components/${PLATFORM}/atoms/text-area`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { DatePickerInput } = require(`../../../../../../components/${PLATFORM}/atoms/date-picker`);
const { TimePickerInput } = require(`../../../../../../components/${PLATFORM}/atoms/time-picker`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { CHECK_IN_HOURS_ITEMS } = require(`../../../../../../shared/${PLATFORM}/constants`)
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)



const EditChampionshipForm = ({
    championshipName,
    championshipDescription,
    handleSubmit = () => { },
    onNameChange = () => { },
    onDescriptionChange = () => { },
    onStartDateChange = () => { },
    onEndDateChange = () => { },
    onSubmit = () => { },
    onCancel = () => { },
    changeField,
    eventName,
    eventDescription,
    eventDateTime,
    eventCountry,
    eventCity,
    eventCircuit,
    eventTime,
    eventDate,
    checkinTime
}) => {
    let [startDate, setStartDate] = useState(new Date(eventDateTime))
    let [startTime, setStartTime] = useState('')
    let [minEndDate, setMinEndDate] = useState('');
    let [checkinHours, setCheckinHours] = useState('')
    let [endDate, setEndDate] = useState('');
    let offset = new Date().getTimezoneOffset()
    useEffect(() => {
        setStartDate(eventDateTime)
        changeField("edit", "event-name", eventName);
        changeField("edit", "event-details", eventDescription);
        changeField("edit", "country", eventCountry);
        changeField("edit", "city", eventCity);
        changeField("edit", "circuit", eventCircuit);
        changeField("edit", "start-date", new Date(eventDateTime));
        changeField("edit", "start-time", new Date(eventDateTime));
        changeField("edit", "checkin", CHECK_IN_HOURS_ITEMS[checkinTime - 5]);
    }, [eventName, eventDescription]);

    const onChangeDate = (value) => {
        var copiedDate = new Date(value);
        changeField("edit", "start-date", value);
        changeField("edit", "start-time", null);
        setStartDate(value)
        setMinEndDate(copiedDate.setDate(value.getDate() + 1))
    }

    const onChangeTime = (value) => {
        setStartTime(value);
        changeField("edit", "start-time", new Date(value));
    }
    const onHoursChange = (value) => {
        setCheckinHours(value);
        changeField("event", 'checkin', value)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.EVENT_INPUT}
                    component={Input}
                    label={STRINGS.EVENT_NAME}
                    type={'text'}
                    placeholder={STRINGS.EVENT_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.EVENT_DETAILS}
                    component={TextArea}
                    label={STRINGS.EVENT_DESCRIPTION_LABEL}
                    type={'text'}
                    placeholder={STRINGS.EVENT_DETAILS_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.START_DATE_NAME}
                    component={DatePickerInput}
                    minDate={new Date()}
                    label={STRINGS.DATE}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    dateValue={startDate}
                    onChangeDate={onChangeDate}
                />
                <Field
                    name={STRINGS.START_TIME_NAME}
                    component={TimePickerInput}
                    minDate={minEndDate}
                    label={STRINGS.START_TIME}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    disabled={startDate ? false : true}
                    timeValue={startTime}
                    onChangeTime={onChangeTime}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.COUNTRY_INPUT}
                    component={Input}
                    label={STRINGS.COUNTRY}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    placeholder={STRINGS.COUNTRY_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.CITY_INPUT}
                    component={Input}
                    label={STRINGS.CITY}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    placeholder={STRINGS.CITY_NAME_PLACEHOLDER}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.CIRCUIT_INPUT}
                    component={Input}
                    label={STRINGS.CIRCUIT_NAME}
                    widthStyle={'col-md-6'}
                    type={'text'}
                    placeholder={STRINGS.CIRCUIT_NAME_PLACEHOLDER}
                />
                <Field
                    name={STRINGS.CHECKIN_HOURS_INPUT}
                    label={STRINGS.CHECKIN_HOURS}
                    component={Select}
                    isSearchable={false}
                    value={checkinHours}
                    options={CHECK_IN_HOURS_ITEMS}
                    onValueChange={onHoursChange}
                    placeholder={STRINGS.CHECKIN_PLACEHOLDER}
                    widthStyle={'col-md-6'}
                />
            </div>

            <hr className="mt-0" />
            <div className="d-flex flex-row justify-content-end">
                <button type={'button'} className={'reject-button'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit buttonLabel={'Update'} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    let initialValues = {
        'championship-name': props.championshipName,
        'details': props.championshipDescription,
    };

    return {
        eventTime: state.form && state.form.edit && state.form.edit.values &&
            state.form.edit.values['start-time'] &&
            state.form.edit.values['start-time'],
        eventDate: state.form && state.form.edit && state.form.edit.values &&
            state.form.edit.values['start-date'] &&
            state.form.edit.values['start-date'],
        initialValues: initialValues,
    };
}
const afterSubmit = (result, dispatch) => {
}

const reduxFormFunction = reduxForm({
    form: "edit",
    fields: ['event-name', 'event-details', 'country', 'city', 'circuit', 'start-date', 'start-time', 'checkin'],
    validate: validator,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(EditChampionshipForm);

export const EventReduxForm = connect(mapStateToProps, { changeField })(reduxFormFunction);