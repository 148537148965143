import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordReduxForm } from "../../../../../../components/sef/molecules/change-password";
import { submit, reset } from "redux-form";
import "./styles.scss";
import noPic from "../../../../../../assets/icons/no-pic.jpg";
import { saveAdminInfo, uploadProfilePic } from "../../../../../../redux/sef/actions";
const {
  defaultConfig: { PLATFORM, LOCATION }
} = require(`../../../../../../config/default`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { PAGE_TITLES, MESSAGES, ROUTES } = require(`../../../../../../shared/${PLATFORM}/constants`);

export const Screen = ({ changePassword = () => {}, adminDetails = {} }) => {
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackBarData] = useState({
    variant: "",
    message: ""
  });
  const [userData, set_userData] = useState(adminDetails);
  const dispatch = useDispatch();
  const fileUploadRef = useRef();
  const profilePicRef = useRef();

  const handleFileUpload = () => {
    let fileReader = new FileReader();
    let chosenFile = fileUploadRef.current.files[0];
    fileReader.onload = (event) => {
      dispatch(
        uploadProfilePic(
          { profileUrl: event.target.result },
          (msg) => {
            window.scrollTo(0, 0);
            setSnackBarData({
              variant: "success",
              message: msg
            });
            setOpenSnackbar(true);
            profilePicRef.current.src = event.target.result;
            dispatch(saveAdminInfo({ ...userData, profileUrl: event.target.result }));
          },
          (msg) => {
            window.scrollTo(0, 0);
            setSnackBarData({
              variant: "error",
              message: msg
            });
          }
        )
      );
    };
    fileReader.readAsDataURL(chosenFile);
  };

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.profile}</title>
      </Helmet>
      <div className="">
        <div className="content-wrapper">
          <SnackbarWrapper
            visible={openSnackBar}
            onClose={() => setOpenSnackbar(false)}
            variant={snackbarData.variant}
            message={snackbarData.message}
          />

          <h3 className="title-text">Profile</h3>
          <div className="card border-0 shadow">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="profile_pic text-center position-relative">
                    <span className="user_pic">
                      <img
                        alt="profile-pic"
                        src={userData?.profileUrl ? userData.profileUrl : noPic}
                        ref={profilePicRef}
                      />
                    </span>
                    <div className="upload_pic">
                      <label htmlFor="upload">
                        <i>
                          <img src={require("../../../../../../assets/icons/edit_icon.svg")} width="" alt="SEF" />
                        </i>
                      </label>
                      <input
                        id="upload"
                        className="upload-photo"
                        type="file"
                        accept="image/png, image/jpeg"
                        ref={fileUploadRef}
                        onChange={handleFileUpload}
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="profile_detail pl-md-5">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            <i className="mr-2">
                              <img src={require("../../../../../../assets/icons/email_icon.svg")} width="" alt="SEF" />
                            </i>
                            Email
                          </label>
                          <div className="label_field">{userData?.email}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            <i className="mr-2">
                              <img src={require("../../../../../../assets/icons/user_icon.svg")} width="" alt="SEF" />
                            </i>
                            Name
                          </label>
                          <div className="label_field">{userData?.name}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            <i className="mr-2">
                              <img src={require("../../../../../../assets/icons/role_icon.svg")} width="" alt="SEF" />
                            </i>
                            Role
                          </label>
                          <div className="label_field">
                            {userData?.role === 1 ? STRINGS.SEF_ADMIN : ""}
                            {/* {userTypes.find(
                            (role, idx) => role.value === userData?.role
                          )?.label} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <h3 className="title-text mt-4">Change Password</h3>
            <div className="card border-0 shadow mt-2">
              <div className="card-body">
                <ChangePasswordReduxForm
                  onSubmit={(values) => {
                    let { oldPassword, newPassword } = values;
                    changePassword(
                      { password: oldPassword, newPassword },
                      (msg) => {
                        window.scrollTo(0, 0);
                        setSnackBarData({
                          variant: "success",
                          message: msg
                        });
                        setOpenSnackbar(true);
                        // const simpleCrypto = new SimpleCrypto(process.env.REACT_APP_REMEMBER_ME_SECRET_KEY);
                        // dispatch(setEncryptedPassword(simpleCrypto.encrypt(newPassword)));
                        dispatch(reset("change-password"));
                      },
                      (msg) => {
                        window.scrollTo(0, 0);
                        setSnackBarData({
                          variant: "error",
                          message: msg
                        });
                        setOpenSnackbar(true);
                      }
                    );
                  }}
                />
              </div>
            </div>
            <div className="text-right my-3">
              <button
                type="submit"
                className={`btn btn-lg btn-primary`}
                onClick={() => dispatch(submit("change-password"))}
              >
                Save{" "}
              </button>
            </div>
            {/* </>
            )} */}
          </>
        </div>
      </div>
    </>
  );
};
