const SERVER_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000'; //Live URL
const API_VERSION = process.env.REACT_API_VERSION || '/v1'

module.exports = {
  URL: {
    SERVER_URL: SERVER_URL,
    LOGOUT: SERVER_URL + API_VERSION + '/user/logout',
    LOGIN: SERVER_URL + API_VERSION + '/admin/login',
    USER_STAT: SERVER_URL + API_VERSION + '/admin/user-stats',
    USER_INFO: SERVER_URL + API_VERSION + '/admin/user-infos',
    ADD_NEW_USER: SERVER_URL + API_VERSION + '/admin/invite-user',
    REINVITE_USER: SERVER_URL + API_VERSION + '/admin/reinvite-user',
    UPLOAD_CSV: SERVER_URL + API_VERSION + '/admin/invite-users-by-csv',
    USER_STATISTICS: SERVER_URL + API_VERSION + '/admin/user-statistics',
    USER_REQUESTS: SERVER_URL + API_VERSION + '/admin/get-users-request',
    FORGOT_PASSWORD: SERVER_URL + API_VERSION + '/admin/forgot-password',
    APPROVE_STATUS: SERVER_URL + API_VERSION + '/admin/user-approved-status',
    UPDATE_ACTIVE_STATUS: SERVER_URL + API_VERSION + '/admin/update-users-status',
    CHAMPIONSHIP: SERVER_URL + API_VERSION + '/championship',
    USERS_LIST: SERVER_URL + API_VERSION + '/championship/users',
    CHAMPIONSHIP_ADMIN: SERVER_URL + API_VERSION + '/championship/admin',
    FETCH_CHAMPIONSHIP_LIST: SERVER_URL + API_VERSION + '/championship/list',
    UPDATE_CHAMPIONSHIP_STATUS: SERVER_URL + API_VERSION + '/championship/status',
    CHAMPIONSHIP_USERS_STATS: SERVER_URL + API_VERSION + '/championship/statistics',
    EVENT: SERVER_URL + API_VERSION + '/event',
    EVENT_LIST: SERVER_URL + API_VERSION + '/event/list',
    EVENT_ATTENDEES: SERVER_URL + API_VERSION + '/event/attendees',
    EMERGENCY_ACCESS: SERVER_URL + API_VERSION + '/championship/emergency-access',
    INSTRUCTION_SAVE: SERVER_URL + API_VERSION + '/test/instruction/save',
    CREATE_TEST_LIST: SERVER_URL + API_VERSION + '/test/question/limit',
    TEST_STATUS_UPDATE: SERVER_URL + API_VERSION + '/test/status',
    CREATE_TEST: SERVER_URL + API_VERSION + '/test/question/limit',
    TEST_QUESTION_LIST: SERVER_URL + API_VERSION + '/question/list',
    ADD_QUESTION: SERVER_URL + API_VERSION + '/question/add',
    QUESTION: SERVER_URL + API_VERSION + '/question',
    UPDATE_QUESTION: SERVER_URL + API_VERSION + '/question/update',
    UPLOAD_FILE: SERVER_URL + API_VERSION + '/file/upload',
    FETCH_INSTRUCTION: SERVER_URL + API_VERSION + '/test/instruction',
    AMBASSADOR_ACCESS: SERVER_URL + API_VERSION + '/championship/ambassador-access',
    AMBASSADORS_LIST: SERVER_URL + API_VERSION + '/championship/ambassadors',
    GET_USER_TYPES: SERVER_URL + API_VERSION + '/admin/user-roles',
    ADD_USER_TYPE: SERVER_URL + API_VERSION + '/admin/role',
    FETCH_UNBLOCK_REQUEST_LIST: SERVER_URL + API_VERSION + '/championship/unblock-request-list',
    TEST_USERS: SERVER_URL + API_VERSION + '/championship/test-users',
    UPDATE_BLOCK_STATUS: SERVER_URL + API_VERSION + '/championship/user/block-status',
    TEST_REMINDER: SERVER_URL + API_VERSION + '/championship/test-reminder',
    HOLDING_AREA_LIST: SERVER_URL + API_VERSION + '/admin/get-holding-users',
    HOLDING_AREA_ROLES: SERVER_URL + API_VERSION + '/user/initial-data',
    CHANGE_PASSWORD: SERVER_URL + API_VERSION + '/user/change-password',
    PROFILE_PIC_UPLOAD: SERVER_URL + '/v1/user/profile-pic',
  }
};