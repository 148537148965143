import React from "react";
import { Helmet } from 'react-helmet';
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { PAGE_TITLES } = require(`../../../../../../shared/${PLATFORM}/constants`);

export const Screen = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.chats}</title>
            </Helmet>
            <p>Chat screen</p>
        </React.Fragment>
    );
}