
const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);


export const HAMBURGER_ICON = require(`../../../assets/icons/hamburger_icon.svg`);
export const NEXT_ICON = require(`../../../assets/next.png`);
export const RIGHT_ICON = require(`../../../assets/icons/arrow_nxt.svg`);
export const LOGOUT_SELECTED = require(`../../../assets/logout_selected.png`);
export const LOGOUT_ICON = require(`../../../assets/logout_icon.png`);
export const DASHBOARD_SELECTED = require(`../../../assets/dashboard_active.png`);
export const DASHBOARD_ICON = require(`../../../assets/dashboard.png`);
export const FEEDS_SELECTED = require(`../../../assets/feeds_selected.png`);
export const FEEDS_ICON = require(`../../../assets/feeds.png`);
export const CHAMPIONSHIPS_SELECTED = require(`../../../assets/championship_selected.png`);
export const CHAMPIONSHIPS_ICON = require(`../../../assets/championship.png`);
export const SEF_TESTS_SELECTED = require(`../../../assets/test_selected.png`);
export const SEF_TESTS_ICON = require(`../../../assets/test.png`);
export const CHAT_SELECTED = require(`../../../assets/chat_selected.png`);
export const CHAT_ICON = require(`../../../assets/chat.png`);
export const EMERGENCY_ACCESS = require(`../../../assets/emergency_selected.png`);
export const EMERGENCY_ACCESS_UNSELECTED = require(`../../../assets/emergency_icon.png`);
export const AMBASSADORS_ICON = require(`../../../assets/ambassador_icon.png`);
export const AMBASSADORS_SELECTED = require(`../../../assets/ambassador_selected.png`);
export const EVENT_SELECTED = require(`../../../assets/event_selected.png`);
export const EVENT_ICON = require(`../../../assets/event.png`);
export const REQUESTS_SELECTED = require(`../../../assets/requests_selected.png`);
export const REQUESTS_ICON = require(`../../../assets/requests_icon.png`);
export const USERS_SELECTED = require(`../../../assets/users_active.png`);
export const USERS_ICON = require(`../../../assets/users_icon.png`);
export const DASHBOARD_PAGE_LOGO = require(`../../../assets/dashboard_logo.png`);
export const EMPTY_USER_LIST_ICON = require(`../../../assets/Empty_users.png`);
export const EMPTY_CHAMPIONSHIP_ICON = require(`../../../assets/trophy_web.png`);
export const EMPTY_EVENT_ICON = require(`../../../assets/empty-events.png`);
export const EMPTY_TEST_ICON = require(`../../../assets/icons/empty_test.svg`);
export const EMPTY_QUESTION_ICON = require(`../../../assets/empty_questions.png`);
export const EMPTY_EMERGENCY_ICON = require(`../../../assets/empty_emergency.png`);
export const SEARCH_ICON = require(`../../../assets/icons/search_icon.svg`)
export const TABLE_ARROW_UP = require(`../../../assets/icons/arrow-up.svg`)
export const TABLE_ARROW_DOWN = require(`../../../assets/icons/arrow-down.svg`);
export const PREV_ARROW = require(`../../../assets/icons/pre_arrow.svg`);
export const NEXT_ARROW = require(`../../../assets/icons/nxt_arrow.svg`);
export const IMPORT_ICON = require(`../../../assets/export_icon.svg`);
export const REGISTERED_USER = require('../../../assets/role-icons/registered_user.svg');
export const TOTAL_DRIVERS = require('../../../assets/role-icons/total_drivers.svg');
export const TOTAL_MARSHALS = require('../../../assets/role-icons/total_marshals.svg');
export const TOTAL_MEDIA = require('../../../assets/role-icons/total_media.svg');
export const TOTAL_PITCREW = require('../../../assets/role-icons/total_pitcrews.svg');
export const TOTAL_SECURITY = require('../../../assets/role-icons/total_security.svg');
export const INFO_ICON = require(`../../../assets/info_icon.png`);
export const DEFAULT_USER_ICON = require(`../../../assets/icons/profile_pic.png`);
export const EDIT_ICON = require(`../../../assets/icons/edit.png`);
export const DELETE_ICON = require(`../../../assets/icons/delete.png`);
export const LOCATION_ICON = require(`../../../assets/icons/address.png`);
export const CITY_ICON = require(`../../../assets/icons/city.png`);
export const DOB_ICON = require(`../../../assets/icons/dob.png`);
export const MEDICAL_ICON = require(`../../../assets/icons/medical.png`);
export const NATIONALITY_ICON = require(`../../../assets/icons/nationality.png`);
export const EMERGENCY_ICON = require(`../../../assets/icons/emergency.png`);
export const VEHICLE_ICON = require(`../../../assets/icons/vehicle.png`);
export const PHONE_ICON = require(`../../../assets/icons/phone.png`);
export const CHAMP_ICON = require(`../../../assets/icons/champ_icon.png`);
export const STATS_ICON = require(`../../../assets/icons/statistics-icon.png`);
export const PLUS_ICON = require(`../../../assets/icons/plus.png`);
export const MINUS_ICON = require(`../../../assets/icons/minus.png`);
export const EVENT_COUNTRY_ICON = require(`../../../assets/icons/country.png`);
export const EVENT_TIME = require(`../../../assets/icons/time.png`);
export const EVENT_ATTENDEES = require(`../../../assets/icons/attendees.png`);
export const EVENT_DATE = require(`../../../assets/icons/event_date.png`);
export const EVENT_CITY = require(`../../../assets/icons/event_city.png`);
export const TICK_ICON = require(`../../../assets/icons/tick.svg`);
export const EMPTY_USER_TYPE_ICON = require(`../../../assets/empty_user_type.png`);
export const EMPTY_AMBASSADOR_ICON = require(`../../../assets/empty_ambassador.png`);
export const DEFAULT_STAT_RED = require(`../../../assets/default_stat.png`);
export const DEFAULT_STAT_BLUE = require(`../../../assets/default_stat_blue.png`);
export const MANAGE_ROLES_SELECTED = require(`../../../assets/manage_roles_selected.png`);
export const MANAGE_ROLES_UNSELECTED = require(`../../../assets/manage_roles_unselected.png`);
export const HOLDING_AREA_ACTIVE = require(`../../../assets/holding_active.svg`);
export const HOLDING_AREA_INACTIVE = require(`../../../assets/holding_inactive.svg`);