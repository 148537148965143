import React from "react";
import { reduxForm, Field } from "redux-form";

import { Input } from "../../atoms/input";
import { InputSubmit } from "../../atoms/input-submit";

import validator from "./validator";

import { STRINGS } from "./constants";

const {
  defaultConfig: { PLATFORM }
} = require("../../../../config/default");

const { onSubmitFail } = require(`../../../../helpers/${PLATFORM}`);

const { ROUTES } = require(`../../../../shared/${PLATFORM}/constants`);

const ChangePasswordForm = ({ handleSubmit }) => {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className="row">
        <Field
          name={STRINGS.OLD_PASSWORD_KEY}
          component={Input}
          label={STRINGS.OLD_PASSWORD_LABEL}
          widthStyle="col-md-6"
          config={{
            type: "password"
          }}
        />
      </div>
      <div className="row">
        <Field
          name={STRINGS.NEW_PASSWORD_KEY}
          component={Input}
          label={STRINGS.NEW_PASSWORD_LABEL}
          widthStyle="col-md-6"
          config={{
            type: "password"
          }}
        />
      </div>
      <div className="row">
        <Field
          name={STRINGS.CONFIRM_PASSWORD_KEY}
          component={Input}
          label={STRINGS.CONFIRM_PASSWORD_LABEL}
          widthStyle="col-md-6"
          config={{
            type: "password"
          }}
        />
      </div>
      {/* <InputSubmit
        buttonLabel={"SAVE"}
        containerStyle={["text-center", "mt-2"]}
        buttonStyle=""
        immediateDivStyle="justify-content-sm-center"
      /> */}
    </form>
  );
};

export const ChangePasswordReduxForm = reduxForm({
  form: "change-password",
  validate: validator,
  onSubmitFail: onSubmitFail
})(ChangePasswordForm);
