import {
  takeLatest,
  all,
  put
} from "redux-saga/effects";
import {
  REVERT_AMBASSADOR_ACCESS,
  setAuthorization
} from "../actions";
const {
  defaultConfig: {
    PLATFORM
  }
} = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const {
  putRequest
} = require(`../../../helpers/${PLATFORM}`);
const {
  STATUS_CODE
} = require(`../../../shared/${PLATFORM}/constants`);


function* revertAmbassadorAccess({
  data,
  success,
  failure
}) {
  try {
    const response = yield putRequest({
      API: `${api.URL.AMBASSADOR_ACCESS}`,
      DATA: data
    });
    if (response.status === STATUS_CODE.unAuthorized) {
      yield put(setAuthorization(null));
      return;
    }
    if (response.status !== STATUS_CODE.successful) {
      yield failure(response.data);
    } else {
      yield success(response.data);
    }
  } catch (error) {
    yield failure({
      msg: 'Something Went Wrong',
      status: false
    });
    return;
  }
}

function* AmbassadorSaga() {
  yield all([
    takeLatest(REVERT_AMBASSADOR_ACCESS, revertAmbassadorAccess)
  ]);
}

export default AmbassadorSaga;