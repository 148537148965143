import React, { useState } from "react";
import './style.scss';
import { Questions } from './questions';
import { Tests } from './tests';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const {
    SUB_ADMIN_TEST_TABS,
    SUB_ADMIN_PLATFORM,
    TEST_PAGE_TAB_VALUES,
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { CustomFileDrop } = require(`../../../../../../components/sef/cells/custom-filedrop`)
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { CustomTabs } = require(`../../../../../../components/sef/atoms/tab-drawer`);
const { Editor } = require(`../../../../../../components/sef/cells/editor-text`);
const { InstructionForm } = require(`./instruction-form`);
export const Screen = ({
    fetchTestList,
    championshipData,
    fetchQuestionList,
    questionList,
    testList,
    history,
    platformType,
    saveInstruction,
    stopLoader,
    updateTest,
    startLoader,
    deleteQuestion,
    createQuestion,
    createTest,
    uploadFile,
    fetchChampionships,
    fetchInstruction,
    testInstruction,
    userTypeList,
    fetchUserTypes
}) => {

    const [instruction, goInstruction] = useState(false);
    const [editInstruction, setEditInstruction] = useState(false);
    const [tabValue, setTabValue] = useState(TEST_PAGE_TAB_VALUES.questionsTab)
    const [addTest, setAddTest] = useState(false);
    // const [showQuestionsForm, setShowQuestionsForm] = useState(false);
    const [addButtonData, setAddButtonData] = useState({
        visible: true,
        onClickAction: () => { },
        label: ''
    })

    return (
        <div className="content-wrapper">
            <div className="align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                {/* {Condition for check if the test is empty or not} */}
                {<div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                    <h5>{STRINGS.TEST_TITLE}</h5>
                    {(platformType === SUB_ADMIN_PLATFORM) && addButtonData.visible && !editInstruction
                        && <div className="d-flex flex-column flex-sm-row">
                            <button className="btn btn-lg btn-outline-secondary mb-2 mr-0 mr-sm-2 edit_btn" onClick={() => {
                                setEditInstruction(true);
                                goInstruction(true);
                            }}>
                                <i className="edit-icon mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27.034" height="26.999" viewBox="0 0 27.034 26.999">
                                        <path id="Forma_1" d="M1738.813,731.506l5.5,5.519L1730.389,751l-5.493-5.519Zm9.675-1.331-2.451-2.462a2.433,2.433,0,0,0-3.442,0l-2.351,2.358,5.5,5.519,2.741-2.75A1.9,1.9,0,0,0,1748.489,730.175Zm-26.474,23.06a.629.629,0,0,0,.76.747l6.133-1.491-5.5-5.52Z" transform="translate(-1722 -727)" fill="#f42634" fillRule="evenodd" />
                                    </svg>
                                </i> {'Edit Instructions'}</button>
                            <button className={'btn btn-lg btn-primary mb-2'} onClick={() => {
                                addButtonData.onClickAction();
                                setAddButtonData({
                                    ...addButtonData,
                                    visible: false
                                })
                            }}>{addButtonData.label}</button>
                        </div>
                    }
                </div>}
                <div className={'card border-0 shadow'}>
                    <div className={'card-body p-0'}>
                        {platformType === SUB_ADMIN_PLATFORM && !editInstruction && !instruction && <CustomTabs
                            tabsItems={SUB_ADMIN_TEST_TABS}
                            value={tabValue}
                            handleTabChange={(newValue) => {
                                newValue ? setAddTest(false) : goInstruction(false)
                                setTabValue(newValue);
                            }}
                        />}
                        {/* {checking if there is any data in reduxfrom} */}
                        <div className="d-flex flex-column">
                            {
                                (tabValue
                                    || (platformType !== SUB_ADMIN_PLATFORM))
                                    ?
                                    <Tests
                                        fetchInstruction={fetchInstruction}
                                        testList={testList}
                                        fetchTestList={fetchTestList}
                                        startLoader={startLoader}
                                        history={history}
                                        fetchChampionships={fetchChampionships}
                                        championshipData={championshipData}
                                        stopLoader={stopLoader}
                                        instruction={instruction}
                                        userTypeList={userTypeList}
                                        fetchUserTypes={fetchUserTypes}
                                        addTest={addTest}
                                        setEditInstruction={(value) => setEditInstruction(value)}
                                        setAddButtonData={(condition, action, label) => {
                                            setAddButtonData({
                                                visible: condition,
                                                onClickAction: action,
                                                label: label
                                            })
                                        }}
                                        updateTest={updateTest}
                                        testTab={tabValue}
                                        setAddTest={(value) => setAddTest(value)}
                                        platformType={platformType}
                                        goInstruction={(value) => goInstruction(value)}
                                        saveInstruction={saveInstruction}
                                        testInstruction={testInstruction}
                                        createTest={createTest}
                                        editInstruction={editInstruction}
                                    />

                                    : <Questions
                                        fetchInstruction={fetchInstruction}
                                        questionList={questionList}
                                        editInstruction={editInstruction}
                                        setEditInstruction={(value) => setEditInstruction(value)}
                                        testInstruction={testInstruction}
                                        fetchQuestionList={fetchQuestionList}
                                        history={history}
                                        userTypeList={userTypeList}
                                        fetchUserTypes={fetchUserTypes}
                                        fetchChampionships={fetchChampionships}
                                        championshipData={championshipData}
                                        stopLoader={stopLoader}
                                        questionsTab={!tabValue}
                                        instruction={instruction}
                                        setAddButtonData={(condition, action, label) => {
                                            setAddButtonData({
                                                visible: condition,
                                                onClickAction: action,
                                                label: label
                                            })
                                        }}
                                        deleteQuestion={deleteQuestion}
                                        uploadFile={uploadFile}
                                        createQuestion={createQuestion}
                                        goInstruction={(value) => goInstruction(value)}
                                        saveInstruction={saveInstruction}
                                        platformType={platformType}
                                        startLoader={startLoader}
                                    />
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}

