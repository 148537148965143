import React, { useEffect, useState } from 'react';
import {
    Route,
    Switch,
    Redirect,
    useHistory
} from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
const { defaultConfig: { PLATFORM, LOCATION, VERSION } } = require(`../../../../config/default`);
const { STRINGS } = require(`../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { DecisionPopup } = require(`../../../../components/${PLATFORM}/atoms/decision-popup`);
const { PrivacyPolicy } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/privacy-policy`);
const { ScreenHOC } = require(`../../../../components/${PLATFORM}/hoc/screen-hoc`);
const { HomeScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/home`);
const { ChatScreen } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/common/chats`);
const { RequestList } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/race-director/requests`);
const { UserTestList } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/race-director/user-tests`);
const { UserTestDetails } = require(`../../../../views/${PLATFORM}/${LOCATION}/${VERSION}/race-director/user-test-details`);
const { styles } = require(`../../../../theme/${PLATFORM}`);
const {
    SUB_ADMIN_DRAWER_ITEMS,
    HAMBURGER_ICON,
    HEADER_LOGO,
    CLOSE_ICON,
    ROUTES,
    RACE_DIRECTOR_DRAWER_ITEMS,
    HEADER_PROFILE_ITEMS
} = require(`../../../../shared/${PLATFORM}/constants`);
const {
    setAuthorization,
    stopLoader,
    logout
} = require(`../../../../redux/${PLATFORM}/actions`);

const RaceDirectorRouter = (props) => {
    const { classes, setAuthorization, userToken, logout } = props;
    const [popupVisible, setPopVisible] = useState(false);
    let history = useHistory();
    return (
        <div>
            <ScreenHOC
                containerStyle={classes.screenHocContainer}
                childrenStyle={classes.screenContentContainer}
                drawerItems={RACE_DIRECTOR_DRAWER_ITEMS}
                headerMenuItem={HEADER_PROFILE_ITEMS}
                onClickAction={(changedUrl) => {
                    if (changedUrl === ROUTES.LOGOUT) {
                        setPopVisible(true);
                    }
                    else {
                        history.push(changedUrl);
                    }
                }}
                hamburgerIcon={HAMBURGER_ICON}
                headerLogo={HEADER_LOGO}
                headerLogoAction={() => {
                    history.replace(ROUTES.DASHBOARD)
                }}
                backArrow={CLOSE_ICON}
            >
                <DecisionPopup
                    modalVisibility={popupVisible}
                    dialogContent={STRINGS.LOGOUT_CONTENT}
                    dialogTitle={STRINGS.LOGOUT}
                    confirmButtonTitle={STRINGS.CONFIRM}
                    rejectButtonTitle={STRINGS.CANCEL}
                    toggleDialogModal={() => setPopVisible(!popupVisible)}
                    onConfirmation={() => {
                        logout(userToken, () => {
                            setPopVisible(false)
                            setAuthorization(null);
                            return <Redirect to={ROUTES.ROOT} />
                        });
                    }}
                    onRejection={() => setPopVisible(false)}
                />
                <Switch>
                    <Route exact path={`${ROUTES.USERS_TESTS}`} component={UserTestList} />
                    <Route exact path={`${ROUTES.REQUESTS}`} component={RequestList} />
                    <Route exact path={`${ROUTES.CHATS}`} component={ChatScreen} />
                    <Route exact path={`${ROUTES.USER_TEST_DETAILS}`} component={UserTestDetails} />
                    <Route exact path={`${ROUTES.PRIVACY_POLICY}`} component={PrivacyPolicy} />
                    <Redirect to={ROUTES.USERS_TESTS} />
                </Switch>
            </ScreenHOC>
        </div>
    );
}

const mapStateToProps = (state) => {
    return ({
        userToken: state.CommonReducer.userToken,
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAuthorization: (userData) => dispatch(setAuthorization(userData)),
        stopLoader: () => dispatch(stopLoader()),
        logout: (token, success) => dispatch(logout(token, success))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RaceDirectorRouter));
