import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    fetchUserTypes,
    fetchAttendeesList
} = require(`../../../../../../redux/${PLATFORM}/actions`)

const mapStateToProps = (state) => {
    return ({
        attendees: state.EventReducer.attendees,
        platformType: state.CommonReducer.platformType,
        championshipData: state.CommonReducer.championshipData,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAttendeesList: (id, success, failure) => dispatch(fetchAttendeesList(id, success, failure)),
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader()),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure))
    }
}
export const AttendeesScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);