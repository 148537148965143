import React, { useState, useEffect } from "react";
import { reduxForm, Field, reset, FieldArray, change as changeField } from "redux-form";
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { TextArea } = require(`../../../../../../components/${PLATFORM}/atoms/text-area`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { DatePickerInput } = require(`../../../../../../components/${PLATFORM}/atoms/date-picker`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { EMAIL_REGX } = require(`../../../../../../shared/${PLATFORM}/constants`);



const EditChampionshipForm = ({
    championshipName,
    championshipDescription,
    handleSubmit = () => { },
    onNameChange = () => { },
    onDescriptionChange = () => { },
    onStartDateChange = () => { },
    onEndDateChange = () => { },
    onSubmit = () => { },
    onCancel = () => { },
    changeField,
    championshipEndDate,
    championshipStartDate
}) => {
    let [startDate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')
    let [minEndDate, setMinEndDate] = useState('')
    useEffect(() => {
        changeField("edit", "start-date", championshipStartDate);
        changeField("edit", "end-date", championshipEndDate);

    }, [championshipEndDate, championshipStartDate]);
    const onChangeDate = (value) => {
        var copiedDate = new Date(value);
        changeField("edit", "start-date", value)
        setStartDate(value)
        setMinEndDate(copiedDate.setDate(value.getDate() + 1))

    }
    const onChangeEndDate = (value) => {
        value = new Date(value)

        setEndDate(value);
        changeField("edit", "end-date", value)

    }
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.CHAMPIONSHIP_INPUT}
                    component={Input}
                    label={STRINGS.CHAMPIONSHIP_NAME}
                    placeholder={STRINGS.CHAMPIONSHIP_NAME_PLACEHOLDER}
                    type={'text'}
                />
                <Field
                    name={STRINGS.CHAMPIONSHIP_DETAILS}
                    component={TextArea}
                    label={STRINGS.CHAMPIONSHIP_DESCRIPTION_LABEL}
                    placeholder={STRINGS.CHAMPIONSHIP_DETAILS_PLACEHOLDER}
                    type={'text'}
                />
            </div>
            <div className="row">
                <Field
                    name={STRINGS.START_DATE_NAME}
                    component={DatePickerInput}
                    label={STRINGS.START_DATE}
                    widthStyle={'col-md-6'}
                    minDate={new Date()}
                    onChangeDate={onChangeDate}

                />

                <Field
                    name={STRINGS.END_DATE_NAME}
                    component={DatePickerInput}
                    label={STRINGS.END_DATE}
                    widthStyle={'col-md-6'}
                    onChangeDate={onChangeEndDate}
                    minDate={minEndDate}


                />
            </div>
            <hr className="mt-0" />
            <div className="d-flex flex-row justify-content-end">
                <button type={'button'} className={'reject-button'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit buttonLabel={'Update'} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    let initialValues = {
        'championship-name': props.championshipName,
        'details': props.championshipDescription,
    };
    return {
        initialValues: initialValues
    };
}
const afterSubmit = (result, dispatch) => {
}

const reduxFormFunction = reduxForm({
    form: "edit",
    fields: ['championship-name', 'details', 'start-date', 'end-date'],
    validate: validator,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(EditChampionshipForm);

export const EditChampionshipReduxForm = connect(mapStateToProps, { changeField })(reduxFormFunction);