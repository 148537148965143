import React, { useEffect } from "react";
import { reduxForm, Field, reset, change as changeField } from "redux-form";
import { connect } from 'react-redux';
import "./style.scss";

import validator from "./validator";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { Form } = require(`../../../../../../components/${PLATFORM}/atoms/form`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`);
const { InputSubmit } = require(`../../../../../../components/${PLATFORM}/atoms/input-submit`);
const { Input } = require(`../../../../../../components/${PLATFORM}/atoms/input`);
const { onSubmitFail } = require(`../../../../../../helpers/${PLATFORM}`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);

const AddUserTypeForm = ({
    editForm = false,
    formText = '',
    changeField,
    handleSubmit = () => { },
    onSubmit = () => { },
    onCancel = () => { }
}) => {

    useEffect(() => {
        changeField("userType", 'user_type', editForm ? formText : '')
    }, [editForm, formText])
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <Field
                    name={STRINGS.USER_TYPE_INPUT}
                    component={Input}
                    label={STRINGS.USER_TYPE}
                    type={'text'}
                    placeholder={STRINGS.USER_TYPE_PLACEHOLDER}
                />
            </div>
            <hr className="mt-0" />
            <div className="d-flex flex-row justify-content-end">
                <button type={'button'} className={'reject-button'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                <InputSubmit buttonLabel={editForm ? 'Update' : STRINGS.ADD} buttonStyle={"accept-button btn-st-remove"} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, props) => {
    return {
    };
}
const afterSubmit = (result, dispatch) => { };
// dispatch(reset('admin'));

const reduxFormFunction = reduxForm({
    form: "userType",
    fields: ['role'],
    onSubmitFail,
    validate: validator,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(AddUserTypeForm);

export const UserTypeReduxForm = connect(mapStateToProps, { changeField })(reduxFormFunction);