import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { ChampionshipReduxForm } from './form';
import './style.scss';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const {
    PAGE_TITLES,
    TABLE_SIZE_ITEMS,
    LAYOUTS,
    MESSAGES,
    ROUTES,
    ROLE_LABELS,
    ACTIVE_PAGE_STYLE,
    LABELS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    EDIT_ICON,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
    EMPTY_CHAMPIONSHIP_ICON
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);


export const Screen = ({
    history,
    stopLoader,
    championship,
    startLoader,
    championshipList,
    fetchChampionships,
    createChampionships,
    updateChampionshipStatus
}) => {

    const [activePage, setActivePage] = useState(1);
    const [formVisible, setFormVisible] = useState(false);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [sortByKey, setSortByKey] = useState('createdAt');
    const [sortType, setSortType] = useState(-1);
    const [adminCount, setAdminCount] = useState([]);
    const [adminNameValues, setAdminNameValue] = useState({});
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    });
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    useEffect(() => {
        startLoader();
        fetchChampionships({
            searchString: '',
            sortKey: sortByKey,
            sortType: sortType,
            limit: tableSize.label,
            page: activePage
        }, () => {
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
    }, [activePage, tableSize.label, sortByKey, sortType]);

    const addNewEntry = (data) => {
        setAdminCount([
            ...adminCount,
            data
        ])
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.championshipList}</title>
            </Helmet>

            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />


                    <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <h5 className="my-2 text-center text-md-left">{formVisible ? STRINGS.ADD_NEW_CHAMPIONSHIP : STRINGS.CHAMPIONSHIP_LIST}</h5>
                        {championshipList && championshipList.metaData && championshipList.metaData.total !== 0 && !formVisible && <div className="group_btn">
                            <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => {
                                addNewEntry({
                                    id: new Date().getTime(),
                                    nameInput: `${STRINGS.NAME_INPUT}-${new Date().getTime()}`,
                                    emailInput: `${STRINGS.EMAIL_INPUT_NAME}-${new Date().getTime()}`
                                });
                                setFormVisible(true)
                            }}>{STRINGS.ADD_CHAMPIONSHIP}</button>
                        </div>
                        }
                    </div>
                    <div className={'card border-0 shadow'}>
                        <div className={classNames([
                            'card-body p-0 height-vh',
                            { 'd-flex align-items-center justify-content-center': !(championshipList && championshipList.metaData && championshipList.metaData.total !== 0) && !formVisible }
                        ])}>

                            <div className="championship-screen w-100">
                                {formVisible ?
                                    <ChampionshipReduxForm
                                        adminCount={adminCount}
                                        updateAdminCount={(data) => addNewEntry(data)}
                                        onCancel={() => setFormVisible(false)}
                                        removeAdmin={(data) => {
                                            let updateAdmins = [...adminCount];
                                            updateAdmins.map((item, index) => {
                                                if (item.id === data.id) {
                                                    updateAdmins.splice(index, 1);
                                                }
                                                return null;
                                            });
                                            setAdminCount([...updateAdmins]);
                                        }}
                                        adminNameValues={adminNameValues}
                                        onNameChange={(field, value) => {
                                            setAdminNameValue({
                                                ...adminNameValues,
                                                field: value
                                            })
                                        }}
                                        onSubmit={(formProps) => {
                                            startLoader();
                                            let start = championship.values[STRINGS.START_DATE_NAME];
                                            let end = championship.values[STRINGS.END_DATE_NAME];
                                            createChampionships(
                                                {
                                                    name: championship.values[STRINGS.CHAMPIONSHIP_INPUT],
                                                    description: championship.values[STRINGS.CHAMPIONSHIP_DETAILS],
                                                    startDate: new Date(start),
                                                    endDate: new Date(end),
                                                    admins: [...championship.values.members]
                                                },
                                                (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    setFormVisible(false);
                                                    stopLoader();
                                                    fetchChampionships({
                                                        searchString: '',
                                                        sortKey: sortByKey,
                                                        sortType: sortType,
                                                        limit: tableSize.label,
                                                        page: activePage
                                                    }, () => {
                                                        stopLoader();
                                                        setOpenSnackbar(true);
                                                    },
                                                        (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        });
                                                },
                                                (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    setOpenSnackbar(true);
                                                    stopLoader();
                                                }
                                            )
                                        }}
                                    /> : !(championshipList && championshipList.metaData && championshipList.metaData.total !== 0) ?
                                        <div className={'row py-4 py-md-4'}>
                                            <div className="col-md-8 offset-md-2 text-center">
                                                <figure className="mb-3 mb-md-4">
                                                    <img src={EMPTY_CHAMPIONSHIP_ICON} alt={'dashboard-logo'} className="img-fluid" />
                                                </figure>
                                                <h5 className="mb-3">{STRINGS.EMPTY_CHAMPIONSHIP_HEADING}</h5>
                                                <p>{STRINGS.EMPTY_CHAMPIONSHIP_DESCRIPTION}</p>

                                                <div className="group_btn mt-3 mt-md-4">
                                                    <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => {
                                                        addNewEntry({
                                                            id: new Date().getTime(),
                                                            nameInput: `${STRINGS.NAME_INPUT}-${new Date().getTime()}`,
                                                            emailInput: `${STRINGS.EMAIL_INPUT_NAME}-${new Date().getTime()}`
                                                        });
                                                        setFormVisible(true)
                                                    }}>{STRINGS.ADD_CHAMPIONSHIP}</button>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="table-responsive">
                                                <CustomTable
                                                    rows={championshipList.data}
                                                    rowsPerPage={tableSize.label}
                                                    columns={LAYOUTS.championshipTable}
                                                    activePage={1}
                                                    sortByKey={sortByKey}
                                                    sortType={sortType}
                                                    emptyDataMessage={MESSAGES.noRecordsFound}
                                                    TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                                    TABLE_ARROW_UP={TABLE_ARROW_UP}
                                                    ROLE_LABELS={ROLE_LABELS}
                                                    EDIT_ICON={EDIT_ICON}
                                                    onEditClick={(id, index) => { history.push(`${ROUTES.CHAMPIONSHIP_DETAIL}?id=${id}`) }}
                                                    onStatusChange={(value, id) => {
                                                        updateChampionshipStatus({
                                                            championshipId: id,
                                                            status: value ? 1 : 0
                                                        }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            stopLoader();
                                                            fetchChampionships({
                                                                searchString: '',
                                                                sortKey: sortByKey,
                                                                sortType: sortType,
                                                                limit: tableSize.label,
                                                                page: activePage
                                                            }, () => {
                                                                stopLoader();
                                                                setOpenSnackbar(true);
                                                            },
                                                                (response) => {
                                                                    setSnackBarData({
                                                                        variant: response.status ? 'success' : 'error',
                                                                        message: response.msg
                                                                    });
                                                                    setOpenSnackbar(true);
                                                                    stopLoader();
                                                                });
                                                        }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        })
                                                    }}
                                                    setSortKey={(key) => {
                                                        if (sortByKey === key) {
                                                            setSortType(-1 * sortType);
                                                        }
                                                        else {
                                                            setSortType(1)
                                                        }
                                                        setSortByKey(key)
                                                    }}
                                                />
                                                {championshipList && championshipList.data && championshipList.data.length && (championshipList.metaData.total > TABLE_SIZE_ITEMS[0].label) && <div className="text-center px-3">
                                                    <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                                        <CustomDropdown
                                                            labelText={LABELS.noOfRows}
                                                            dataItems={TABLE_SIZE_ITEMS}
                                                            value={tableSize.value}
                                                            dropDataSet={(value) => {
                                                                setActivePage(1);
                                                                setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                            }}
                                                        />
                                                        {
                                                            (championshipList && championshipList.metaData && championshipList.metaData.total > tableSize.label) ? <CustomPagination
                                                                displayingString={STRINGS.DISPLAYING}
                                                                outOfString={STRINGS.OUT_OF}
                                                                totalPages={championshipList.metaData.total}
                                                                itemsCount={championshipList.data.length}
                                                                pageCount={LIMITS.paginationPageCount}
                                                                limit={tableSize.label}
                                                                currentPage={activePage}
                                                                nextArrow={NEXT_ARROW}
                                                                prevArrow={PREV_ARROW}
                                                                onPageChange={(selectedPage) => {
                                                                    let page = selectedPage.selected + 1;
                                                                    document.getElementById('screen-content').scroll(
                                                                        {
                                                                            top: 0,
                                                                            left: 0,
                                                                            behavior: 'smooth'
                                                                        }
                                                                    )
                                                                    setActivePage(page);
                                                                }}
                                                                activePageStyle={ACTIVE_PAGE_STYLE}
                                                            />
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>}
                                            </div>
                                        </React.Fragment>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}