import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { fetchUserInfo, fetchUserStatistics } = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        userDetails: state.UserReducer.userDetails,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserInfo: (id, success, error) => dispatch(fetchUserInfo(id, success, error)),
    }
}
export const UserDetailScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);