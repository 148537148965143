import React, { useState, useEffect } from 'react';
import { reduxForm, Field, reset, FieldArray, formValues, change as changeField } from "redux-form";
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { validator } from './validator';
import { startLoader, stopLoader } from '../../../../../../redux/sef/actions';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { Form } = require(`../../../../../../components/sef/atoms/form`);
const { InputSubmit } = require(`../../../../../../components/sef/atoms/input-submit`);
const { STRINGS } = require(`../../../../../../shared/sef/constants/us/strings`);
const { Input } = require(`../../../../../../components/sef/atoms/input`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { TimeInput } = require(`../../../../../../components/sef/atoms/time-input`);
const { Select } = require(`../../../../../../components/sef/atoms/select`);
const { AnswerList } = require(`../../../../../../components/sef/molecules/test-multiple`);
const {
    QUESTION_TYPE,
    ANSWER_TYPE,
    SINGLE_CORRECT_RADIO_OPTIONS,
    BOOLEAN_RADIO_OPTIONS
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { AnswerListRadio } = require(`../../../../../../components/sef/molecules/test-radio`);
const { TestFileDrop } = require(`../../../../../../components/sef/molecules/test-file-drop`);

const renderTimeFields = ({ fields, value }) => {
    fields.length === 0 && fields.push({})
    return (
        fields.map((member, index) => {
            return (
                <div className="col-md-6">
                    <label>{STRINGS.QUESTION_TIME_LABEL}</label>
                    <div className="form-row">
                        <Field
                            name={`${member}.${STRINGS.MINUTE_INPUT}`}
                            component={TimeInput}
                            label={''}
                            minuteInput={true}
                            config={{
                                max: 60,
                                min: 0,
                                defaultValue: 1
                            }}
                            placeholder={'minutes'}
                            widthStyle={'col-md-4 col-6'}
                        />
                        <label className="d-xs-none colon_div">{':'}</label>
                        <Field
                            name={`${member}.${STRINGS.SECONDS_INPUT}`}
                            component={TimeInput}
                            label={''}
                            config={{
                                max: 59,
                                min: 0
                            }}
                            placeholder={'seconds'}
                            widthStyle={'col-md-4 col-6'}
                        />
                    </div>
                </div>
            )
        })
    );
}

const renderSelectedAnswerType = (answerType, changeField, rightOptionList) => {
    switch (answerType) {
        case ANSWER_TYPE[0].value:
            return <AnswerListRadio
                answerType={answerType}
                answerRadioOption={BOOLEAN_RADIO_OPTIONS}
                onChoseAnswer={(value) => {
                    changeField("addTest", 'right_option_list', [value])

                }} />
        case ANSWER_TYPE[1].value:
            return <AnswerListRadio
                answerType={answerType}
                answerRadioOption={SINGLE_CORRECT_RADIO_OPTIONS}
                onChoseAnswer={(value) => {
                    changeField("addTest", 'right_option_list', [value])
                }} />
        case ANSWER_TYPE[2].value:
            return <AnswerList
                rightOptionList={rightOptionList}
                onChoseAnswer={(checked, value) => {
                    if (checked) {
                        changeField("addTest", 'right_option_list', [...rightOptionList, value])
                    }
                    else {
                        let rightOptions = [...rightOptionList];
                        rightOptions.splice(rightOptions.indexOf(value), 1)
                        changeField("addTest", 'right_option_list', [...rightOptions])
                    }
                }} />
        default:
            return null
    }
}

export const Screen = ({
    widthStyle,
    buttonStyle,
    answerType,
    questionType,
    changeField,
    rightOptionList,
    uploadFile,
    startLoader,
    userTypeList,
    fetchUserTypes,
    stopLoader,
    onSubmit = () => { },
    onCancel = () => { },
    handleSubmit = () => { },
    ...props
}) => {
    const [formData, setFormData] = useState('');
    const [hasFormData, setHasFormData] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [selectedQuestionType, setQuestionType] = useState(QUESTION_TYPE[0]);
    const [roleItems, setRoleItems] = useState([]);
    const [selectedUserType, setUserType] = useState(roleItems[0] ? roleItems[0] : null);
    const [selectedAnwserType, setAnswerType] = useState(ANSWER_TYPE[0]);

    useEffect(() => {
        fetchUserTypes((response) => {
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
        });
    }, [])

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([...items]);
            setUserType(items[0]);
        }
    }, [userTypeList && userTypeList.length])

    useEffect(() => {
        if (questionType) {
            setQuestionType(QUESTION_TYPE[questionType - 1]);
        }
    }, [questionType]);

    useEffect(() => {
        changeField("addTest", 'time[0].question_minute', 1)
        changeField("addTest", 'time[0].question_seconds', 0)
    }, [])

    return (

        <div className="p-4">
            <Form
                onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <Field
                        name={STRINGS.USER_TYPE_INPUT}
                        component={Select}
                        label={STRINGS.USER_TYPE_LABEL}
                        widthStyle={"col-md-6"}
                        type="type"
                        onValueChange={value => setUserType(value)}
                        options={roleItems}
                    />
                    <Field
                        name={STRINGS.QUESTION_TYPE}
                        component={Select}
                        label={STRINGS.QUESTION_TYPE_LABEL}
                        widthStyle={"col-md-6"}
                        value={selectedQuestionType.value}
                        options={QUESTION_TYPE}
                        onValueChange={value => setQuestionType(value)}
                    />
                </div>
                <div className="row">
                    <Field
                        name={STRINGS.ANSWER_TYPE}
                        component={Select}
                        label={STRINGS.ANSWER_TYPE_LABEL}
                        placeholder={STRINGS.TYPE_HERE}
                        value={selectedAnwserType.value}
                        onValueChange={value => setAnswerType(value)}
                        widthStyle={"col-md-6"}
                        options={ANSWER_TYPE}
                    />
                    <FieldArray name="time" component={renderTimeFields} />
                </div>
                <div className="row">
                    <Field
                        name={STRINGS.QUESTION_TITLE}
                        component={Input}
                        label={STRINGS.QUESTION_TITLE_LABEL}
                        placeholder={STRINGS.TYPE_HERE}
                        type={'text'}
                        widthStyle={"col-md-12"}
                    />
                </div>
                {renderSelectedAnswerType(answerType, changeField, rightOptionList)}
                <div className="row">
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />
                    <Field
                        name={STRINGS.FILE}
                        component={TestFileDrop}
                        handleSubmit={({ meta, file }, status) => {
                            setFormData({ meta, file });
                            if (status === 'removed') {
                                setHasFormData(false);
                            }
                            if (status === 'rejected_file_type') {
                                setHasFormData(false);
                                setSnackBarData({
                                    variant: 'error',
                                    message: 'File format is not supported.'
                                });
                                setOpenSnackbar(true);
                            }
                            if (status === 'error_file_size') {
                                setHasFormData(false);
                                setSnackBarData({
                                    variant: 'error',
                                    message: 'File size is larger than 16MB.'
                                });
                                setOpenSnackbar(true);
                            }
                            if (status === 'preparing' || status === 'done') {
                                if (status === 'done') {
                                    startLoader();
                                    uploadFile({ meta, file },
                                        (response) => {
                                            changeField("addTest", 'file', response)
                                            setHasFormData(true);
                                            stopLoader()
                                        },
                                        () => { })
                                }
                            }
                        }}
                        fileType={selectedQuestionType.value === QUESTION_TYPE[0].value ? null : selectedQuestionType.value === QUESTION_TYPE[1].value ? '.png,.jpeg,.svg,.jpg,.gif' : selectedQuestionType.value === QUESTION_TYPE[2].value ? '.wav,.mp3' : '.webm,.MPG,.MP2,.MPEG,.MPE,.MPV,.mp4,.m4p,.m4v'}
                        widthStyle={"col-md-12"}
                    />
                </div>
                <hr className="mt-0" />
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex flex-row btn-res mt-2">
                            <button type={'button'} className={'btn btn-lg btn-outline-primary mr-2'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                            <InputSubmit
                                disabled={questionType !== QUESTION_TYPE[0].value && !hasFormData ? true : false}
                                buttonLabel={'Save'} />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}


const mapStateToProps = (state, props) => {
    return {
        answerType: state.form && state.form.addTest && state.form.addTest.values &&
            state.form.addTest.values.answer_type &&
            state.form.addTest.values.answer_type.value,
        questionType: state.form && state.form.addTest && state.form.addTest.values &&
            state.form.addTest.values.question_type &&
            state.form.addTest.values.question_type.value,
        rightOptionList: state.form && state.form.addTest && state.form.addTest.values && state.form.addTest.values.right_option_list ? state.form.addTest.values.right_option_list : []
    };
}

const reduxFormFunction = reduxForm({
    form: "addTest",
    validate: validator,
    enableReinitialize: true
})(Screen);

export const AddQuestions = connect(mapStateToProps, { changeField })(reduxFormFunction);