import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    addNewUserType,
    editUserType,
    fetchUserTypes
} = require(`../../../../../../redux/sef/actions`)


const mapStateToProps = (state) => {
    return ({
        platformType: state.CommonReducer.platformType,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        stopLoader: () => dispatch(stopLoader()),
        startLoader: () => dispatch(startLoader()),
        addNewUserType: (data, success, failure) => dispatch(addNewUserType(data, success, failure)),
        editUserType: (data, success, failure) => dispatch(editUserType(data, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure))
    }
}
export const RoleScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);



