import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { EventReduxForm } from './form';
import { useEffect } from "react";
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { SearchableDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/searchable-dropdown`);
const { LAYOUTS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    PAGE_TITLES,
    LABELS,
    MESSAGES,
    DELETE_ICON,
    EMPTY_EVENT_ICON,
    SEARCH_ICON,
    TABLE_ARROW_DOWN,
    ROLE_LABELS,
    TABLE_SIZE_ITEMS,
    TABLE_ARROW_UP,
    EDIT_ICON,
    ROUTES,
    SUB_ADMIN_PLATFORM,
    ACTIVE_PAGE_STYLE, } = require(`../../../../../../shared/${PLATFORM}/constants`);

const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const { Select } = require(`../../../../../../components/${PLATFORM}/atoms/select`)

const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

export const Screen = ({
    history,
    startLoader,
    eventList,
    stopLoader,
    deleteEvent,
    createEvent,
    platformType,
    fetchEventsList,
    championshipList,
    championshipData,
    fetchChampionships,
}) => {
    const [popupVisible, setPopVisible] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [sortType, setSortType] = useState(-1);
    const [sortByKey, setSortByKey] = useState('date')
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [selectedChampionship, setSelectedChampionship] = useState(null);
    const [champList, setChampList] = useState(null)
    const [CHAMPIONSHIPS_ITEMS, setChampionshipItems] = useState([]);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [formVisible, setFormVisible] = useState(false);
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        onConfirmation: () => { }
    });
    useEffect(() => {
        fetchChampionships({
            searchString: '',
            sortKey: 'name',
            sortType: 1,
            limit: 0,
            page: 1
        }, (response) => {
            let items = [];
            response.data.map((item, index) => {
                items.push({
                    label: item.name,
                    value: index + 1
                });
                return null;
            });
            setChampList([...response.data])
            setChampionshipItems([...items])
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
    }, []);

    useEffect(() => {
        fetchEventsList({
            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
            searchString: searchString,
            sortKey: sortByKey === 'date' ? "createdAt" : sortByKey === 'championship-name' ? 'name' : sortByKey,
            sortType: sortType,
            limit: tableSize.label,
            page: activePage
        }, () => { }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            stopLoader();
            setOpenSnackbar(true);
        })
    }, [tableSize.value, activePage, searchString, sortType, sortByKey]);

    return (
        <div>
            <React.Fragment>
                <Helmet>
                    <title>{PAGE_TITLES.events}</title>
                </Helmet>
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />

                    <div className="align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                            <h5 className="mb-2">{STRINGS.EVENT_TITLE} <span>{platformType === SUB_ADMIN_PLATFORM ? '-' : ''} {championshipData && championshipData.name}</span></h5>
                            {((eventList && eventList.metaData && (eventList.metaData.total !== 0 || !!searchString) && !formVisible) && (platformType === SUB_ADMIN_PLATFORM)) && <button className={'btn btn-lg btn-primary mb-2'} onClick={() => { setFormVisible(true) }}>{STRINGS.ADD_EVENT}</button>}
                        </div>
                        <div className={'card border-0 shadow'}>
                            <div className={classNames([
                                'card-body height-vh',
                                { 'p-0': (eventList && eventList.metaData && (eventList.metaData.total !== 0 || !!searchString) && !formVisible) || platformType !== SUB_ADMIN_PLATFORM },
                                { 'd-flex align-items-center justify-content-center': !(eventList && eventList.metaData && eventList.metaData.total !== 0) && !formVisible && !searchString && (platformType === SUB_ADMIN_PLATFORM) }
                            ])}>

                                <div className="event-screen w-100">
                                    {formVisible ? <EventReduxForm
                                        onSubmit={(formProps) => {
                                            startLoader();
                                            let date = formProps['start-date'];
                                            let time = new Date(formProps['start-time']);
                                            let modifiedDate = date.setHours(time.getHours(), time.getMinutes(), 0, 0);
                                            modifiedDate = new Date(modifiedDate)
                                            createEvent({
                                                championshipId: championshipData._id,
                                                name: formProps['event-name'],
                                                description: formProps['event-details'],
                                                dateTime: modifiedDate.getTime(),
                                                country: formProps.country,
                                                city: formProps.city,
                                                circuit: formProps.circuit,
                                                startCheckInTime: formProps.checkin.value + 4
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                fetchEventsList({
                                                    championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                    searchString: searchString,
                                                    sortKey: sortByKey === 'date' ? "createdAt" : sortByKey,
                                                    sortType: sortType,
                                                    limit: tableSize.label,
                                                    page: activePage
                                                }, () => { }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    stopLoader();
                                                    setOpenSnackbar(true);
                                                });
                                                setFormVisible(false);
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            })
                                        }}
                                        onEmailChange={(value) => { }}
                                        onNameChange={(value) => { }}
                                        onRoleChange={(value) => { }}
                                        onCancel={() => { setFormVisible(false) }}
                                    />
                                        :
                                        <div className="d-flex flex-column">

                                            {
                                                eventList && eventList.metaData && (!eventList.metaData.total || !!searchString) && !searchString && (platformType === SUB_ADMIN_PLATFORM)
                                                &&
                                                <div className={'row py-4 py-md-4'}>
                                                    <div className="col-md-8 offset-md-2 text-center">
                                                        <figure className="mb-3 mb-md-4">
                                                            <img src={EMPTY_EVENT_ICON} alt={'dashboard-logo'} className="img-fluid" />
                                                        </figure>
                                                        <h5 className="mb-3">{STRINGS.EMPTY_EVENT_HEADING}</h5>
                                                        <p>{STRINGS.EMPTY_EVENT_DESCRIPTION}</p>

                                                        <div className="group_btn mt-3 mt-md-4">
                                                            <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => { setFormVisible(true) }}>{STRINGS.ADD_EVENT}</button>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>}

                                    <div className={'card-body p-0'}>
                                        <React.Fragment>
                                            <div className="table_filter">
                                                {(platformType !== SUB_ADMIN_PLATFORM || (eventList && eventList.metaData && (eventList.metaData.total !== 0 || !!searchString) && !formVisible))
                                                    &&
                                                    <React.Fragment>
                                                        <div className="form-row table-listing">
                                                            <div className="col-md-3">
                                                                <div className='table-search'>
                                                                    <TextField
                                                                        className='text-field'
                                                                        label={LABELS.search}
                                                                        type="text"
                                                                        value={searchString}
                                                                        placeholder={STRINGS.SEARCH}
                                                                        onChange={e => { setSearchString(e.target.value) }}
                                                                        margin="0"
                                                                    />
                                                                    <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                                                                </div>
                                                            </div>

                                                            <div className={'col-md-9'}>
                                                                <div className="row justify-content-between justify-content-md-end select_multi mt-2 mt-md-0">
                                                                    {platformType !== SUB_ADMIN_PLATFORM && <SearchableDropdown
                                                                        placeholder={STRINGS.SELECT_CHAMPIONSHIP}
                                                                        options={CHAMPIONSHIPS_ITEMS}
                                                                        isSearchable={true}
                                                                        value={selectedChampionship && selectedChampionship.value ? selectedChampionship.value : ''}
                                                                        onValueChange={(value) => {
                                                                            setSelectedChampionship(CHAMPIONSHIPS_ITEMS[value - 1]);
                                                                            fetchEventsList({
                                                                                championshipId: champList[value.value - 1]._id,
                                                                                searchString: searchString,
                                                                                sortKey: sortByKey === 'date' ? "createdAt" : sortByKey,
                                                                                sortType: sortType,
                                                                                limit: tableSize.label,
                                                                                page: activePage
                                                                            }, () => { }, (response) => {
                                                                                setSnackBarData({
                                                                                    variant: response.status ? 'success' : 'error',
                                                                                    message: response.msg
                                                                                });
                                                                                stopLoader();
                                                                                setOpenSnackbar(true);
                                                                            })
                                                                            setActivePage(1);
                                                                        }}
                                                                    />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>

                                            <SnackbarWrapper
                                                visible={openSnackBar}
                                                onClose={() => setOpenSnackbar(false)}
                                                variant={snackbarData.variant}
                                                message={snackbarData.message}
                                            />

                                            {((eventList && eventList.metaData && (eventList.metaData.total !== 0 || !!searchString) && !formVisible) || (platformType !== SUB_ADMIN_PLATFORM))
                                                && <div className="table-responsive"><CustomTable
                                                    rows={eventList && eventList.data ? eventList.data : []}
                                                    rowsPerPage={tableSize.label}
                                                    showProfile={false}
                                                    isEvent={true}
                                                    columns={platformType === SUB_ADMIN_PLATFORM ? LAYOUTS.eventList : LAYOUTS.superEventList}
                                                    activePage={1}
                                                    sortByKey={sortByKey}
                                                    sortType={sortType}
                                                    TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                                    emptyDataMessage={MESSAGES.noRecordsFound}
                                                    TABLE_ARROW_UP={TABLE_ARROW_UP}
                                                    ROLE_LABELS={ROLE_LABELS}
                                                    EDIT_ICON={EDIT_ICON}
                                                    DELETE_ICON={DELETE_ICON}
                                                    onRowClick={(id) => {
                                                        if (platformType !== SUB_ADMIN_PLATFORM) {
                                                            history.push(`${ROUTES.EVENTS_DETAILS}?id=${id}`)
                                                        }
                                                        else
                                                            return;
                                                    }}
                                                    onDeleteClick={(id) => {
                                                        setPopupData({
                                                            popupContent: STRINGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE,
                                                            popupTitle: STRINGS.DELETE,
                                                            onConfirmation: () => {
                                                                setPopVisible(false);
                                                                startLoader()
                                                                deleteEvent({ eventId: id },
                                                                    (response) => {
                                                                        setSnackBarData({
                                                                            variant: response.status ? 'success' : 'error',
                                                                            message: response.msg
                                                                        });
                                                                        fetchEventsList({
                                                                            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                                            searchString: searchString,
                                                                            sortKey: sortByKey === 'date' ? "createdAt" : sortByKey,
                                                                            sortType: sortType,
                                                                            limit: tableSize.label,
                                                                            page: activePage
                                                                        }, () => {
                                                                            stopLoader();
                                                                            setOpenSnackbar(true);
                                                                        }, (response) => {
                                                                            setSnackBarData({
                                                                                variant: response.status ? 'success' : 'error',
                                                                                message: response.msg
                                                                            });
                                                                            stopLoader();
                                                                            setOpenSnackbar(true);
                                                                        });
                                                                        stopLoader();
                                                                        setOpenSnackbar(true);
                                                                    }, (response) => { })
                                                            }
                                                        })
                                                        setPopVisible(true);

                                                    }}
                                                    onEditClick={(id, index) => { history.push(`${ROUTES.EVENTS_DETAILS}?id=${id}`) }}
                                                    setSortKey={(key) => {
                                                        if (sortByKey === key) {
                                                            setSortType(-1 * sortType);
                                                        }
                                                        else {
                                                            setSortType(1)
                                                        }
                                                        setSortByKey(key)
                                                    }}
                                                />
                                                    {eventList && eventList.data && !!eventList.data.length && !formVisible && (eventList.metaData.total > TABLE_SIZE_ITEMS[0].label)
                                                        && <div className="text-center px-3">
                                                            <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                                                <CustomDropdown
                                                                    labelText={LABELS.noOfRows}
                                                                    dataItems={TABLE_SIZE_ITEMS}
                                                                    value={tableSize.value}
                                                                    dropDataSet={(value) => {
                                                                        setActivePage(1);
                                                                        setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                                    }}
                                                                />
                                                                {(eventList && eventList.metaData && eventList.metaData.total > tableSize.label)
                                                                    ? <CustomPagination
                                                                        displayingString={STRINGS.DISPLAYING}
                                                                        outOfString={STRINGS.OUT_OF}
                                                                        totalPages={eventList.metaData.total}
                                                                        itemsCount={eventList.data.length}
                                                                        pageCount={LIMITS.paginationPageCount}
                                                                        limit={tableSize.label}
                                                                        currentPage={activePage}
                                                                        nextArrow={NEXT_ARROW}
                                                                        prevArrow={PREV_ARROW}
                                                                        onPageChange={(selectedPage) => {
                                                                            let page = selectedPage.selected + 1;
                                                                            document.getElementById('screen-content').scroll(
                                                                                {
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    behavior: 'smooth'
                                                                                }
                                                                            )
                                                                            setActivePage(page);
                                                                        }}
                                                                        activePageStyle={ACTIVE_PAGE_STYLE}
                                                                    />
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>}
                                                </div>}
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div >

    );
}