import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { LAYOUTS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    PAGE_TITLES,
    LABELS,
    MESSAGES,
    DELETE_ICON,
    EMPTY_EMERGENCY_ICON,
    SEARCH_ICON,
    TABLE_ARROW_DOWN,
    ROLE_LABELS,
    TABLE_SIZE_ITEMS,
    TABLE_ARROW_UP,
    EDIT_ICON,
    ROUTES,
    SUB_ADMIN_PLATFORM,
    ACTIVE_PAGE_STYLE, } = require(`../../../../../../shared/${PLATFORM}/constants`);

const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { ContactOptions } = require(`./contact-options`)
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);

const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

export const Screen = ({
    history,
    startLoader,
    fetchUsersList,
    emergencyContacts = { metaData: {} },
    stopLoader,
    usersList,
    platformType,
    championshipData,
    giveEmergencyAccess,
    updateApprovalStatus,
    revertEmergencyAccess,
    updateActivationStatus
}) => {
    const [popupVisible, setPopVisible] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [sortType, setSortType] = useState(-1);
    const [sortByKey, setSortByKey] = useState('date');
    const [addContactList, updateAddContactList] = useState([]);
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [showList, setShowList] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: 'Confirm',
        onConfirmation: () => { }
    });

    useEffect(() => {
        startLoader();
        fetchUsersList({
            searchString: searchString,
            filterRole: 0,
            filterStatus: 0,
            sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
            emergencyAccess: true,
            sortType: sortType,
            limit: tableSize.label,
            page: activePage
        }, () => {
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
    }, [tableSize.value, activePage, sortType, sortByKey])

    return (
        <div>
            <React.Fragment>
                <Helmet>
                    <title>{PAGE_TITLES.events}</title>
                </Helmet>
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />

                    {showList && <div className='dropzone-div'>
                        <div className="overlay"></div>
                        <div className="dropzone-dialog dialog-lg">
                            <div className="dropzone-content">
                                <div className="dropzone-body">
                                    <ContactOptions
                                        fetchUsersList={fetchUsersList}
                                        usersList={usersList}
                                        startLoader={startLoader}
                                        stopLoader={stopLoader}
                                        championshipData={championshipData}
                                        updateActivationStatus={updateActivationStatus}
                                        updateApprovalStatus={updateApprovalStatus}
                                        addContactList={addContactList}
                                        onStatusChange={(value, id) => {
                                            let temp = [...addContactList];
                                            if (value) {
                                                temp.push(id)
                                            }
                                            else {
                                                temp.splice(temp.indexOf(id), 1)
                                            }
                                            updateAddContactList(temp);
                                        }}
                                    />
                                    <div className="d-flex flex-column flex-md-row justify-content-sm-start">
                                        <button type={'button'} className={'reject-button'} onClick={() => {
                                            startLoader();
                                            updateAddContactList([]);
                                            fetchUsersList({
                                                searchString: searchString,
                                                filterRole: 0,
                                                filterStatus: 0,
                                                sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                emergencyAccess: true,
                                                sortType: sortType,
                                                limit: tableSize.label,
                                                page: activePage
                                            }, () => {
                                                stopLoader();
                                            },
                                                (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    setOpenSnackbar(true);
                                                    stopLoader();
                                                });
                                            setShowList(false)
                                        }}>{STRINGS.CANCEL}</button>
                                        {usersList && usersList.data && usersList.data.length !== 0 && <button type={'button'} className={'accept-button'} onClick={() => {
                                            if (addContactList.length) {
                                                startLoader();
                                                giveEmergencyAccess({
                                                    championshipId: championshipData._id,
                                                    userIds: [...addContactList]
                                                }, (response) => {
                                                    updateAddContactList([]);
                                                    fetchUsersList({
                                                        searchString: searchString,
                                                        filterRole: 0,
                                                        filterStatus: 0,
                                                        sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                        emergencyAccess: true,
                                                        sortType: sortType,
                                                        limit: tableSize.label,
                                                        page: activePage
                                                    }, () => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        setOpenSnackbar(true);
                                                        stopLoader();
                                                        stopLoader();
                                                    },
                                                        (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            setOpenSnackbar(true);
                                                            stopLoader();
                                                        });
                                                }, () => { })
                                            }
                                            else {
                                                startLoader();
                                                fetchUsersList({
                                                    searchString: searchString,
                                                    filterRole: 0,
                                                    filterStatus: 0,
                                                    sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                    emergencyAccess: true,
                                                    sortType: sortType,
                                                    limit: tableSize.label,
                                                    page: activePage
                                                }, () => {
                                                    stopLoader();
                                                },
                                                    (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        setOpenSnackbar(true);
                                                        stopLoader();
                                                    });
                                            }
                                            setShowList(false);
                                        }}>{STRINGS.SAVE}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className="align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                        <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-wrap">
                            <h5 className="mb-2">{STRINGS.EMERGENCY_CONTACTS} <span>{championshipData && championshipData.name}</span></h5>
                            {platformType === SUB_ADMIN_PLATFORM && (usersList && usersList.metaData && !(!usersList.metaData.total)) && < button className={'btn btn-lg btn-primary mb-2'} onClick={() => { setShowList(true) }}>{STRINGS.ADD_EMERGENCY_USERS}</button>}
                        </div>
                        <div className={'card border-0 shadow'}>
                            <div className={classNames([
                                'card-body height-vh',
                                { 'p-0': usersList && usersList.metaData && (usersList.metaData.total !== 0 || !!searchString) },
                                { 'd-flex align-items-center justify-content-center': !(usersList && usersList.metaData && (usersList.metaData.total !== 0 || !!searchString)) }
                            ])}>


                                <div className="event-screen w-100">

                                    <div className={'card-body p-0'}>
                                        <React.Fragment>
                                            <div className="table_filter">
                                                <div className="form-row table-listing">
                                                    {usersList && usersList.metaData && (usersList.metaData.total !== 0 || !!searchString) &&
                                                        <React.Fragment>
                                                            <div className="col-md-3">
                                                                <div className='table-search'>
                                                                    <TextField
                                                                        className='text-field'
                                                                        label={LABELS.search}
                                                                        type="text"
                                                                        value={searchString}
                                                                        placeholder={STRINGS.SEARCH}
                                                                        onChange={e => {
                                                                            setSearchString(e.target.value);
                                                                            if (!e.target.value) {
                                                                                startLoader();
                                                                            }
                                                                            fetchUsersList({
                                                                                searchString: e.target.value,
                                                                                filterRole: 0,
                                                                                filterStatus: 0,
                                                                                sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                                                emergencyAccess: true,
                                                                                sortType: sortType,
                                                                                limit: tableSize.label,
                                                                                page: activePage
                                                                            }, () => {
                                                                                stopLoader();
                                                                            },
                                                                                (response) => {
                                                                                    setSnackBarData({
                                                                                        variant: response.status ? 'success' : 'error',
                                                                                        message: response.msg
                                                                                    });
                                                                                    setOpenSnackbar(true);
                                                                                    stopLoader();
                                                                                });
                                                                        }}
                                                                        margin="0"
                                                                    />
                                                                    <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                                                                </div>
                                                            </div>
                                                            <div>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>

                                            <SnackbarWrapper
                                                visible={openSnackBar}
                                                onClose={() => setOpenSnackbar(false)}
                                                variant={snackbarData.variant}
                                                message={snackbarData.message}
                                            />




                                            {usersList && usersList.metaData && (usersList.metaData.total !== 0 || !!searchString) ?
                                                <div className="table-responsive">
                                                    <CustomTable
                                                        rows={usersList && usersList.data ? usersList.data : []}
                                                        rowsPerPage={tableSize.label}
                                                        showProfile={true}
                                                        isEvent={true}
                                                        columns={LAYOUTS.contactsOptionsTable}
                                                        activePage={1}
                                                        sortByKey={sortByKey}
                                                        sortType={sortType}
                                                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                                        emptyDataMessage={MESSAGES.noRecordsFound}
                                                        TABLE_ARROW_UP={TABLE_ARROW_UP}
                                                        ROLE_LABELS={ROLE_LABELS}
                                                        EDIT_ICON={EDIT_ICON}
                                                        DELETE_ICON={DELETE_ICON}
                                                        onRowClick={(id) => {
                                                            // history.push(`${ROUTES.USER_DETAIL}?id=${id}`)
                                                        }}
                                                        onStatusChange={(value, id) => {
                                                            // revertEmergencyAccess();
                                                            if (!value) {
                                                                setPopupData({
                                                                    popupContent: STRINGS.ARE_YOU_SURE_YOU_WANT_TO_REVERT_EMERGENCY_ACCESS,
                                                                    popupTitle: STRINGS.REVERT_EMERGENCY_ACCESS,
                                                                    onConfirmation: () => {
                                                                        setPopVisible(false);
                                                                        startLoader();
                                                                        revertEmergencyAccess({
                                                                            championshipId: championshipData._id,
                                                                            userId: id
                                                                        },
                                                                            (response) => {
                                                                                setSnackBarData({
                                                                                    variant: response.status ? 'success' : 'error',
                                                                                    message: response.msg
                                                                                });
                                                                                fetchUsersList({
                                                                                    searchString: searchString,
                                                                                    filterRole: 0,
                                                                                    filterStatus: 0,
                                                                                    sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                                                    emergencyAccess: true,
                                                                                    sortType: sortType,
                                                                                    limit: tableSize.label,
                                                                                    page: activePage
                                                                                }, () => {
                                                                                    stopLoader();
                                                                                },
                                                                                    (response) => {
                                                                                        setSnackBarData({
                                                                                            variant: response.status ? 'success' : 'error',
                                                                                            message: response.msg
                                                                                        });
                                                                                        setOpenSnackbar(true);
                                                                                        stopLoader();
                                                                                    });
                                                                                stopLoader();
                                                                                setOpenSnackbar(true);
                                                                            }, (response) => { })
                                                                    }
                                                                })
                                                                setPopVisible(true);
                                                            }
                                                        }}
                                                        onEditClick={(id, index) => { history.push(`${ROUTES.EVENTS_DETAILS}?id=${id}`) }}
                                                        setSortKey={(key) => {
                                                            if (sortByKey === key) {
                                                                setSortType(-1 * sortType);
                                                            }
                                                            else {
                                                                setSortType(1)
                                                            }
                                                            setSortByKey(key)
                                                        }}
                                                    />

                                                    {usersList && usersList.data && !!usersList.data.length && (usersList.metaData.total > TABLE_SIZE_ITEMS[0].label)
                                                        && <div className="text-center px-3">
                                                            <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                                                <CustomDropdown
                                                                    labelText={LABELS.noOfRows}
                                                                    dataItems={TABLE_SIZE_ITEMS}
                                                                    value={tableSize.value}
                                                                    dropDataSet={(value) => {
                                                                        setActivePage(1);
                                                                        setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                                    }}
                                                                />
                                                                {(emergencyContacts && emergencyContacts.metaData && emergencyContacts.metaData.total > tableSize.label)
                                                                    ? <CustomPagination
                                                                        displayingString={STRINGS.DISPLAYING}
                                                                        outOfString={STRINGS.OUT_OF}
                                                                        totalPages={emergencyContacts.metaData.total}
                                                                        itemsCount={emergencyContacts.data.length}
                                                                        pageCount={LIMITS.paginationPageCount}
                                                                        limit={tableSize.label}
                                                                        currentPage={activePage}
                                                                        nextArrow={NEXT_ARROW}
                                                                        prevArrow={PREV_ARROW}
                                                                        onPageChange={(selectedPage) => {
                                                                            let page = selectedPage.selected + 1;
                                                                            document.getElementById('screen-content').scroll(
                                                                                {
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                    behavior: 'smooth'
                                                                                }
                                                                            )
                                                                            setActivePage(page);

                                                                        }}
                                                                        activePageStyle={ACTIVE_PAGE_STYLE}
                                                                    />
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>}
                                                </div>
                                                :
                                                <div className={'row py-4 py-md-4'}>
                                                    <div className="col-md-8 offset-md-2 text-center">
                                                        <figure className="mb-3 mb-md-4">
                                                            <img src={EMPTY_EMERGENCY_ICON} alt={'dashboard-logo'} className="img-fluid" />
                                                        </figure>
                                                        <h5 className="mb-3">{STRINGS.EMPTY_EMERGENCY_HEADING}</h5>
                                                        <p>{STRINGS.EMPTY_EMERGENCY_DESCRIPTION}</p>

                                                        <div className="group_btn mt-3 mt-md-4">
                                                            <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => { setShowList(true) }}>{STRINGS.ADD_EMERGENCY_USERS}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </div >

    );
}