export const CREATE_EVENT = 'CREATE_EVENT';
export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST';
export const SAVE_EVENT_LIST = 'SAVE_EVENT_LIST';
export const DELETE_EVENT = 'DELETE_EVENT';
export const FETCH_EVENT_DETAILS = 'FETCH_EVENT_DETAILS';
export const SAVE_EVENT_DETAILS = 'SAVE_EVENT_DETAILS';
export const FETCH_ATTENDEES_LIST = 'FETCH_ATTENDEES_LIST';
export const SAVE_ATTENDEES_LIST = 'SAVE_ATTENDEES_LIST';
export const UPDATE_EVENT = 'UPDATE_EVENT';

export const createEvent = (data, success, failure) => {
    return {
        type: CREATE_EVENT,
        data,
        success,
        failure
    }
};

export const updateEvent = (data, success, failure) => {
    return {
        type: UPDATE_EVENT,
        data,
        success,
        failure
    }
}

export const fetchEventsList = (data, success, failure) => {
    return {
        type: FETCH_EVENT_LIST,
        data,
        success,
        failure
    }
};

export const saveEventList = (data) => {
    return {
        type: SAVE_EVENT_LIST,
        data,
    }
};

export const deleteEvent = (data, success, failure) => {
    return {
        type: DELETE_EVENT,
        data,
        success,
        failure
    }
};

export const fetchEventDetails = (id, success, failure) => {
    return {
        type: FETCH_EVENT_DETAILS,
        id,
        success,
        failure
    }
};

export const saveEventDetails = (data) => {
    return {
        type: SAVE_EVENT_DETAILS,
        data
    }
};

export const fetchAttendeesList = (data, success, failure) => {
    return {
        type: FETCH_ATTENDEES_LIST,
        data,
        success,
        failure
    }
};

export const saveAttendeesList = data => {
    return {
        type: SAVE_ATTENDEES_LIST,
        data
    }
};
