import React, { useState, useEffect } from "react";
import { TextField } from '@material-ui/core';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const {
    LAYOUTS,
    LIMITS,
    NEXT_ARROW,
    PREV_ARROW,
    LABELS,
    MESSAGES,
    SEARCH_ICON,
    TABLE_ARROW_DOWN,
    TABLE_ARROW_UP,
    EMERGENCY_ROLE_ITEMS,
    USER_STATUS_ITEMS,
    ROLE_LABELS,
    TABLE_SIZE_ITEMS,
    INFO_ICON,
    ACTIVE_PAGE_STYLE,
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)

export const ContactOptions = ({
    fetchUsersList,
    usersList,
    startLoader,
    stopLoader,
    onStatusChange,
    addContactList,
    championshipData,
    updateActivationStatus,
    updateApprovalStatus,
}) => {
    const [activePage, setActivePage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [addUserVisible, setAddUserVisible] = useState(false);
    const [credentials, setCredentials] = useState({
        name: '',
        email: '',
        role: 0
    })
    const [selectedRole, setSelectedRole] = useState(EMERGENCY_ROLE_ITEMS[0]);
    const [selectedStatus, setSelectedStatus] = useState(USER_STATUS_ITEMS[0]);
    const [lastActivePage, setLastActivePage] = useState(1);
    const [sortByKey, setSortByKey] = useState('date');
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortType, setSortType] = useState(-1);
    const [popupVisible, setPopVisible] = useState(false);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [uploader, openUploader] = useState(false);
    const [formData, setFormData] = useState('');
    const [hasFormData, setHasFormData] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    })
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    });

    useEffect(() => {
        fetchUsersList({
            searchString: searchString,
            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value + 1,
            filterStatus: selectedStatus.value - 1,
            sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
            emergencyAccess: false,
            sortType: sortType,
            limit: tableSize.label,
            page: activePage
        }, () => {
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
        setActivePage(1);
    }, [selectedRole, sortByKey, sortType, selectedRole.value, selectedStatus.value, tableSize.label, searchString]);



    return (
        <React.Fragment>

            <DecisionPopup
                modalVisibility={popupVisible}
                dialogContent={popupData.popupContent}
                dialogTitle={popupData.popupTitle}
                confirmButtonTitle={popupData.confirmButton}
                rejectButtonTitle={popupData.rejectButton}
                toggleDialogModal={() => setPopVisible(!popupVisible)}
                onConfirmation={popupData.onConfirmation}
                onRejection={() => setPopVisible(false)}
            />

            <React.Fragment>

                <div className="table_filter">
                    <div className="form-row table-listing">
                        <div className="col-md-3">
                            <div className='table-search'>
                                <TextField
                                    className='text-field'
                                    label={LABELS.search}
                                    type="text"
                                    value={searchString}
                                    placeholder={STRINGS.SEARCH}
                                    onChange={e => {
                                        fetchUsersList({
                                            searchString: e.target.value.trim(),
                                            filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                            filterStatus: selectedStatus.value - 1,
                                            sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                            emergencyAccess: false,
                                            sortType: sortType,
                                            limit: tableSize.label,
                                            page: activePage
                                        }, () => {
                                            stopLoader();
                                        },
                                            (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                setOpenSnackbar(true);
                                                stopLoader();
                                            });
                                        if (e.target.value === '') {
                                            setActivePage(lastActivePage);
                                            setSearchString(e.target.value);
                                        }
                                        else {
                                            setActivePage(1);
                                            setSearchString((e.target.value));
                                        }
                                    }}
                                    margin="0"
                                />
                                <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                            </div>
                        </div>

                        <div className={'col-md-9'}>
                            <div className="d-flex justify-content-between justify-content-md-end select_multi">
                                <CustomDropdown
                                    labelText={LABELS.role}
                                    dataItems={EMERGENCY_ROLE_ITEMS}
                                    value={selectedRole.value}
                                    dropDataSet={(value) => {
                                        setSelectedRole(EMERGENCY_ROLE_ITEMS[value - 1]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <SnackbarWrapper
                    visible={openSnackBar}
                    onClose={() => setOpenSnackbar(false)}
                    variant={snackbarData.variant}
                    message={snackbarData.message}
                />

                <div className="table-responsive">
                    <CustomTable
                        rows={usersList && usersList.data ? usersList.data : []}
                        rowsPerPage={tableSize.label}
                        showProfile={true}
                        columns={LAYOUTS.contactsOptionsTable}
                        activePage={1}
                        sortByKey={sortByKey}
                        sortType={sortType}
                        addContactList={addContactList}
                        emptyDataMessage={MESSAGES.noRecordsFound}
                        TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                        TABLE_ARROW_UP={TABLE_ARROW_UP}
                        ROLE_LABELS={ROLE_LABELS}
                        INFO_ICON={INFO_ICON}
                        onRowClick={(id) => { }}
                        onStatusChange={(value, id) => { onStatusChange(value, id) }}
                        setSortKey={(key) => {
                            if (sortByKey === key) {
                                setSortType(-1 * sortType);
                            }
                            else {
                                setSortType(1)
                            }
                            setSortByKey(key)
                        }}
                    />
                    {usersList && usersList.data && !!usersList.data.length && (usersList.metaData.total > TABLE_SIZE_ITEMS[0].label)
                        && <div className="text-center px-3">
                            <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                <CustomDropdown
                                    labelText={LABELS.noOfRows}
                                    dataItems={TABLE_SIZE_ITEMS}
                                    value={tableSize.value}
                                    dropDataSet={(value) => {
                                        setActivePage(1);
                                        setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                    }}
                                />
                                {
                                    (usersList && usersList.metaData && usersList.metaData.total > tableSize.label) ? <CustomPagination
                                        displayingString={STRINGS.DISPLAYING}
                                        outOfString={STRINGS.OUT_OF}
                                        totalPages={usersList.metaData.total}
                                        itemsCount={usersList.data.length}
                                        pageCount={LIMITS.paginationPageCount}
                                        limit={tableSize.label}
                                        currentPage={activePage}
                                        nextArrow={NEXT_ARROW}
                                        emptyDataMessage={MESSAGES.noRecordsFound}
                                        prevArrow={PREV_ARROW}
                                        onPageChange={(selectedPage) => {
                                            let page = selectedPage.selected + 1;
                                            document.getElementById('screen-content').scroll(
                                                {
                                                    top: 0,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                }
                                            )
                                            setActivePage(page);
                                            setLastActivePage(page);
                                            fetchUsersList({
                                                filterRole: selectedRole.value === 1 ? 0 : selectedRole.value,
                                                filterStatus: selectedStatus.value - 1,
                                                sortKey: sortByKey === 'date' ? 'createdAt' : sortByKey,
                                                sortType: sortType,
                                                emergencyAccess: false,
                                                limit: tableSize.label,
                                                page: page
                                            }, () => {
                                                stopLoader();
                                            },
                                                (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    setOpenSnackbar(true);
                                                    stopLoader();
                                                });
                                        }}
                                        activePageStyle={ACTIVE_PAGE_STYLE}
                                    />
                                        :
                                        null
                                }
                            </div>
                        </div>}
                </div>
            </React.Fragment>

        </React.Fragment >
    );
}