import React, { useState, useEffect } from "react";
import './style.scss';
import { TextField } from '@material-ui/core';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { InstructionForm } = require(`./instruction-form`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { SearchableDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/searchable-dropdown`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const {
    LABELS,
    LAYOUTS,
    MESSAGES,
    SEARCH_ICON,
    ROLE_LABELS,
    TABLE_ARROW_DOWN,
    TABLE_SIZE_ITEMS,
    TABLE_ARROW_UP,
    DELETE_ICON,
    SUB_ADMIN_PLATFORM,
    TEST_PAGE_TAB_VALUES,
    EMPTY_TEST_ICON,
    EDIT_ICON,
    ROUTES,
    NEXT_ARROW,
    PREV_ARROW,
    USER_ROLE_ITEMS,
    LIMITS,
    ACTIVE_PAGE_STYLE
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);
// const { AddTest } = require(`./AddTest`);
const { TestQuestionTable } = require(`../../../../../../components/sef/molecules/test-question-table`);
const { CreateTest } = require(`./tests-form`)
export const Tests = ({
    fetchTestList,
    championshipData,
    fetchQuestionList,
    fetchInstruction,
    testList,
    history,
    testInstruction,
    platformType,
    editInstruction,
    saveInstruction,
    setAddTest,
    setAddButtonData,
    addTest, testTab,
    stopLoader,
    instruction,
    updateTest,
    setEditInstruction,
    startLoader,
    goInstruction,
    fetchChampionships,
    createTest,
    fetchUserTypes,
    userTypeList,
}) => {
    const [activePage, setActivePage] = useState(1);
    const [selectedChampionship, setSelectedChampionship] = useState(null);
    const [tabValue, setTabValue] = useState(TEST_PAGE_TAB_VALUES.questionsTab);
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortType, setSortType] = useState(-1);
    const [roleItems, setRoleItems] = useState([]);
    const [CHAMPIONSHIPS_ITEMS, setChampionshipItems] = useState([]);
    const [champList, setChampList] = useState(null)
    const [sortByKey, setSortByKey] = useState('question');
    const [searchString, setSearchString] = useState('');
    const [selectedRole, setRoleSelected] = useState();
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [data, setData] = useState()
    const [formVisible, setFormVisible] = useState(false);
    const [onEdit, setOnEdit] = useState({ visible: false, id: '' });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        onConfirmation: () => { }
    });

    useEffect(() => {
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
    }, [])

    useEffect(() => {
        fetchChampionships({
            searchString: '',
            sortKey: 'name',
            sortType: 1,
            limit: 0,
            page: 1
        }, (response) => {
            let items = [];
            response.data.map((item, index) => {
                items.push({
                    id: item._id,
                    label: item.name,
                    value: index + 1
                });
                return null;
            });
            setChampList([...response.data])
            setChampionshipItems([...items])
            stopLoader()
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
    }, []);

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    React.useEffect(() => {
        startLoader()
        if (!!CHAMPIONSHIPS_ITEMS.length) {
            fetchTestList({
                championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : CHAMPIONSHIPS_ITEMS && CHAMPIONSHIPS_ITEMS[0] && CHAMPIONSHIPS_ITEMS[0].id
            }, () => {
                stopLoader();
            }, (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                stopLoader();
                setOpenSnackbar(true);
            })
        }
    }, [CHAMPIONSHIPS_ITEMS.length])

    React.useEffect(() => {
        setAddButtonData((platformType === SUB_ADMIN_PLATFORM && testTab && (testList && testList.data && (testList.data.length !== 0 || !!searchString) && !instruction)),
            () => setAddTest(true),
            STRINGS.ADD_TEST)
    }, [testList && testList.data.length])

    return (
        <React.Fragment>
            {instruction && <InstructionForm
                SUB_ADMIN_PLATFORM={SUB_ADMIN_PLATFORM}
                platformType={platformType}
                testInstruction={testInstruction}
                fetchInstruction={fetchInstruction}
                championshipData={championshipData}
                onSubmit={(formData) => {
                    saveInstruction({
                        championshipId: championshipData._id,
                        instructions: formData
                    }, (response) => {
                        goInstruction(false);
                        setEditInstruction(false);
                        setSnackBarData({
                            variant: response.status ? 'success' : 'error',
                            message: response.msg
                        });
                        stopLoader();
                        setOpenSnackbar(true);
                        if (editInstruction) {
                            fetchTestList({
                                championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : CHAMPIONSHIPS_ITEMS && CHAMPIONSHIPS_ITEMS[0] && CHAMPIONSHIPS_ITEMS[0].id
                            }, () => {
                                stopLoader();
                            }, (response) => {
                                setSnackBarData({
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg
                                });
                                stopLoader();
                                setOpenSnackbar(true);
                            })
                        }
                        else {
                            goInstruction(false);
                            setEditInstruction(false);
                        }
                    }, (response) => {
                        setSnackBarData({
                            variant: response.status ? 'success' : 'error',
                            message: response.msg
                        });
                        stopLoader();
                        setOpenSnackbar(true);
                    })
                }}
                onCancel={() => {
                    goInstruction(false);
                    setEditInstruction(false);
                }}
            />}
            {/* <div className="test-screen w-100 p-4" style={{ justifyContent: instruction ? "flex-start" : 'center' }}> */}
            {

                (!!testTab && !addTest && testList && testList.data.length <= 0) && (platformType === SUB_ADMIN_PLATFORM) &&
                <div className={'row py-4 py-md-4'}>
                    <div className="col-md-8 offset-md-2 text-center">
                        <figure className="mb-3 mb-md-4">
                            <img src={EMPTY_TEST_ICON} alt={'dashboard-logo'} className="img-fluid" />
                        </figure>
                        <h5 className="mb-3">{STRINGS.EMPTY_TEST_HEADING}</h5>
                        <p>{STRINGS.EMPTY_TEST_DESCRIPTION}</p>
                        <div className="group_btn mt-3 mt-md-4">
                            <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => {
                                setAddTest(true)
                            }}>{STRINGS.ADD_TEST}</button>
                        </div>
                    </div>
                </div>
            }
            {
                <div className={'card-body p-0 pt-3'}>
                    <React.Fragment>
                        {((platformType === SUB_ADMIN_PLATFORM && addTest && !instruction) || onEdit.visible)
                            &&
                            <CreateTest
                                onEdit={onEdit.visible}
                                id={onEdit.id}
                                data={data}
                                userTypeList={userTypeList}
                                fetchUserTypes={fetchUserTypes}
                                createTest={(data, documentsRequired) => {
                                    startLoader()
                                    createTest({
                                        championshipId: championshipData._id,
                                        userRole: data.testType.value,
                                        totalQuestions: Number.parseInt(data.noOfQuestion),
                                        documentsRequired:documentsRequired
                                    }, (response) => {
                                        setSnackBarData({
                                            variant: response.status ? 'success' : 'error',
                                            message: response.msg
                                        });
                                        setAddTest(false);
                                        setOnEdit(false);
                                        fetchTestList({
                                            championshipId: championshipData._id
                                        }, () => {
                                            stopLoader();
                                            setOpenSnackbar(true);
                                        }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            setOnEdit({ visible: false });
                                            setAddTest(false);
                                            stopLoader();
                                            setOpenSnackbar(true);
                                        });
                                    }, (response) => {
                                        setSnackBarData({
                                            variant: response.status ? 'success' : 'error',
                                            message: response.msg
                                        });
                                        stopLoader();
                                        setOpenSnackbar(true);
                                    });
                                }
                                }
                                onCancel={() => {
                                    setAddTest(false);
                                    setOnEdit({ visible: false });
                                    setAddButtonData((platformType === SUB_ADMIN_PLATFORM && testTab && (testList && testList.data && (testList.data.length !== 0 || !!searchString) && !instruction)),
                                        () => setAddTest(true),
                                        STRINGS.ADD_TEST)
                                }}
                            />
                        }
                        <SnackbarWrapper
                            visible={openSnackBar}
                            onClose={() => setOpenSnackbar(false)}
                            variant={snackbarData.variant}
                            message={snackbarData.message}
                        />
                        {platformType !== SUB_ADMIN_PLATFORM && !addTest && !instruction && <div className="table_filter">
                            <React.Fragment>
                                <div className="form-row table-listing">
                                    {<div className={'col-md-12'}>
                                        <div className="d-flex justify-content-between justify-content-md-end select_multi mt-2 mt-md-0 align-items-center">

                                            <React.Fragment>
                                                <div className="d-flex align-items-center flex-column flex-sm-row">
                                                    <label>{'Select Championship: '}</label>
                                                    <SearchableDropdown
                                                        placeholder={STRINGS.SELECT_CHAMPIONSHIP}
                                                        options={CHAMPIONSHIPS_ITEMS}
                                                        isSearchable={true}
                                                        widthStyle={'s_champ_w'}
                                                        value={selectedChampionship}
                                                        onValueChange={(value) => {
                                                            setSelectedChampionship(CHAMPIONSHIPS_ITEMS[value - 1]);
                                                            fetchTestList({
                                                                championshipId: champList[value.value - 1]._id
                                                            }, () => { }, (response) => {
                                                                setSnackBarData({
                                                                    variant: response.status ? 'success' : 'error',
                                                                    message: response.msg
                                                                });
                                                                stopLoader();
                                                                setOpenSnackbar(true);
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    </div>}
                                </div>
                            </React.Fragment>
                        </div>}
                        {((testList && testList.data.length > 0 && !addTest && !instruction
                            && !onEdit.visible) || (platformType !== SUB_ADMIN_PLATFORM)) && < div className="table-responsive">
                                <CustomTable
                                    rows={testList && testList.data ? testList.data : []}
                                    rowsPerPage={tableSize.label}
                                    showProfile={false}
                                    roleItems={roleItems}
                                    columns={platformType !== SUB_ADMIN_PLATFORM ? LAYOUTS.SuperAdminTestTable : LAYOUTS.TestTable}
                                    activePage={1}
                                    sortByKey={sortByKey}
                                    sortType={sortType}
                                    TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                    emptyDataMessage={MESSAGES.noRecordsFound}
                                    TABLE_ARROW_UP={TABLE_ARROW_UP}
                                    ROLE_LABELS={ROLE_LABELS}
                                    EDIT_ICON={EDIT_ICON}
                                    DELETE_ICON={DELETE_ICON}
                                    onRowClick={(id) => {
                                        if (platformType !== SUB_ADMIN_PLATFORM) {
                                            history.push(`${ROUTES.EVENTS_DETAILS}?id=${id}`)
                                        }
                                        else
                                            return;
                                    }}
                                    onDeleteClick={(id) => { }}
                                    onEditClick={(data) => {
                                        setData(data);
                                        setOnEdit({ visible: true, id: data._id })
                                    }}
                                    setSortKey={(key) => {
                                        if (sortByKey === key) {
                                            setSortType(-1 * sortType);
                                        }
                                        else {
                                            setSortType(1)
                                        }
                                        setSortByKey(key)
                                    }}
                                    onStatusChange={(value, id) => {
                                        updateTest({
                                            championshipId: championshipData._id,
                                            userRole: id,
                                            status: value ? 1 : 0
                                        },
                                            (response) => {
                                                fetchTestList({
                                                    championshipId: championshipData._id
                                                });
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            }
                                        )
                                    }}
                                />
                            </div>}
                    </React.Fragment>
                </div>
            }
        </React.Fragment >
    );
}

