import {
  connect
} from "react-redux";
import {
  AmbassadorsList
} from "./screen";
const {
  defaultConfig: {
    PLATFORM
  }
} = require(`../../../../../../config/default`);
const {
  stopLoader,
  startLoader,
  fetchUsersList,
  revertAmbassadorAccess,
  fetchAmbassadorsList,
  updateAmbassadorAccess
} = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = state => {
  return {
    platformType: state.CommonReducer.platformType,
    ambassadorsList: state.UserReducer.ambassadorsList,
    usersList: state.UserReducer.usersList,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    startLoader: () => dispatch(startLoader()),
    stopLoader: () => dispatch(stopLoader()),
    fetchAmbassadorsList: (data, success, failure) =>
      dispatch(fetchAmbassadorsList(data, success, failure)),
    revertAmbassadorAccess: (data, success, failure) =>
      dispatch(revertAmbassadorAccess(data, success, failure)),
    updateAmbassadorAccess: data => dispatch(updateAmbassadorAccess(data)),

    fetchUsersList: (data, success, failure) => dispatch(fetchUsersList(data, success, failure)),
  };
};
export const AmbassadorsListScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(AmbassadorsList);