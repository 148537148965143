export const STRINGS = {
  EMAIL_INPUT_NAME: 'email',
  EMAIL_REQUIRED: 'Email is required.',
  PASSWORD_INPUT_NAME: 'password',
  NAME_INPUT: 'name',
  FIRST_NAME_INPUT: 'firstName',
  LAST_NAME_INPUT: 'lastName',
  COUNTRY_INPUT: 'country',
  CITY_INPUT: 'city',
  CIRCUIT_INPUT: 'circuit',
  END_DATE_NAME: 'end-date',
  START_TIME_NAME: 'start-time',
  START_DATE_NAME: 'start-date',
  CHAMPIONSHIP_INPUT: 'championship-name',
  EVENT_INPUT: 'event-name',
  CHAMPIONSHIP_DETAILS: 'details',
  EVENT_DETAILS: 'event-details',
  USER_ROLE: 'role',
  PASSWORD_REQUIRED: 'Password is required.',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  LOGIN: 'Login',
  PASSWORD_LABEL: 'Password',
  EMAIL_LABEL: 'Email',
  NAME_LABEL: 'Name',
  FIRST_NAME_LABEL: 'First Name',
  LAST_NAME_LABEL: 'Last Name',
  COUNTRY: 'Country',
  CITY: 'City',
  CIRCUIT_NAME: 'Circuit Name',
  CHAMPIONSHIP_DESCRIPTION_LABEL: 'Description',
  EVENT_DESCRIPTION_LABEL: 'Description',
  NAME_REQUIRED: 'Name is required.',
  USER_ROLE_LABEL: 'Role',
  ROLE_REQUIRED: 'User role is required.',
  CHAMPIONSHIP_NAME_REQUIRED: 'Championship name is required.',
  EVENT_NAME_REQUIRED: 'Event name is required.',
  COUNTRY_REQUIRED: 'Country is required.',
  CITY_REQUIRED: 'City is required.',
  CIRCUIT_NAME_REQUIRED: 'Circuit name is required.',
  CHAMPIONSHIP_DESCRIPTION_REQUIRED: 'Championship description is required.',
  EVENT_DESCRIPTION_REQUIRED: 'Event description is required.',
  START_TIME_REQUIRED: 'Time is required.',
  VALUE_CANNOT_BE_ONLY_SPACES: 'Value cannot be only spaces.',
  NAME_PLACEHOLDER: 'Enter user name',
  EMAIL_PLACEHOLDER: 'Eg: example@gmail.com',
  PASSWORD_PLACEHOLDER: 'Enter your password',
  CHAMPIONSHIP_NAME_PLACEHOLDER: 'Enter championship name',
  COUNTRY_NAME_PLACEHOLDER: 'E.g. United Kingdom',
  CITY_NAME_PLACEHOLDER: 'E.g. Lianelli',
  CIRCUIT_NAME_PLACEHOLDER: 'E.g. Pembrey Circuit',
  EVENT_NAME_PLACEHOLDER: 'Enter event name',
  CHAMPIONSHIP_DETAILS_PLACEHOLDER: `Type description here..`,
  EVENT_DETAILS_PLACEHOLDER: `Type description here..`,
  USER_ROLE_PLACEHOLDER: 'Select Role',
  KEEP_ME_SIGNED_IN_CHECKBOX_NAME: 'Remember me',
  KEEP_ME_SIGNED_IN: 'Keep me signed in',
  FORGOT_PASSWORD: 'Forgot Password?',
  BUTTON_LABEL_LOGIN: 'Log In',
  ADD_USER: 'Add User',
  REMEMBER_ME: 'Remember me',
  EMAIL_INVALID: 'Email is invalid.',
  END_DATE_MUST_BE_GREATER: 'End date must be greater than start date.',
  SEND_EMAIL: 'Send',
  LOG_IN: 'Log In?',
  FORGOT_LOGIN: 'Log in',
  CHECK_YOUR_MAIL: 'Check your mail!',
  EMAIL_RESPONSE_MESSAGE: 'We just emailed you a reset password link on your registered email address.',
  SHARE_EMAIL_MESSAGE: 'Enter your email address, We will send you a link to reset your password.',
  LOGOUT: 'Logout',
  DASHBOARD: 'Dashboard',
  HOME: 'Home',
  CHAMPIONSHIPS: 'Championships',
  TESTS: 'Tests',
  CHAT: 'Chat',
  EVENT: 'Events',
  LOGOUT_CONTENT: 'Are you sure you want to logout?',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  NAME: 'Name',
  REQUEST_DATE: 'Request Date',
  ROLE: 'Role',
  ACTION: 'Action',
  NEWS_FEEDS: 'News Feeds',
  DISPLAYING: 'Displaying',
  OUT_OF: 'Out of',
  APPROVAL_REQUESTS: 'Requests -',
  ALL: 'All',
  DRIVERS: 'Driver',
  MARSHAL: 'Marshal',
  MEDIA: 'Media',
  PIT_CREW: 'Pitcrew',
  SECURITY: 'Security',
  NO_RECORDS_FOUND: 'No matched records found',
  NO_OF_ROWS: 'No. of Rows',
  ACCEPT_USER_CONTENT: 'Are you sure you want to approve this user?',
  ACCEPT_USER: 'Approve User',
  REJECT_USER_CONTENT: 'Are you sure you want to reject this user?',
  REJECT_USER: 'Reject User',
  USERS: 'Users',
  REQUESTS: 'Requests',
  EUROPEAN_SUPERKART: 'European Superkart',
  DASHBOARD_PAGE_TITLE: 'SEF Dashboard',
  FEEDS_PAGE_TITLE: 'SEF Feeds',
  USERS_PAGE_TITLE: 'SEF Users',
  REQUESTS_PAGE_TITLE: 'SEF Users Requests',
  TESTS_PAGE_TITLE: 'SEF Tests',
  CHATS_PAGE_TITLE: 'SEF Chats',
  EVENTS_PAGE_TITLE: 'SEF Events',
  FORGOT_PAGE_TITLE: 'SEF Forgot Password',
  LOGIN_PAGE_TITLE: 'SEF Login',
  USER_DETAIL_PAGE_TITLE: 'SEF User Details',
  CHAMPIONSHIP_LIST_TITLE: 'SEF Championship List',
  CHAMPIONSHIP_DETAILS_TITLE: 'SEF Championship Details',
  USERS_TITLE: 'Users -',
  EVENT_TITLE: 'Events ',
  EMERGENCY_CONTACTS: 'Emergency Contacts -',
  STATUS: 'Status',
  ACTIVE: 'Active',
  REGISTERED: 'Registered',
  REQUESTED: 'Requested',
  INVITED: 'Invited',
  IMPORT_CSV: 'Import CSV',
  FILE_TYPE_REJECTED: 'Selected file format is not supported! Please choose .csv file format',
  PREPARING: 'preparing',
  DONE: 'done',
  REJECTED_FILE_TYPE: 'rejected_file_type',
  RESEND_INVITE: 'Resend Invite',
  OKAY: 'Okay',
  REMINDER_SENT: 'Reminder Sent!',
  USERS_LIST: "User's List",
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  DATE: 'Date',
  START_TIME: 'Time',
  CHAMPIONSHIP_NAME: 'Championship Name',
  EVENT_NAME: 'Event Name',
  START_DATE_REQUIRED: 'Start date is required.',
  END_DATE_REQUIRED: 'End date is required.',
  ADMIN_NAME_REQUIRED: 'Admin name is required.',
  CHAMPIONSHIP_LIST: 'Championship List',
  DESCRIPTION: 'Description',
  CHAMPIONSHIP: 'Championship',
  ATTEMPT: 'Attempt(s)',
  PERCENTAGE: 'Percentage',
  ADMIN_NAME_PLACEHOLDER: 'Enter Admin name',
  ADMIN_EMAIL_PLACEHOLDER: 'Enter Admin email',
  EVENT_LIST: 'Events List',
  EMPTY_TEST_HEADING: "It seems you haven’t added any test.",
  EMPTY_CREATE_TEST_HEADING: "It seems you haven’t created any test.",
  EMPTY_TEST_DESCRIPTION: `You can add test by clicking on the button below.`,
  ADD_TEST: 'Add Test',
  TEST_INSTRUCTION: "instruction",
  TEST_INSTRUCTION_PLACEHOLDER: "Type instruction here",
  TEST_INSTRUCTION_LABEL: "Instructions",
  TEST_TITLE: "Test",
  TEST_INSTRUCTION_REQUIRED: "Test instruction is required.",
  USER_TYPE: "user_type",
  QUESTION_TYPE: "question_type",
  USER_TYPE_LABEL: "User Type",
  QUESTION_TYPE_LABEL: "Question Type",
  QUESTION_TITLE: "question_title",
  QUESTION_TITLE_LABEL: "Question Title",
  TYPE_HERE: "Type here...",
  RIGHT_ANSWER_OPTIONS: "right answer option",
  ANSWER_OPTIONS: "answer option",
  TEST_TYPE: "Test Type",
  NO_OF_QUESTION: "No. of Question(s)",
  ANSWER_TYPE: "answer_type",
  QUESTION_TIME: 'question_time',
  QUESTION_TIME_LABEL: 'Question Time ',
  MINUTE_INPUT: 'question_minute',
  SECONDS_INPUT: 'question_seconds',
  FILE: 'file',
  ANSWER_TYPE_LABEL: "Answer Type",
  USER_TYPE_REQUIRED: "User type is required",
  QUESTION_TYPE_REQUIRED: "Question type is required.",
  ANSWER_TYPE_REQUIRED: "Answer type is required.",
  QUESTION_TITLE_REQUIRED: "Question title is required.",
  ANSWER_OPTION_LIST: "answer",
  ANSWER_LIST: "answer_list",
  RIGHT_OPTION_LIST: "right_option_list",
  EMERGENCY_ACCESS: 'Emergency Access',
  AMBASSADORS: 'Ambassadors',
  ACCESS: 'Access',
  LOG_OUT: 'Log Out',
  ATTENDEES: 'Attendees',
  SUCCESS: 'success',
  ERROR: 'error',
  SEARCH: 'Search',
  USER_TYPE_COLON: 'User Type: ',
  LOCATION: 'Location',
  PEOPLE_ARE_ATTENDING: 'Attendee(s)',
  SEE_ALL: 'See all',
  ADD_EVENT: 'Add Event',
  ADD_QUESTION: 'Add Question',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: 'Are you sure you want to delete this event?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION: 'Are you sure you want to delete this question?',
  DELETE: 'Delete Event',
  DELETE_QUESTION: 'Delete Question',
  EMPTY_EVENT_HEADING: `It seems you haven't added any event yet.`,
  EMPTY_EVENT_DESCRIPTION: 'You can add events by clicking on the button below.',
  EMPTY_QUESTIONS_HEADING: `It seems you haven't added any question yet.`,
  EMPTY_QUESTION_DESCRIPTION: `You can add questions by clicking on the button below.`,
  SELECT_CHAMPIONSHIP: 'Select Championship: ',
  ADD_EMERGENCY_USERS: 'Add Emergency Users',
  EMPTY_EMERGENCY_HEADING: `It seems you haven't given emergency access to anyone yet.`,
  EMPTY_EMERGENCY_DESCRIPTION: 'You can add emergency contacts by clicking on the button below.',
  EMPTY_AMBASSADOR_HEADING: `It seems you haven't added any ambassador.`,
  EMPTY_AMBASSADOR_NO_USERS_DESCRIPTION: "First you have to add Drivers in Users section, then you'll be able to add Ambassadors.",
  EMPTY_AMBASSADOR_DESCRIPTION: 'You can add ambassador by clicking on the below button.',
  ADD_AMBASSADORS: 'Generate List',
  SAVE: 'Save',
  NA: 'NA',
  ADDRESS: 'Address',
  NATIONALITY: 'Nationality',
  DOB: 'D.O.B.',
  PHONE_NO: 'Phone No.',
  BLOOD_GROUP: 'Blood Group',
  ANY_KNOWN_ALLERGIES: 'Any known blood allergies',
  RELEVANT_PAST_HISTORY: 'Any relevant past history (including concussion)',
  ISSUES_UNDER_TREATMENT: 'Any issue under treatment',
  EXISTING_TUE: 'Any existing therapeutic Use Exemption (T.U.E)',
  LAST_TETANUS: 'Year of last Tetanus injection',
  RELATIONSHIP: 'Relationship',
  CONTACT_NUMBER: 'Contact Number',
  TEAM_NAME: 'Team Name',
  ASN_CATEGORY: 'ASN/Category',
  FIA_CATEGORIZATION: 'FIA Categorization',
  LICENSE_NUMBER: 'Licence Number',
  VEHICLE_REGISTRATION_NUMBER: 'Vehicle Registration Number',
  INSURANCE_POLICY_NUMBER: 'Insurance Policy Number',
  MEDICAL_DETAILS: 'Medical Details',
  EMERGENCY_DETAILS: 'Emergency Details',
  VEHICLE_DETAILS: 'Vehicle Details',
  STATISTICS: 'Statistics',
  FILE_NOT_UPLOADED_YET: 'File not uploaded yet.',
  UPLOAD: 'Upload',
  OR: 'Or',
  IT_SEEMS: 'It seems',
  DIDNT_RESPONDED_TO_INVITATION: `didn't respond to the invitation you sent. Let's send them a reminder.`,
  YOUR_INVITATION_SENT_TO: 'Your invitation has been sent to',
  FOR: 'for',
  PROFILE: 'profile',
  EMPTY_USER_HEADING: `It seems you haven't added any user here.`,
  EMPTY_USER_DESCRIPTION: `You can import or add users by clicking on any of the below buttons.`,
  ADD: 'Add',
  UPDATE_CHAMPIONSHIP: 'Update Championship',
  UPDATE_EVENT_DETAILS: 'Update Event Details',
  ADD_CHAMPIONSHIP_ADMIN: 'Add Championship Admin',
  DETAILS: 'Details',
  DISABLE_CHAMPIONSHIP: 'Disable Championship',
  ENABLE_CHAMPIONSHIP: 'Enable Championship',
  EDIT: 'Edit',
  ADD_ADMIN: 'Add Admin',
  REMOVE_ADMIN: "Remove Admin",
  ADMINS: 'Admins',
  DELETE_ADMIN_DESCRIPTION: 'Are you sure you want to delete this Admin?',
  DELETE_ADMIN_HEADING: 'Delete Admin',
  PLUS: '+',
  MINUS: '-',
  ADD_CHAMPIONSHIP: 'Add Championship',
  ADD_NEW_CHAMPIONSHIP: 'Add New Championship',
  EMPTY_CHAMPIONSHIP_HEADING: 'No Championships added yet.',
  EMPTY_CHAMPIONSHIP_DESCRIPTION: 'Please add championships to get started.',
  CHECKIN_HOURS_INPUT: 'checkin',
  CHECKIN_HOURS: 'Checkin Hours',
  CHECKIN_PLACEHOLDER: 'Select',
  REVERT_EMERGENCY_ACCESS: 'Revert Emergency Access',
  ARE_YOU_SURE_YOU_WANT_TO_REVERT_EMERGENCY_ACCESS: 'Are you sure you want to revert Emergency Access from this user?',
  INVALID_NAME: 'Name cannot have digits or special characters.',
  EVENT_DATE: 'Event Date',
  EVENT_TIME: 'Event Time',
  CHECKIN_HOURS_REQUIRED: 'Checkin hours is required.',
  EVENT_CHAMPIONSHIP_NAME: 'Championship Name',
  NUMBER_REQUIRED: "Number required",
  QUESTION_CANNOT_BE_NEGATIVE: "Questions can't be negative",
  NO_OF_QUESTION_REQUIRED: 'No of question is required.',
  TEST_TYPE_IS_REQUIRED: "Test type is required.",
  PLEASE_SELECT_CHAMPIONSHIP: "Please select championship.",
  AMBASSADORS_TITLE: "Ambassadors",
  AMBASSADOR_ACCESS: "Ambassador Access",
  GRANT_AMBASSADOR_ACCESS: "Grant Ambassador Access",
  REVOKE_AMBASSADOR_ACCESS: "Revoke Ambassador Access",
  ARE_YOU_SURE_YOU_WANT_TO_GRANT_AMBASSADOR_ACCESS:
    "Are you sure you want to grant Ambassador Access to this user?",
  ARE_YOU_SURE_YOU_WANT_TO_REVOKE_AMBASSADOR_ACCESS:
    "Are you sure you want to revoke Ambassador Access from this user?",
  MANAGE_ROLES_TITLE: 'SEF User Types',
  MANAGE_ROLES: 'Manage User Types',
  USER_TYPE: 'User Type',
  ADD_USER_TYPE: 'Add User Type',
  EDIT_USER_TYPE:'Edit User Type',
  EMPTY_USERTYPE_HEADING: "It seems you haven't added any user type.",
  EMPTY_USERTYPE_DESCRIPTION: `You can add user type by clicking on the button below.`,
  MANAGE_USER_TYPE: 'Manage User Types',
  USER_TYPE_PLACEHOLDER: 'Enter User Type',
  USER_TYPE_INPUT: 'user_type',
  BLOCK: 'Block',
  BLOCK_USER_FROM_GIVING_TEST_CONTENT: 'Are you sure you want to block this user from giving tests?',
  APPROVE_USER_FROM_GIVING_TEST_CONTENT: 'Are you sure you want to allow this user to give tests?',
  BLOCK_USER: 'Block User',
  UNBLOCK_USER: 'Unblock User',
  TEST_REMINDER_CONTENT: 'Test reminder has been sent to',
  TEST_REMINDER_TITLE: 'Test Reminder',
  CONTACT_NAME: 'Contact Name',
  HOLDING_AREA: 'Holding Area',
  HOLDING_AREA_TITLE: 'SEF Holding Area',
  PRIVACY_POLICY: 'Privacy Policy',
  PROFILE:'Profile',
  DELETE_CHAMPIONSHIP:"Delete Championship",
  SEF_ADMIN:"SEF Admin",
  CHAMPIONSHIP_ADMIN: "Championship Admin",
  NEED_DOCUMENT_TEST: "Need Document for Start Test",
  SAVE_NEXT: "Save & Next"
}
