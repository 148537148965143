import { connect, formValueSelector } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const { fetchChampionships, createChampionships, stopLoader, startLoader, updateChampionshipStatus } = require(`../../../../../../redux/${PLATFORM}/actions`);

const mapStateToProps = (state) => {
    return ({
        championshipList: state.ChampionshipReducer.championshipList,
        championship: state.form.championship || {},
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        stopLoader: () => dispatch(stopLoader()),
        startLoader: () => dispatch(startLoader()),
        fetchChampionships: (data, success, failure) => dispatch(fetchChampionships(data, success, failure)),
        createChampionships: (data, success, failure) => dispatch(createChampionships(data, success, failure)),
        updateChampionshipStatus: (data, success, failure) => dispatch(updateChampionshipStatus(data, success, failure))
    }
}
export const ChampionshipScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);