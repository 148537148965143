import { all, fork } from 'redux-saga/effects';
import AuthSaga from './auth';
import UserSaga from './user';
import EventSaga from './events';
import ChampionshipsSaga from './championships';
import TestSaga from './test';
import AmbassadorSaga from './ambassadors';
import EmergencySaga from './emergency';
import RaceDirectorSaga from './race-director';

function* dataSaga() {
  yield all([
    fork(AuthSaga),
    fork(UserSaga),
    fork(EventSaga),
    fork(ChampionshipsSaga),
    fork(TestSaga),
    fork(EmergencySaga),
    fork(AmbassadorSaga),
    fork(RaceDirectorSaga)
  ]);
}

export default dataSaga;
