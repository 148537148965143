import { takeLatest, all, put } from "redux-saga/effects";

import {
    FETCH_TEST_LIST,
    UPLOAD_FILE,
    FETCH_QUESTION_DETAIL,
    setQuestionList,
    FETCH_QUESTION_LIST,
    DELETE_QUESTION,
    startLoader,
    stopLoader,
    setTestList,
    saveQuestionDetails,
    setAuthorization,
    SAVE_INSTRUCTION,
    UPDATE_TEST_QUESTION,
    CREATE_QUESTION,
    UPDATE_TEST,
    CREATE_TEST,
    FETCH_INSTRUCTION,
    getInstruction
} from '../actions';
const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const {
    postRequest,
    putRequest,
    deleteRequest,
    getRequest
} = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);

const createFormData = (fileData) => {
    const data = new FormData();
    data.append("file", fileData.file);

    return data;
};

function* fetchTestList({ data, success, failure }) {
    yield put(startLoader())
    try {
        const response = yield postRequest({ API: `${api.URL.CREATE_TEST_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(setTestList(response.data));
            success(response.data)
        }
    }
    catch (error) {

        return;
    } finally {
        yield put(stopLoader())
    }
}

function* createTest({ data, success, failure }) {
    try {
        const response = yield putRequest({ API: `${api.URL.CREATE_TEST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(setQuestionList(response.data.data))
            success(response.data);
        }
    }
    catch (error) {
        return;
    } finally {
        yield put(stopLoader())
    }
}
function* fetchQuestionList({ data, success, failure }) {
    try {
        if (!data.championshipId) {
            delete data.championshipId;
        }
        if (!data.searchString) {
            delete data.searchString;
        }
        if (data.filterRole === '1' || data.filterRole === 1) {
            delete data.filterRole;
        }
        const response = yield postRequest({ API: `${api.URL.TEST_QUESTION_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(setQuestionList(response.data.data))
            success(response.data);
        }
    }
    catch (error) {
        return;
    } finally {
    }
}

function* saveInstruction({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.INSTRUCTION_SAVE}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    } finally {
        yield put(stopLoader())
    }
}

function* updateTest({ data, success, failure }) {
    try {
        const response = yield putRequest({ API: `${api.URL.TEST_STATUS_UPDATE}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    } finally {
        yield put(stopLoader())
    }
}

function* createQuestion({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.ADD_QUESTION}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(setQuestionList(response.data.data))
            success(response.data);
        }
    }
    catch (error) {
        return;
    } finally {
        yield put(stopLoader())
    }
}

function* deleteTestQuestion({ data, success, error }) {
    try {
        const response = yield deleteRequest({ API: `${api.URL.QUESTION}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* uploadTestFile({ data, success, failure }) {
    const formData = createFormData(data);
    try {
        const response = yield postRequest({ API: `${api.URL.UPLOAD_FILE}`, DATA: formData, HEADER: { "Content-Type": 'multipart/form-data' } });
        if (response.status !== STATUS_CODE.successful) {
        }
        else {
            success(response.data.data.url)
        }
    }
    catch (error) {
        return;
    }
    finally {
        return;
    }
}

function* fetchQuestionDetails({ id, success, failure }) {
    try {
        const response = yield getRequest({ API: `${api.URL.QUESTION}/${id}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveQuestionDetails(response.data.data));
            success(response.data)

        }
    }
    catch (error) {
        return;
    }
}


function* fetchInstruction({ data }) {
    try {
        const championshipId = data && data.championshipId;
        const response = yield getRequest({ API: `${api.URL.FETCH_INSTRUCTION}/${championshipId}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
        }
        else {
            yield put(getInstruction(response.data.data))
        }
    }
    catch (error) {
        return;
    }
};

function* updateQuestion({ data, failure, success }) {

    try {
        const response = yield putRequest({ API: `${api.URL.UPDATE_QUESTION}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(setQuestionList(response.data.data))
            success(response.data);
        }
    }
    catch (error) {
        return;
    } finally {
        yield put(stopLoader())
    }
}

function* TestSaga() {
    yield all([
        takeLatest(FETCH_TEST_LIST, fetchTestList),
        takeLatest(FETCH_QUESTION_LIST, fetchQuestionList),
        takeLatest(SAVE_INSTRUCTION, saveInstruction),
        takeLatest(CREATE_QUESTION, createQuestion),
        takeLatest(UPDATE_TEST, updateTest),
        takeLatest(DELETE_QUESTION, deleteTestQuestion),
        takeLatest(CREATE_TEST, createTest),
        takeLatest(UPLOAD_FILE, uploadTestFile),
        takeLatest(FETCH_QUESTION_DETAIL, fetchQuestionDetails),
        takeLatest(FETCH_INSTRUCTION, fetchInstruction),
        takeLatest(UPDATE_TEST_QUESTION, updateQuestion)
    ]);
}

export default TestSaga;
