import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import query from 'query-string';
import moment from 'moment-timezone';
import './style.scss';
import { EventReduxForm } from './form';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const {
    LAYOUTS,
    PAGE_TITLES,
    LOCATION_ICON,
    MEDICAL_ICON,
    DOB_ICON,
    MESSAGES,
    EVENT_ATTENDEES,
    ROUTES,
    PLUS_ICON,
    MINUS_ICON,
    EVENT_CITY,
    EVENT_COUNTRY_ICON,
    EMERGENCY_ICON,
    VEHICLE_ICON,
    INFO_ICON,
    SUB_ADMIN_PLATFORM,
    PHONE_ICON,
    BLOOD_GROUPS,
    EVENT_DATE,
    TABLE_SIZE_ITEMS,
    EVENT_TIME,
    DEFAULT_USER_ICON,
    STATS_ICON
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);

export const Screen = ({
    eventDetails,
    location,
    history,
    startLoader,
    stopLoader,
    updateEvent,
    platformType,
    championshipData,
    fetchEventDetails
}) => {
    const [popupVisible, setPopVisible] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [eventData, setEventData] = useState({
        name: eventDetails && eventDetails.name ? eventDetails.name : '',
        description: eventDetails && eventDetails.description ? eventDetails.description : '',
        startDate: eventDetails && eventDetails.startDate ? eventDetails.startDate : new Date(),
        endDate: eventDetails && eventDetails.endDate ? eventDetails.endDate : new Date()
    });
    const [sortByKey, setSortByKey] = useState('date');
    const [sortType, setSortType] = useState(1);
    const [expandRow, setExpandRow] = useState(false);
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        onConfirmation: () => { }
    });
    const offset = new Date().getTimezoneOffset();
    const parameters = query.parse(location.search);
    useEffect(() => {
        // fetchUserInfo(parameters.id);
        fetchEventDetails(parameters.id, () => { }, () => { });
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>{PAGE_TITLES.userDetail}</title>
            </Helmet>
            <div className="main-panel d-flex flex-column">
                <div className="content-wrapper">
                    <DecisionPopup
                        modalVisibility={popupVisible}
                        dialogContent={popupData.popupContent}
                        dialogTitle={popupData.popupTitle}
                        confirmButtonTitle={popupData.confirmButton}
                        rejectButtonTitle={popupData.rejectButton}
                        toggleDialogModal={() => setPopVisible(!popupVisible)}
                        onConfirmation={popupData.onConfirmation}
                        onRejection={() => setPopVisible(false)}
                    />
                    <SnackbarWrapper
                        visible={openSnackBar}
                        onClose={() => setOpenSnackbar(false)}
                        variant={snackbarData.variant}
                        message={snackbarData.message}
                    />
                    {editForm && (platformType === SUB_ADMIN_PLATFORM) && <div className='dropzone-div'>
                        <div className="overlay"></div>
                        <div className="dropzone-dialog update_champ">
                            <div className="dropzone-content">
                                <div className="dropzone-body">
                                    <h3 className="mb-4 text-center">{STRINGS.UPDATE_EVENT_DETAILS}</h3>

                                    <EventReduxForm
                                        onCancel={() => {
                                            setEditForm(false);
                                            setEventData({
                                                name: eventDetails && eventDetails.name ? eventDetails.name : '',
                                                description: eventDetails && eventDetails.description ? eventDetails.description : '',
                                                startDate: eventDetails && eventDetails.startDate ? eventDetails.startDate : new Date(),
                                                endDate: eventDetails && eventDetails.endDate ? eventDetails.endDate : new Date()
                                            });
                                        }}
                                        onSubmit={(formProps) => {
                                            startLoader();
                                            let date = formProps['start-date'];
                                            let time = new Date(formProps['start-time']);
                                            let modifiedDate = date.setHours(time.getHours(), time.getMinutes(), 0, 0);
                                            modifiedDate = new Date(modifiedDate);
                                            updateEvent({
                                                eventId: parameters.id,
                                                name: formProps['event-name'],
                                                description: formProps['event-details'],
                                                dateTime: modifiedDate.getTime(),
                                                country: formProps.country,
                                                city: formProps.city,
                                                circuit: formProps.circuit,
                                                startCheckInTime: formProps.checkin.value + 4
                                            }, (response) => {
                                                setEditForm(false);
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                fetchEventDetails(parameters.id, () => {
                                                    stopLoader();
                                                    setOpenSnackbar(true);
                                                }, () => { });
                                            }, (response) => {
                                                setSnackBarData({
                                                    variant: response.status ? 'success' : 'error',
                                                    message: response.msg
                                                });
                                                stopLoader();
                                                setOpenSnackbar(true);
                                            })
                                        }}
                                        eventName={eventDetails.name}
                                        eventDescription={eventDetails.description}
                                        eventDateTime={eventDetails.dateTime}
                                        eventCountry={eventDetails.country}
                                        eventCity={eventDetails.city}
                                        eventCircuit={eventDetails.circuit}
                                        checkinTime={eventDetails.startCheckInTime}
                                        championshipData={eventDetails}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    {eventDetails &&
                        <React.Fragment>
                            <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
                                <h5><label onClick={() => { history.replace(ROUTES.EVENTS) }}>{STRINGS.EVENT_LIST}</label> <span>{'Description'}</span></h5>
                            </div>
                            <div className={'card border-0 shadow'}>
                                <div className={'card-body event_sec'}>
                                    <div className="row align-items-center mb-2">
                                        <div className="col-6">
                                            <h3 className="mb-0">{eventDetails.name}</h3>
                                        </div>
                                        {(platformType === SUB_ADMIN_PLATFORM) && <div className="col-6 text-right">
                                            <button className="btn btn-outline-secondary edit_btn" onClick={() => {
                                                setEditForm(true);
                                            }}><i className="edit-icon mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="27.034" height="26.999" viewBox="0 0 27.034 26.999">
                                                <path id="Forma_1" d="M1738.813,731.506l5.5,5.519L1730.389,751l-5.493-5.519Zm9.675-1.331-2.451-2.462a2.433,2.433,0,0,0-3.442,0l-2.351,2.358,5.5,5.519,2.741-2.75A1.9,1.9,0,0,0,1748.489,730.175Zm-26.474,23.06a.629.629,0,0,0,.76.747l6.133-1.491-5.5-5.52Z" transform="translate(-1722 -727)" fill="#f42634" fill-rule="evenodd" />
                                            </svg></i> {STRINGS.EDIT}</button>
                                        </div>}
                                    </div>

                                    <p>{eventDetails.description}</p>

                                    <hr className="clearfix" />

                                    <div className="block_tile mt-4">

                                        <div className="row flex-wrap flex-column flex-md-row">
                                            <div className={'col form-group'}>
                                                <h6><i className="mr-2"><img src={EVENT_DATE} height={20} width={20} alt='date' /></i> {STRINGS.EVENT_DATE}</h6>
                                                <span>
                                                    {moment(new Date(eventDetails.dateTime)).format('DD-MMM-YYYY')}
                                                </span>
                                            </div>

                                            <div className={'col form-group'}>
                                                <h6><i className="mr-2"><img src={EVENT_TIME} height={20} width={20} alt='date' /></i> {STRINGS.EVENT_TIME}</h6>
                                                <span>
                                                    {moment(new Date(eventDetails.dateTime).toLocaleTimeString(), 'HH:mm').format('hh:mm A')}
                                                </span>
                                            </div>

                                            <div className={'col form-group'}>
                                                <h6><i className="mr-2"><img src={EVENT_COUNTRY_ICON} height={20} width={20} alt='date' /></i> {STRINGS.COUNTRY}</h6>
                                                <span>{eventDetails.country}</span>
                                            </div>

                                            <div className={'col form-group'}>
                                                <h6><i className="mr-2"><img src={EVENT_CITY} height={20} width={20} alt='date' /></i> {STRINGS.CITY} </h6>
                                                <span>{eventDetails.city}</span>
                                            </div>

                                            <div className={'col form-group'}>
                                                <h6><i className="mr-2"><img src={LOCATION_ICON} height={18} width={15} alt='date' /></i> {STRINGS.LOCATION}</h6>
                                                <span>{eventDetails.circuit}</span>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="block_tile mt-3">
                                        <h6><i className="mr-2"><img src={EVENT_ATTENDEES} height={20} width={20} alt='date' /></i>
                                            <label>{STRINGS.ATTENDEES}</label>
                                        </h6>
                                        <div className="d-flex align-items-center justify-content-start flex-row flex-wrap attend_detail">
                                            {eventDetails.attendees.map((item, index) => {
                                                if (index < 3) {
                                                    return (
                                                        <figure>
                                                            <img src={item.profileUrl} className={'rounded-circle'} width={70} alt='date' />
                                                        </figure>
                                                    )
                                                }
                                                return null;
                                            })}
                                            {eventDetails.total_attendees > 3 && <span className={'plus-attendees rounded-circle'}>{`${eventDetails.total_attendees - 3}+`}</span>}

                                            <span>{`${eventDetails.total_attendees} ${STRINGS.PEOPLE_ARE_ATTENDING}`}</span>

                                            {eventDetails.total_attendees !== 0 && <a href={`${ROUTES.ATTENDEES}?id=${parameters.id}`} className={'see-all ml-md-5'} rel="noopener noreferrer" onClick={() => history.push(`${ROUTES.ATTENDEES}?id=${parameters.id}`)}>{STRINGS.SEE_ALL}</a>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                </div>
            </div>
        </React.Fragment >
    );
}