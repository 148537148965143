import React, { useState, useEffect } from "react";
import './style.scss';
import { TextField } from '@material-ui/core';
import { AddQuestions } from './questions-form';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
const { InstructionForm } = require(`./instruction-form`);
const { SearchableDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/searchable-dropdown`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const { CustomDropdown } = require(`../../../../../../components/${PLATFORM}/atoms/drop-down`);
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);
const { CustomPagination } = require(`../../../../../../components/${PLATFORM}/atoms/pagination`);
const {
    LABELS,
    LAYOUTS,
    MESSAGES,
    SEARCH_ICON,
    ROLE_LABELS,
    TABLE_ARROW_DOWN,
    TABLE_SIZE_ITEMS,
    TABLE_ARROW_UP,
    DELETE_ICON,
    SUB_ADMIN_PLATFORM,
    EMPTY_QUESTION_ICON,
    EDIT_ICON,
    ROUTES,
    NEXT_ARROW,
    PREV_ARROW,
    LIMITS,
    QUESTION_TYPE,
    USER_ROLE_ITEMS,
    ACTIVE_PAGE_STYLE
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`);

export const Questions = ({
    fetchTestList,
    fetchInstruction,
    championshipData,
    fetchQuestionList,
    questionList,
    testList,
    history,
    goInstruction,
    platformType,
    saveInstruction,
    setAddButtonData,
    startLoader,
    questionsTab,
    editInstruction,
    setEditInstruction,
    deleteQuestion,
    instruction,
    stopLoader,
    uploadFile,
    fetchUserTypes,
    userTypeList,
    createQuestion,
    testInstruction,
    fetchChampionships
}) => {
    const [activePage, setActivePage] = useState(1);
    const [popupVisible, setPopVisible] = useState(false);
    const [selectedChampionship, setSelectedChampionship] = useState(null);
    const [tableSize, setTableSize] = useState(TABLE_SIZE_ITEMS[0]);
    const [sortType, setSortType] = useState(-1);
    const [CHAMPIONSHIPS_ITEMS, setChampionshipItems] = useState([]);
    const [champList, setChampList] = useState(null);
    const [roleItems, setRoleItems] = useState([]);
    const [sortByKey, setSortByKey] = useState('question');
    const [searchString, setSearchString] = useState('');
    const [showQuestionsForm, setShowQuestionsForm] = useState(false);
    const [selectedRole, setSelectedRole] = useState(USER_ROLE_ITEMS[0]);
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        confirmButton: STRINGS.CONFIRM,
        onConfirmation: () => { }
    });
    const [editQuestion, setEditQuestion] = useState(false);

    useEffect(() => {
        fetchUserTypes((response) => {
            stopLoader();
        }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            setOpenSnackbar(true);
            stopLoader();
        });
    }, [])

    useEffect(() => {
        fetchChampionships({
            searchString: '',
            sortKey: 'name',
            sortType: 1,
            limit: 0,
            page: 1
        }, (response) => {
            let items = [];
            response.data.map((item, index) => {
                items.push({
                    label: item.name,
                    value: index + 1
                });
                return null;
            });
            setChampList([...response.data])
            setChampionshipItems([...items])
            stopLoader();
        },
            (response) => {
                setSnackBarData({
                    variant: response.status ? 'success' : 'error',
                    message: response.msg
                });
                setOpenSnackbar(true);
                stopLoader();
            });
    }, []);

    useEffect(() => {
        let items = [];
        if (userTypeList) {
            userTypeList.map((item) => {
                items.push({
                    label: item.value,
                    value: item.key
                })
            });
            setRoleItems([USER_ROLE_ITEMS[0], ...items]);
        }
    }, [userTypeList && userTypeList.length])

    React.useEffect(() => {
        startLoader();
        fetchQuestionList({
            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
            searchString: searchString,
            sortKey: sortByKey,
            sortType: sortType,
            limit: tableSize.label,
            page: activePage
        }, () => { stopLoader() }, (response) => {
            setSnackBarData({
                variant: response.status ? 'success' : 'error',
                message: response.msg
            });
            stopLoader();
            setOpenSnackbar(true);
        })
    }, [])

    React.useEffect(() => {
        setAddButtonData(
            (platformType === SUB_ADMIN_PLATFORM && questionsTab && (questionList && questionList.metaData && (questionList.metaData.total !== 0 || !!searchString) && !instruction && !showQuestionsForm)),
            () => setShowQuestionsForm(true),
            STRINGS.ADD_QUESTION)
    }, [questionList && questionList.data && questionList.data.length])

    return (
        <React.Fragment>
            {/* instruction */}
            {instruction && <InstructionForm
                SUB_ADMIN_PLATFORM={SUB_ADMIN_PLATFORM}
                platformType={platformType}
                testInstruction={testInstruction}
                fetchInstruction={fetchInstruction}
                championshipData={championshipData}
                onSubmit={(formData) => {
                    saveInstruction({
                        championshipId: championshipData._id,
                        instructions: formData
                    }, (response) => {
                        goInstruction(false);
                        setEditInstruction(false);
                        setSnackBarData({
                            variant: response.status ? 'success' : 'error',
                            message: response.msg
                        });
                        stopLoader();
                        setOpenSnackbar(true);
                        if (editInstruction) {
                            fetchQuestionList({
                                championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                searchString: searchString,
                                sortKey: sortByKey,
                                sortType: sortType,
                                limit: tableSize.label,
                                page: activePage
                            }, () => { }, (response) => {
                                setSnackBarData({
                                    variant: response.status ? 'success' : 'error',
                                    message: response.msg
                                });
                                stopLoader();
                                setOpenSnackbar(true);
                            })
                        }
                        else {
                            goInstruction(false);
                            setEditInstruction(false);
                            setShowQuestionsForm(true);
                        }
                    }, (response) => {
                        setSnackBarData({
                            variant: response.status ? 'success' : 'error',
                            message: response.msg
                        });
                        stopLoader();
                        setOpenSnackbar(true);
                    })
                }}
                onCancel={() => {
                    goInstruction(false);
                    setEditInstruction(false);
                }}
            />}
            {showQuestionsForm && <AddQuestions
                uploadFile={uploadFile}
                startLoader={startLoader}
                userTypeList={userTypeList}
                fetchUserTypes={fetchUserTypes}
                stopLoader={stopLoader}
                onSubmit={(formData) => {
                    let options = [];
                    formData['members'].map((item, index) => {
                        options.push({
                            id: index + 1,
                            option: item.answer
                        });
                        return null;
                    });
                    let correctAnswers = [];
                    formData['right_option_list'].map((item) => {
                        correctAnswers.push(item + 1);
                        return null;
                    });
                    let minutes = formData.time[0].question_minute;
                    let seconds = formData.time[0].question_seconds;
                    let questionTime = (parseInt(minutes) * 60) + parseInt(seconds);
                    createQuestion({
                        championshipId: championshipData._id,
                        userRole: formData['user_type'].value,
                        questionType: formData['question_type'].value,
                        question: formData['question_title'],
                        questionUrl: formData.file,
                        questionTime: questionTime,
                        options: [...options],
                        totalAnswers: formData['right_option_list'].length,
                        answer: [...correctAnswers]
                    }, (response) => {
                        setSnackBarData({
                            variant: response.status ? 'success' : 'error',
                            message: response.msg
                        });
                        startLoader();
                        setShowQuestionsForm(false);
                        fetchQuestionList({
                            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                            searchString: searchString,
                            sortKey: sortByKey,
                            sortType: sortType,
                            limit: tableSize.label,
                            page: activePage
                        }, () => {
                            stopLoader();
                            setOpenSnackbar(true);
                        }, (response) => {
                            setSnackBarData({
                                variant: response.status ? 'success' : 'error',
                                message: response.msg
                            });
                            stopLoader();
                            setOpenSnackbar(true);
                        })
                    }, (response) => {
                        setSnackBarData({
                            variant: response.status ? 'success' : 'error',
                            message: response.msg
                        });
                    })
                }}
                onCancel={() => {
                    setShowQuestionsForm(false);
                    setAddButtonData(true, () => setShowQuestionsForm(true), STRINGS.ADD_QUESTION)
                }}
            />
            }
            <DecisionPopup
                modalVisibility={popupVisible}
                dialogContent={popupData.popupContent}
                dialogTitle={popupData.popupTitle}
                confirmButtonTitle={popupData.confirmButton}
                rejectButtonTitle={popupData.rejectButton}
                toggleDialogModal={() => setPopVisible(!popupVisible)}
                onConfirmation={popupData.onConfirmation}
                onRejection={() => setPopVisible(false)}
            />
            {
                <div className={'card-body p-0'}>
                    <React.Fragment>
                        {(platformType !== SUB_ADMIN_PLATFORM || (!instruction && !showQuestionsForm))
                            &&
                            <div className="table_filter">
                                <React.Fragment>
                                    <div className="form-row table-listing">
                                        <div className="col-md-3">
                                            <div className='table-search'>
                                                <TextField
                                                    className='text-field'
                                                    label={LABELS.search}
                                                    type="text"
                                                    value={searchString}
                                                    placeholder={STRINGS.SEARCH}
                                                    onChange={e => {
                                                        fetchQuestionList({
                                                            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                            searchString: e.target.value,
                                                            sortKey: sortByKey,
                                                            sortType: sortType,
                                                            limit: tableSize.label,
                                                            page: 1
                                                        }, () => { }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            stopLoader();
                                                            setOpenSnackbar(true);
                                                        })
                                                        setSearchString(e.target.value);
                                                    }}
                                                    margin="0"
                                                />
                                                <i><img className='search-icon' src={SEARCH_ICON} alt={''} /></i>
                                            </div>
                                        </div>

                                        {platformType === SUB_ADMIN_PLATFORM && <div className={'col-md-9'}>
                                            <div className="d-flex justify-content-between justify-content-md-end select_multi">
                                                <CustomDropdown
                                                    labelText={LABELS.role}
                                                    dataItems={roleItems}
                                                    value={selectedRole.value}
                                                    dropDataSet={(value) => {
                                                        fetchQuestionList({
                                                            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                            searchString: searchString,
                                                            sortKey: sortByKey,
                                                            sortType: sortType,
                                                            limit: tableSize.label,
                                                            page: 1,
                                                            filterRole: value
                                                        }, () => { }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            stopLoader();
                                                            setOpenSnackbar(true);
                                                        })
                                                        setSelectedRole(value);
                                                        setActivePage(1);
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </React.Fragment>

                            </div>
                        }
                        <SnackbarWrapper
                            visible={openSnackBar}
                            onClose={() => setOpenSnackbar(false)}
                            variant={snackbarData.variant}
                            message={snackbarData.message}
                        />

                        {((questionList && questionList.metaData && (questionList.metaData.total !== 0 || !!searchString) && !instruction && !showQuestionsForm) || (platformType !== SUB_ADMIN_PLATFORM))
                            && <div className="table-responsive">
                                <CustomTable
                                    rows={questionList && questionList.data}
                                    rowsPerPage={tableSize.label}
                                    showProfile={false}
                                    isEvent={true}
                                    columns={LAYOUTS.TestQuestionTable}
                                    activePage={1}
                                    sortByKey={sortByKey}
                                    sortType={sortType}
                                    TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                                    emptyDataMessage={MESSAGES.noRecordsFound}
                                    TABLE_ARROW_UP={TABLE_ARROW_UP}
                                    ROLE_LABELS={ROLE_LABELS}
                                    EDIT_ICON={EDIT_ICON}
                                    DELETE_ICON={DELETE_ICON}
                                    QUESTION_TYPE={QUESTION_TYPE}
                                    onRowClick={(id) => {
                                        if (platformType === SUB_ADMIN_PLATFORM) {
                                            history.push(`${ROUTES.EDIT_TEST_QUESTION}?id=${id}`)
                                        }
                                        else
                                            return;
                                    }}
                                    onDeleteClick={(id) => {
                                        setPopupData({
                                            popupContent: STRINGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION,
                                            popupTitle: STRINGS.DELETE_QUESTION,
                                            onConfirmation: () => {
                                                setPopVisible(false);
                                                startLoader()
                                                deleteQuestion({
                                                    questionId: id
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    fetchQuestionList({
                                                        championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                        searchString: searchString,
                                                        sortKey: sortByKey,
                                                        sortType: sortType,
                                                        limit: tableSize.label,
                                                        page: activePage,
                                                        filterRole: selectedRole.value
                                                    }, () => {
                                                        setOpenSnackbar(true);
                                                        stopLoader();
                                                    }, (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        stopLoader();
                                                        setOpenSnackbar(true);
                                                    })
                                                }, (response) => {
                                                    setSnackBarData({
                                                        variant: response.status ? 'success' : 'error',
                                                        message: response.msg
                                                    });
                                                    stopLoader();
                                                    setOpenSnackbar(true);
                                                })
                                            }
                                        })
                                        setPopVisible(true);
                                    }}
                                    onEditClick={(id, index) => {
                                        history.push(`${ROUTES.EDIT_TEST_QUESTION}?id=${id}`)
                                        // setEditQuestion(true)
                                    }}
                                    setSortKey={(key) => {
                                        if (sortByKey === key) {
                                            setSortType(-1 * sortType);
                                        }
                                        else {
                                            setSortType(1)
                                        }
                                        setSortByKey(key)
                                        fetchQuestionList({
                                            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '', searchString: searchString,
                                            sortKey: sortByKey,
                                            sortType: sortType,
                                            limit: tableSize.label,
                                            page: 1
                                        }, () => { }, (response) => {
                                            setSnackBarData({
                                                variant: response.status ? 'success' : 'error',
                                                message: response.msg
                                            });
                                            stopLoader();
                                            setOpenSnackbar(true);
                                        })
                                        setActivePage(1);
                                    }}
                                />
                                {questionList && questionList.data && !!questionList.data.length && !instruction && !showQuestionsForm && (questionList.metaData.total > TABLE_SIZE_ITEMS[0].label)
                                    && <div className="text-center px-3">
                                        <div className="pagi-list d-inline-flex flex-column flex-md-row">
                                            <CustomDropdown
                                                labelText={LABELS.noOfRows}
                                                dataItems={TABLE_SIZE_ITEMS}
                                                value={tableSize.value}
                                                dropDataSet={(value) => {
                                                    fetchQuestionList({
                                                        championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                        searchString: searchString,
                                                        sortKey: sortByKey,
                                                        sortType: sortType,
                                                        limit: TABLE_SIZE_ITEMS[value - 1].label,
                                                        page: 1
                                                    }, () => { }, (response) => {
                                                        setSnackBarData({
                                                            variant: response.status ? 'success' : 'error',
                                                            message: response.msg
                                                        });
                                                        stopLoader();
                                                        setOpenSnackbar(true);
                                                    })
                                                    setActivePage(1);
                                                    setTableSize(TABLE_SIZE_ITEMS[value - 1]);
                                                }}
                                            />
                                            {(questionList && questionList.metaData && questionList.metaData.total > tableSize.label)
                                                ? <CustomPagination
                                                    displayingString={STRINGS.DISPLAYING}
                                                    outOfString={STRINGS.OUT_OF}
                                                    totalPages={questionList.metaData.total}
                                                    itemsCount={questionList.data.length}
                                                    pageCount={LIMITS.paginationPageCount}
                                                    limit={tableSize.label}
                                                    currentPage={activePage}
                                                    nextArrow={NEXT_ARROW}
                                                    prevArrow={PREV_ARROW}
                                                    onPageChange={(selectedPage) => {
                                                        let page = selectedPage.selected + 1;
                                                        fetchQuestionList({
                                                            championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id ? championshipData._id : '' : !!champList && !!selectedChampionship ? champList[selectedChampionship.value - 1]._id : '',
                                                            searchString: searchString,
                                                            sortKey: sortByKey,
                                                            sortType: sortType,
                                                            limit: tableSize.label,
                                                            page: page
                                                        }, () => { }, (response) => {
                                                            setSnackBarData({
                                                                variant: response.status ? 'success' : 'error',
                                                                message: response.msg
                                                            });
                                                            stopLoader();
                                                            setOpenSnackbar(true);
                                                        })
                                                        setActivePage(selectedPage.selected + 1)
                                                        document.getElementById('screen-content').scroll(
                                                            {
                                                                top: 0,
                                                                left: 0,
                                                                behavior: 'smooth'
                                                            }
                                                        )
                                                    }}
                                                    activePageStyle={ACTIVE_PAGE_STYLE}
                                                />
                                                :
                                                null
                                            }
                                        </div>
                                    </div>}
                            </div>}
                    </React.Fragment>
                </div>
            }
            {
                questionList && questionsTab && questionList.metaData && (questionList.metaData.total === 0 || !!searchString) && !searchString && (platformType === SUB_ADMIN_PLATFORM) && !instruction && !showQuestionsForm
                && <div className={'row py-4 py-md-4'}>
                    <div className="col-md-8 offset-md-2 text-center">
                        <figure className="mb-3 mb-md-4">
                            <img src={EMPTY_QUESTION_ICON} alt={'dashboard-logo'} className="img-fluid" />
                        </figure>
                        <h5 className="mb-3">{STRINGS.EMPTY_QUESTIONS_HEADING}</h5>
                        <p>{STRINGS.EMPTY_QUESTION_DESCRIPTION}</p>
                        <div className="group_btn mt-3 mt-md-4">
                            <button className={'btn btn-lg btn-primary text-capitalize'} onClick={() => {
                                goInstruction(true)
                            }}>{STRINGS.ADD_QUESTION}</button>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}