import React, { useState, useEffect } from 'react';
import { reduxForm, Field, reset, FieldArray, change as changeField } from "redux-form";
import { connect } from 'react-redux';
import { validator } from './instruction-validator';
import { startLoader, stopLoader } from '../../../../../../redux/sef/actions';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { TextArea } = require(`../../../../../../components/sef/atoms/text-area`);
const { Form } = require(`../../../../../../components/sef/atoms/form`);
const { InputSubmit } = require(`../../../../../../components/sef/atoms/input-submit`);
const { STRINGS } = require(`../../../../../../shared/sef/constants/us/strings`);
const { Editor } = require(`../../../../../../components/sef/cells/editor-text`)
export const InstructionForm = ({
    widthStyle,
    buttonStyle,
    data,
    fetchInstruction,
    SUB_ADMIN_PLATFORM,
    platformType,
    championshipData,
    testInstruction,
    onCancel = () => { },
    onSubmit = () => { },
    handleSubmit = () => { },
    ...props
}) => {
    useEffect(() => {
        startLoader();
        fetchInstruction({ championshipId: platformType === SUB_ADMIN_PLATFORM ? championshipData && championshipData._id : '' })
    }, [])
    useEffect(() => {
        startLoader();
        setInstructionData(testInstruction);
        stopLoader();
    }, [testInstruction])
    const [instructionData, setInstructionData] = useState('')
    const [content, setContent] = useState(1)
    const [meta, setMeta] = useState({ error: '', touched: false })
    const onSave = () => {
        if (!(content.length - 1)) {
            setMeta(
                {
                    error: STRINGS.TEST_INSTRUCTION_REQUIRED,
                    touched: true
                }
            )
        }
        else if (!content.trim().length) {
            setMeta(
                {
                    error: STRINGS.TEST_INSTRUCTION_REQUIRED,
                    touched: true
                }
            )
        }
        else {
            onSubmit(instructionData)
        }
    }

    return (
        <div className="p-4">
            <div className="row">
                <div className="col-md-12">
                    <Editor
                        meta={meta}
                        label={STRINGS.TEST_INSTRUCTION_LABEL}
                        instructionData={instructionData}
                        onHandle={(value, delta, source, editor) => {
                            setInstructionData(value);
                            if (!(editor.getText().length - 1)) {
                                setMeta(
                                    {
                                        error: STRINGS.TEST_INSTRUCTION_REQUIRED,
                                        touched: true
                                    }
                                )
                            }
                            else {
                                setMeta(
                                    {
                                        error: '',
                                        touched: false
                                    }
                                )
                            }
                            setContent(editor.getText())
                        }}
                    />
                </div>
            </div>

            <div className="button-container">
                <hr className="mt-0" />
                <div className="d-flex flex-row btn-res mt-4">
                    <button type={'button'} className={'btn btn-lg btn-outline-primary mr-2'} onClick={() => onCancel()}>{STRINGS.CANCEL}</button>
                    <InputSubmit
                        buttonAction={onSave}
                        buttonType={'submit'}
                        buttonStyle={buttonStyle}
                        buttonLabel={'Save'} />
                </div>
            </div>
        </div>
    );
}