import { ROUTES } from "./routes";
import {
  REQUESTS_ICON,
  REQUESTS_SELECTED,
  DASHBOARD_ICON,
  DASHBOARD_SELECTED,
  FEEDS_ICON,
  FEEDS_SELECTED,
  USERS_SELECTED,
  USERS_ICON,
  SEF_TESTS_ICON,
  SEF_TESTS_SELECTED,
  CHAMPIONSHIPS_SELECTED,
  CHAMPIONSHIPS_ICON,
  CHAT_ICON,
  CHAT_SELECTED,
  EVENT_SELECTED,
  EVENT_ICON,
  REGISTERED_USER,
  TOTAL_DRIVERS,
  TOTAL_MARSHALS,
  TOTAL_MEDIA,
  TOTAL_PITCREW,
  TOTAL_SECURITY,
  AMBASSADORS_SELECTED,
  AMBASSADORS_ICON,
  MANAGE_ROLES_SELECTED,
  MANAGE_ROLES_UNSELECTED,
  EMERGENCY_ACCESS,
  EMERGENCY_ACCESS_UNSELECTED,
  HOLDING_AREA_ACTIVE,
  HOLDING_AREA_INACTIVE
} from "./icons";
const {
  defaultConfig: { LOCATION }
} = require(`../../../config/default`);
const { STRINGS } = require(`./${LOCATION}/strings`);

export const SUB_ADMIN_PLATFORM = 7;
export const RACE_DIRECTOR_PLATFORM = 8;

export const EMAIL_REGX = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGX = /^[A-Za-z ]+$/;
export const CHAMPIONSHIP_NAME_REGEX = /^[A-Za-z0-9 ]+$/;
export const LABELS = {
  login: STRINGS.LOGIN,
  email: STRINGS.EMAIL,
  password: STRINGS.PASSWORD,
  forgotPassword: STRINGS.FORGOT_PASSWORD,
  checkYourMail: STRINGS.CHECK_YOUR_MAIL,
  name: STRINGS.NAME,
  requestDate: STRINGS.REQUEST_DATE,
  role: STRINGS.ROLE,
  action: STRINGS.ACTION,
  approvalRequests: STRINGS.APPROVAL_REQUESTS,
  all: STRINGS.ALL,
  drivers: STRINGS.DRIVERS,
  marshal: STRINGS.MARSHAL,
  media: STRINGS.MEDIA,
  pitCrew: STRINGS.PIT_CREW,
  security: STRINGS.SECURITY,
  noOfRows: STRINGS.NO_OF_ROWS,
  europeanSuperkart: STRINGS.EUROPEAN_SUPERKART,
  usersList: STRINGS.USERS_LIST,
  status: STRINGS.STATUS,
  active: STRINGS.ACTIVE,
  registered: STRINGS.REGISTERED,
  requested: STRINGS.REQUESTED,
  invited: STRINGS.INVITED,
  preparing: STRINGS.PREPARING,
  done: STRINGS.DONE,
  rejectedFileType: STRINGS.REJECTED_FILE_TYPE,
  addUser: STRINGS.ADD_USER,
  startDate: STRINGS.START_DATE,
  endDate: STRINGS.END_DATE,
  championship: STRINGS.CHAMPIONSHIP,
  attempt: STRINGS.ATTEMPT,
  percentage: STRINGS.PERCENTAGE,
  date: STRINGS.DATE,
  time: STRINGS.START_TIME,
  emptyTestHeading: STRINGS.EMPTY_TEST_HEADING,
  emptyTestDescription: STRINGS.EMPTY_TEST_DESCRIPTION,
  addTest: STRINGS.ADD_TEST,
  questionTitle: STRINGS.QUESTION_TITLE,
  questionType: STRINGS.QUESTION_TYPE,
  testType: STRINGS.TEST_TYPE,
  noOfQuestion: STRINGS.NO_OF_QUESTION,
  questionTitleLabel: STRINGS.QUESTION_TITLE_LABEL,
  questionTypeLabel: STRINGS.QUESTION_TYPE_LABEL,
  access: STRINGS.ACCESS,
  eventDate: STRINGS.EVENT_DATE,
  eventTime: STRINGS.EVENT_TIME,
  championshipName: STRINGS.EVENT_CHAMPIONSHIP_NAME,
  ambassadorAccess: STRINGS.AMBASSADOR_ACCESS,
  block: STRINGS.BLOCK
};

export const PAGE_TITLES = {
  dashboard: STRINGS.DASHBOARD_PAGE_TITLE,
  feeds: STRINGS.FEEDS_PAGE_TITLE,
  users: STRINGS.USERS_PAGE_TITLE,
  requests: STRINGS.REQUESTS_PAGE_TITLE,
  tests: STRINGS.TESTS_PAGE_TITLE,
  chats: STRINGS.CHATS_PAGE_TITLE,
  events: STRINGS.EVENTS_PAGE_TITLE,
  forgot: STRINGS.FORGOT_PAGE_TITLE,
  login: STRINGS.LOGIN_PAGE_TITLE,
  userDetail: STRINGS.USER_DETAIL_PAGE_TITLE,
  championshipList: STRINGS.CHAMPIONSHIP_LIST_TITLE,
  championshipDetails: STRINGS.CHAMPIONSHIP_DETAILS_TITLE,
  roles: STRINGS.MANAGE_ROLES_TITLE,
  holdingArea: STRINGS.HOLDING_AREA_TITLE,
  profile: STRINGS.PROFILE
}

export const KEY_CODES = {
  enterKey: 13,
  nine: 57,
  zero: 48,
  backSpace: 8
};

export const VALIDATION_MESSAGES = {
  EMAIL_REQUIRED: STRINGS.EMAIL_REQUIRED,
  PASSWORD_REQUIRED: STRINGS.PASSWORD_REQUIRED,
  EMAIL_INVALID: STRINGS.EMAIL_INVALID,
  USER_ROLE_REQUIRED: STRINGS.ROLE_REQUIRED,
  NAME_REQUIRED: STRINGS.NAME_REQUIRED,
  CHAMPIONSHIP_NAME_REQUIRED: STRINGS.CHAMPIONSHIP_NAME_REQUIRED,
  EVENT_NAME_REQUIRED: STRINGS.EVENT_NAME_REQUIRED,
  COUNTRY_REQUIRED: STRINGS.COUNTRY_REQUIRED,
  CITY_REQUIRED: STRINGS.CITY_REQUIRED,
  CIRCUIT_NAME_REQUIRED: STRINGS.CIRCUIT_NAME_REQUIRED,
  VALUE_CANNOT_BE_ONLY_SPACES: STRINGS.VALUE_CANNOT_BE_ONLY_SPACES,
  START_DATE_REQUIRED: STRINGS.START_DATE_REQUIRED,
  END_DATE_REQUIRED: STRINGS.END_DATE_REQUIRED,
  START_TIME_REQUIRED: STRINGS.START_TIME_REQUIRED,
  ADMIN_NAME_REQUIRED: STRINGS.ADMIN_NAME_REQUIRED,
  CHAMPIONSHIP_DESCRIPTION_REQUIRED: STRINGS.CHAMPIONSHIP_DESCRIPTION_REQUIRED,
  EVENT_DESCRIPTION_REQUIRED: STRINGS.EVENT_DESCRIPTION_REQUIRED,
  TEST_INSTRUCTION_REQUIRED: STRINGS.TEST_INSTRUCTION_REQUIRED,
  USER_TYPE_REQUIRED: STRINGS.USER_TYPE_REQUIRED,
  QUESTION_TYPE_REQUIRED: STRINGS.QUESTION_TYPE_REQUIRED,
  ANSWER_TYPE_REQUIRED: STRINGS.ANSWER_TYPE_REQUIRED,
  QUESTION_TITLE_REQUIRED: STRINGS.QUESTION_TITLE_REQUIRED,
  END_DATE_MUST_BE_GREATER: STRINGS.END_DATE_MUST_BE_GREATER,
  INVALID_NAME: STRINGS.INVALID_NAME,
  CHECKIN_HOURS_REQUIRED: STRINGS.CHECKIN_HOURS_REQUIRED,
  NUMBER_REQUIRED: STRINGS.NUMBER_REQUIRED,
  NO_OF_QUESTION_REQUIRED: STRINGS.NO_OF_QUESTION_REQUIRED,
  QUESTION_CANNOT_BE_NEGATIVE: STRINGS.QUESTION_CANNOT_BE_NEGATIVE,
  TEST_TYPE_IS_REQUIRED: STRINGS.TEST_TYPE_IS_REQUIRED
};

export const MESSAGES = {
  noRecordsFound: STRINGS.NO_RECORDS_FOUND,
  pleaseSelectChampionship: STRINGS.PLEASE_SELECT_CHAMPIONSHIP
};

export const ALT_TEXTS = {
  companyLogo: "company-logo-image",
  topShapeImage: "top-shape-image",
  bottomShapeImage: "bottom-shape-image",
  badgeImage: "badge image",
  backArrow: "back-arrow",
  calender: "calender",
  loader: "loader"
};

export const ELEMENT_ID = {
  welcomeText: 'welcome-text',
  loginPage: 'login-page',
  loginButton: 'login-button',
  submitButton: 'submit-button',
  challengePage: 'challenge-page',
  challengesDiv: 'challenges-div',
  challengeButton: 'challenge-button',
  redirectingLink: 'redirecting-link',
  profilePage: 'profile-page',
  leaderboardPage: 'leaderboard-page',
  inputWebUrl: 'input-website-url',
  emailInput: 'email-input',
  passwordInput: 'password-input',
  challengeDetailPage: 'challenge-detail-page',
  challengeDetailDiv: 'challenge-detail-div',
  dropInput: 'drop-input',
  customInput: 'custom-input',
  default: 'default',
  name: 'name',
  email: 'email',
  date: 'date',
  role: 'role',
  action: 'action',
  status: 'status',
  active: 'active',
  createTestAction: 'createTestAction',
  endDate: 'endDate',
  startDate: 'startDate',
  championship: 'championship',
  attempt: 'totalAttempts',
  percentage: 'latestScore',
  time: 'time',
  dateTime: 'dateTime',
  questionTitle: 'question',
  questionType: 'questionType',
  testType: 'testType',
  noOfQuestions: 'noOfQuestion',
  access: 'access',
  championshipName: 'championship-name',
  ambassadorAccess: "ambassadorAccess",
  userTypeName: 'user-type-name',
  block: 'block',
  userPercentage: 'percentage',
  testListAction: 'test-list-action',
  testReminder: 'test-reminder',
  testUserName: 'test-name'
};

export const STATUS_CODE = {
  successful: 200,
  unAuthorized: 401
};

export const RACE_DIRECTOR_DRAWER_ITEMS = [
  {
    tag: STRINGS.USERS,
    alt: "drawer-icon",
    routeUrl: ROUTES.USERS_TESTS,
    selectedIcon: USERS_SELECTED,
    unSelectedIcon: USERS_ICON
  },
  {
    tag: STRINGS.REQUESTS,
    alt: "drawer-icon",
    routeUrl: ROUTES.REQUESTS,
    selectedIcon: REQUESTS_SELECTED,
    unSelectedIcon: REQUESTS_ICON
  },
  {
    tag: STRINGS.CHAT,
    alt: "drawer-icon",
    routeUrl: ROUTES.CHATS,
    selectedIcon: CHAT_SELECTED,
    unSelectedIcon: CHAT_ICON
  },
];

export const SUB_ADMIN_DRAWER_ITEMS = [
  {
    tag: STRINGS.DASHBOARD,
    alt: "drawer-icon",
    routeUrl: ROUTES.DASHBOARD,
    selectedIcon: DASHBOARD_SELECTED,
    unSelectedIcon: DASHBOARD_ICON
  },
  {
    tag: STRINGS.NEWS_FEEDS,
    alt: "drawer-icon",
    routeUrl: ROUTES.NEWS_FEEDS,
    selectedIcon: FEEDS_SELECTED,
    unSelectedIcon: FEEDS_ICON
  },
  {
    tag: STRINGS.AMBASSADORS,
    alt: "drawer-icon",
    routeUrl: ROUTES.AMBASSADORS,
    selectedIcon: AMBASSADORS_SELECTED,
    unSelectedIcon: AMBASSADORS_ICON
  },
  {
    tag: STRINGS.USERS,
    alt: "drawer-icon",
    routeUrl: ROUTES.USERS,
    selectedIcon: USERS_SELECTED,
    unSelectedIcon: USERS_ICON
  },
  {
    tag: STRINGS.REQUESTS,
    alt: "drawer-icon",
    routeUrl: ROUTES.REQUESTS,
    selectedIcon: REQUESTS_SELECTED,
    unSelectedIcon: REQUESTS_ICON
  },
  {
    tag: STRINGS.TESTS,
    alt: "drawer-icon",
    routeUrl: ROUTES.TESTS,
    selectedIcon: SEF_TESTS_SELECTED,
    unSelectedIcon: SEF_TESTS_ICON
  },
  {
    tag: STRINGS.EMERGENCY_ACCESS,
    alt: "drawer-icon",
    routeUrl: ROUTES.EMERGENCY_ACCESS,
    selectedIcon: EMERGENCY_ACCESS,
    unSelectedIcon: EMERGENCY_ACCESS_UNSELECTED
  },
  {
    tag: STRINGS.CHAT,
    alt: "drawer-icon",
    routeUrl: ROUTES.CHATS,
    selectedIcon: CHAT_SELECTED,
    unSelectedIcon: CHAT_ICON
  },
  {
    tag: STRINGS.EVENT,
    alt: "drawer-icon",
    routeUrl: ROUTES.EVENTS,
    selectedIcon: EVENT_SELECTED,
    unSelectedIcon: EVENT_ICON
  }
];

export const HEADER_MENU_ITEMS = [
  // {
  //   label: "Profile",
  //   routeUrl: ROUTES.PROFILE,
  //   onClick: changedUrl => { }
  // },
  // {
  //   label: "About Us",
  //   routeUrl: ROUTES.ABOUT_US,
  //   onClick: changedUrl => { }
  // },
  // {
  //   label: "Contact Us",
  //   routeUrl: ROUTES.CONTACT_US,
  //   onClick: changedUrl => { }
  // },
  {
    label: "Privacy Policy",
    routeUrl: ROUTES.PRIVACY_POLICY,
    onClick: changedUrl => { }
  }
];

export const SUPER_ADMIN_DRAWER_ITEMS = [
  {
    tag: STRINGS.DASHBOARD,
    alt: 'drawer-icon',
    routeUrl: ROUTES.DASHBOARD,
    selectedIcon: DASHBOARD_SELECTED,
    unSelectedIcon: DASHBOARD_ICON
  },
  {
    tag: STRINGS.NEWS_FEEDS,
    alt: 'drawer-icon',
    routeUrl: ROUTES.NEWS_FEEDS,
    selectedIcon: FEEDS_SELECTED,
    unSelectedIcon: FEEDS_ICON
  },
  {
    tag: STRINGS.CHAMPIONSHIPS,
    alt: 'drawer-icon',
    routeUrl: ROUTES.CHAMPIONSHIPS,
    selectedIcon: CHAMPIONSHIPS_SELECTED,
    unSelectedIcon: CHAMPIONSHIPS_ICON
  },
  {
    tag: STRINGS.MANAGE_ROLES,
    alt: 'drawer-icon',
    routeUrl: ROUTES.MANAGE_ROLES,
    selectedIcon: MANAGE_ROLES_SELECTED,
    unSelectedIcon: MANAGE_ROLES_UNSELECTED
  },
  {
    tag: STRINGS.CHAT,
    alt: 'drawer-icon',
    routeUrl: ROUTES.CHATS,
    selectedIcon: CHAT_SELECTED,
    unSelectedIcon: CHAT_ICON
  },
  {
    tag: STRINGS.TESTS,
    alt: 'drawer-icon',
    routeUrl: ROUTES.TESTS,
    selectedIcon: SEF_TESTS_SELECTED,
    unSelectedIcon: SEF_TESTS_ICON
  },
  {
    tag: STRINGS.EVENT,
    alt: 'drawer-icon',
    routeUrl: ROUTES.EVENTS,
    selectedIcon: EVENT_SELECTED,
    unSelectedIcon: EVENT_ICON
  },
  {
    tag: STRINGS.HOLDING_AREA,
    alt: 'drawer-icon',
    routeUrl: ROUTES.HOLDING_AREA,
    selectedIcon: HOLDING_AREA_ACTIVE,
    unSelectedIcon: HOLDING_AREA_INACTIVE
  }
];

export const ROLE_KEYS = {
  driver: 2,
  pitCrew: 3,
  marshal: 4,
  media: 5,
  security: 6
};

export const ROLE_LABELS = [
  LABELS.all,
  LABELS.drivers,
  LABELS.pitCrew,
  LABELS.marshal,
  LABELS.media,
  LABELS.security
];

export const ACTIVE_PAGE_STYLE = {
  backgroundColor: "white",
  color: "#18191f",
  borderColor: "#18191f",
  fontWeight: "500"
};

export const LAYOUTS = {
  userAttemptedTestList: [
    {
      id: ELEMENT_ID.testUserName,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.userPercentage,
      label: LABELS.percentage,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.block,
      label: LABELS.block,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_ID.testListAction,
      label: LABELS.action,
      minWidth: 5,
      align: "left",
    },
  ],
  unAttemptedTestList: [
    {
      id: ELEMENT_ID.testUserName,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.userPercentage,
      label: LABELS.percentage,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.block,
      label: LABELS.block,
      minWidth: 5,
      align: "left",
    },
    {
      id: ELEMENT_ID.testReminder,
      label: LABELS.action,
      minWidth: 5,
      align: "left",
    },
  ],
  contactsOptionsTable: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.email,
      label: LABELS.email,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.access,
      label: LABELS.access,
      minWidth: 5,
      align: 'left',
    }
  ],
  userTable: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.status,
      label: LABELS.status,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.active,
      label: LABELS.active,
      minWidth: 5,
      align: "left"
    }
  ],
  requestTable: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.date,
      label: LABELS.requestDate,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left"
    }
  ],
  eventList: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.dateTime,
      label: LABELS.eventDate,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.time,
      label: LABELS.eventTime,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: 'left',
    }
  ],
  superEventList: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.championshipName,
      label: LABELS.championshipName,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.dateTime,
      label: LABELS.eventDate,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.time,
      label: LABELS.eventTime,
      minWidth: 5,
      align: 'left',
    },
  ],
  attendeesList: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: 'left',
    },
  ],
  userTypes: [
    {
      id: ELEMENT_ID.userTypeName,
      label: LABELS.name,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.active,
      label: LABELS.active,
      minWidth: 5,
      align: "left"
    }
  ],
  TestQuestionTable: [
    {
      id: ELEMENT_ID.questionTitle,
      label: LABELS.questionTitleLabel,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.questionType,
      label: LABELS.questionTypeLabel,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: 'left',
    }
  ],
  TestTable: [
    {
      id: ELEMENT_ID.testType,
      label: LABELS.testType,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.noOfQuestions,
      label: LABELS.noOfQuestion,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.createTestAction,
      label: LABELS.action,
      minWidth: 5,
      align: 'center',
    },
    {
      id: ELEMENT_ID.active,
      label: LABELS.active,
      minWidth: 5,
      align: 'left'
    }
  ],
  SuperAdminTestTable: [
    {
      id: ELEMENT_ID.testType,
      label: LABELS.testType,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.noOfQuestions,
      label: LABELS.noOfQuestion,
      minWidth: 5,
      align: 'left',
    }
  ],
  ambassadorTable: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.ambassadorAccess,
      label: LABELS.ambassadorAccess,
      minWidth: 5,
      align: "left"
    }
  ],
  championshipTable: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.startDate,
      label: LABELS.startDate,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.endDate,
      label: LABELS.endDate,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.active,
      label: LABELS.active,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left"
    }
  ],
  adminTable: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.email,
      label: LABELS.email,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left"
    }
  ],
  userStatsTable: [
    {
      id: ELEMENT_ID.championship,
      label: LABELS.championship,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.attempt,
      label: LABELS.attempt,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.percentage,
      label: LABELS.percentage,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left"
    }
  ],
  holdingArea: [
    {
      id: ELEMENT_ID.name,
      label: LABELS.name,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.role,
      label: LABELS.role,
      minWidth: 5,
      align: "left"
    },
    {
      id: ELEMENT_ID.championshipName,
      label: LABELS.championshipName,
      minWidth: 5,
      align: 'left',
    },
    {
      id: ELEMENT_ID.action,
      label: LABELS.action,
      minWidth: 5,
      align: "left"
    }
  ]
};

export const EMERGENCY_ROLE_ITEMS = [
  {
    label: LABELS.all,
    value: 1
  },
  {
    label: LABELS.pitCrew,
    value: 2
  },
  {
    label: LABELS.marshal,
    value: 3
  },
];



export const LIMITS = {
  userTable: 5,
  paginationPageCount: 1
};

export const USER_STATUS_ITEMS = [
  {
    label: LABELS.all,
    value: 1
  },
  {
    label: LABELS.registered,
    value: 2
  },
  {
    label: LABELS.requested,
    value: 3
  },
  {
    label: LABELS.invited,
    value: 4
  }
];

export const USER_ROLE_ITEMS = [
  {
    label: LABELS.all,
    value: 1
  },
  {
    label: LABELS.drivers,
    value: 2
  },
  {
    label: LABELS.pitCrew,
    value: 3
  },
  {
    label: LABELS.marshal,
    value: 4
  },
  {
    label: LABELS.media,
    value: 5
  },
  {
    label: LABELS.security,
    value: 6
  }
];


export const ADD_USER_ROLE_ITEMS = [
  {
    label: LABELS.drivers,
    value: 1
  },
  {
    label: LABELS.pitCrew,
    value: 2
  },
  {
    label: LABELS.marshal,
    value: 3
  },
  {
    label: LABELS.media,
    value: 4
  },
  {
    label: LABELS.security,
    value: 5
  }
];

export const TABLE_SIZE_ITEMS = [
  {
    label: 5,
    value: 1
  },
  {
    label: 10,
    value: 2
  },
  {
    label: 15,
    value: 3
  },
  {
    label: 20,
    value: 4
  }
];

export const CHECK_IN_HOURS_ITEMS = [
  {
    label: "5 hours",
    value: 1
  },
  {
    label: "6 hours",
    value: 2
  },
  {
    label: "7 hours",
    value: 3
  },
  {
    label: "8 hours",
    value: 4
  },
  {
    label: "9 hours",
    value: 5
  },
  {
    label: "10 hours",
    value: 6
  },
  {
    label: "11 hours",
    value: 7
  },
  {
    label: "12 hours",
    value: 8
  },
  {
    label: "13 hours",
    value: 9
  },
  {
    label: "14 hours",
    value: 10
  },
  {
    label: "15 hours",
    value: 11
  },
  {
    label: "16 hours",
    value: 12
  },
  {
    label: "17 hours",
    value: 13
  },
  {
    label: "18 hours",
    value: 14
  },
  {
    label: "19 hours",
    value: 15
  },
  {
    label: "20 hours",
    value: 16
  },
  {
    label: "21 hours",
    value: 17
  },
  {
    label: "22 hours",
    value: 18
  },
  {
    label: "23 hours",
    value: 19
  },
  {
    label: "24 hours",
    value: 20
  }
];

export const ROLE_STATS_ICONS = [
  REGISTERED_USER,
  TOTAL_DRIVERS,
  TOTAL_PITCREW,
  TOTAL_MARSHALS,
  TOTAL_MEDIA,
  TOTAL_SECURITY
];

export const BLOOD_GROUPS = ["O+", "O-", "A+", "A-", "B+", "B-", "AB+", "AB-"];
export const RELATION_TYPE = ["Brother", "Sister", "Father", "Mother", "Cousin", "Uncle", "Aunt", "Son", "Daughter", "Nephew", "Niece", "Wife", "Husband", "Guardian"];
export const FIA_CATEGORIES = ["Platinum", "Gold"];
export const QUESTION_TYPE = [
  {
    label: "Text",
    value: 1
  },
  {
    label: "Image",
    value: 2
  },
  {
    label: "Audio",
    value: 3
  },
  {
    label: "Video",
    value: 4
  },
]

export const QUESTION_TYPE_CODE = {
  TEXT: 1,
  IMAGE: 2,
  AUDIO: 3,
  VIDEO: 4
}

export const ANSWER_TYPE = [
  {
    label: "True/False",
    value: 0
  }, {
    label: "Single",
    value: 1
  },
  {
    label: "Multiple",
    value: 2
  }
]

export const ANSWER_NAMES = [
  {
    inputName: STRINGS.RIGHT_ANSWER_OPTIONS,
    name: STRINGS.ANSWER_OPTIONS
  }
]

export const SUB_ADMIN_TEST_TABS = [
  { label: 'Questions' },
  { label: "Tests" }
];

export const INSTRUCTION_CHECKBOX = [
  { label: "Passport" , value:1},
  { label: "Licence" , value:2},
  { label: "Insurance" , value:3},
  { label: "Covid Test" , value:4},
  { label: "Additional Data" , value:5},
]

export const RACE_DIRECTOR_TABS = [
  { label: 'Attempted' },
  { label: 'Unattempted' }
]

export const TEST_PAGE_TAB_VALUES = {
  questionsTab: 0,
  createTestTab: 1
};

export const USER_TEST_LIST_TAB_VALUES = {
  attempted: 0,
  unAttempted: 1
};

export const SINGLE_CORRECT_RADIO_OPTIONS = [{
  value: 1,
},
{
  value: 2,
},
{
  value: 3,
},
{
  value: 4,
}
];

export const BOOLEAN_RADIO_OPTIONS = [
  {
    value: 1,
  },
  {
    value: 2,
  },
];

