import { takeLatest, all, put, delay } from "redux-saga/effects";

import {
    CREATE_CHAMPIONSHIP,
    FETCH_CHAMPIONSHIP_LIST,
    UPDATE_CHAMPIONSHIP_STATUS,
    FETCH_CHAMPIONSHIP_DETAILS,
    DELETE_CHAMPIONSHIP_ADMIN,
    UPDATE_CHAMPIONSHIP_DETAILS,
    ADD_CHAMPIONSHIP_ADMIN,
    saveChampionshipList,
    setAuthorization,
    saveChampionshipDetails,
} from '../actions';
const { defaultConfig: { PLATFORM } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const { postRequest, putRequest, getRequest, deleteRequest } = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);

function* addNewChampionship({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.CHAMPIONSHIP}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }

        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* addNewChampionshipAdmin({ data, success, error }) {
    try {
        const response = yield postRequest({ API: `${api.URL.CHAMPIONSHIP_ADMIN}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* fetchChampionshipsList({ data, success, failure }) {
    try {
        if (!data.searchString) {
            delete data.searchString;
        }
        const response = yield postRequest({ API: `${api.URL.FETCH_CHAMPIONSHIP_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveChampionshipList(response.data.data));
            yield delay(1000);
            success(response.data.data);
        }
    }
    catch (error) {
        return;
    }
};

function* fetchChampionshipDetails({ id, success, error }) {
    try {
        const response = yield getRequest({ API: `${api.URL.CHAMPIONSHIP}/${id}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data)
        }
        else {
            yield put(saveChampionshipDetails(response.data.data));
            success();
        }
    }
    catch (error) {
        return;
    }
}

function* updateChampionshipStatus({ data, success, failure }) {
    try {
        const response = yield putRequest({ API: `${api.URL.UPDATE_CHAMPIONSHIP_STATUS}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* updateChampionshipsDetails({ data, success, failure }) {
    try {
        const response = yield putRequest({ API: `${api.URL.CHAMPIONSHIP}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* deleteAdmin({ data, success, error }) {
    try {
        const response = yield deleteRequest({ API: `${api.URL.CHAMPIONSHIP_ADMIN}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            error(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* ChampionshipsSaga() {
    yield all([
        takeLatest(CREATE_CHAMPIONSHIP, addNewChampionship),
        takeLatest(FETCH_CHAMPIONSHIP_LIST, fetchChampionshipsList),
        takeLatest(UPDATE_CHAMPIONSHIP_STATUS, updateChampionshipStatus),
        takeLatest(FETCH_CHAMPIONSHIP_DETAILS, fetchChampionshipDetails),
        takeLatest(DELETE_CHAMPIONSHIP_ADMIN, deleteAdmin),
        takeLatest(ADD_CHAMPIONSHIP_ADMIN, addNewChampionshipAdmin),
        takeLatest(UPDATE_CHAMPIONSHIP_DETAILS, updateChampionshipsDetails)
    ]);
}

export default ChampionshipsSaga;