import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    fetchUnblockRequestList,
    updateBlockStatus,
    fetchUserTypes
} = require(`../../../../../../redux/sef/actions`)


const mapStateToProps = (state) => {

    return ({
        platformType: state.CommonReducer.platformType,
        unBlockRequestList: state.RaceDirectorReducer.unBlockRequestList,
        championshipData: state.CommonReducer.championshipData,
        userTypeList: state.UserReducer.userTypeList
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        stopLoader: () => dispatch(stopLoader()),
        startLoader: () => dispatch(startLoader()),
        fetchUnblockRequestList: (data, success, failure) => dispatch(fetchUnblockRequestList(data, success, failure)),
        fetchUserTypes: (success, failure) => dispatch(fetchUserTypes(success, failure)),
        updateBlockStatus: (data, success, failure) => dispatch(updateBlockStatus(data, success, failure))
    }
}
export const RequestList = connect(mapStateToProps, mapDispatchToProps)(Screen);
