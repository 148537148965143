import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import query from 'query-string';
import moment from 'moment';
import { AdminReduxForm } from './form';
import { EditChampionshipReduxForm } from './edit-form';
import './style.scss';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../../../../config/default`);
const { CustomTable } = require(`../../../../../../components/${PLATFORM}/atoms/custom-table`);
// const { MessagePopUp } = require(`../../../../../../components/${PLATFORM}/atoms/message-pop-up`);
const { SnackbarWrapper } = require(`../../../../../../components/${PLATFORM}/molecules/snackbar-wrapper`);
const {
    PAGE_TITLES,
    DELETE_ICON,
    MESSAGES,
    LAYOUTS,
    ROUTES,
    TABLE_ARROW_UP,
    TABLE_ARROW_DOWN,
} = require(`../../../../../../shared/${PLATFORM}/constants`);
const { STRINGS } = require(`../../../../../../shared/${PLATFORM}/constants/${LOCATION}/strings`)
const { DecisionPopup } = require(`../../../../../../components/${PLATFORM}/atoms/decision-popup`);

export const Screen = ({
    location,
    history,
    admin,
    edit,
    startLoader,
    stopLoader,
    updateChampionshipDetails,
    updateChampionshipStatus,
    championshipDetails,
    addChampionshipAdmin,
    deleteChampionshipAdmin,
    fetchChampionshipDetails
}) => {
    const [popupVisible, setPopVisible] = useState(false);
    const [addAdminVisible, setAddAdminVisible] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [credentials, setCredentials] = useState({
        name: '',
        email: '',
        role: 0
    });
    const [championshipData, setChampionshipData] = useState({
        name: championshipDetails && championshipDetails.name ? championshipDetails.name : '',
        description: championshipDetails && championshipDetails.description ? championshipDetails.description : '',
        startDate: championshipDetails && championshipDetails.startDate ? championshipDetails.startDate : new Date(),
        endDate: championshipDetails && championshipDetails.endDate ? championshipDetails.endDate : new Date()
    });
    const [popupData, setPopupData] = useState({
        popupContent: '',
        popupTitle: '',
        onConfirmation: () => { }
    });
    const [openSnackBar, setOpenSnackbar] = useState(false);
    const [snackbarData, setSnackBarData] = useState({
        variant: '',
        message: ''
    });
    const parameters = query.parse(location.search);
    // const { vehicleInfo, emergencyInfo, medicalInfo } = userDetails || {};
    useEffect(() => {
        startLoader();
        fetchChampionshipDetails(parameters.id, () => { stopLoader() }, () => { stopLoader() });

    }, [parameters.id]);

    useEffect(() => {
        setChampionshipData({
            name: championshipDetails && championshipDetails.name ? championshipDetails.name : '',
            description: championshipDetails && championshipDetails.description ? championshipDetails.description : '',
            startDate: championshipDetails && championshipDetails.startDate ? championshipDetails.startDate : new Date(),
            endDate: championshipDetails && championshipDetails.endDate ? championshipDetails.endDate : new Date()
        });
    }, [championshipDetails && Object.keys(championshipDetails).length])

    const handleDeleteChampionship = () => {
      updateChampionshipStatus(
        {
          championshipId: parameters.id,
          status: 2 //key-value to delete championship
        },
        (response) => {
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg
          });
          stopLoader();
          history.replace(ROUTES.CHAMPIONSHIPS);
        },
        (response) => {
          setSnackBarData({
            variant: response.status ? "success" : "error",
            message: response.msg
          });
          setOpenSnackbar(true);
          stopLoader();
        }
      );
    };
    return (
      <React.Fragment>
        <Helmet>
          <title>{PAGE_TITLES.championshipDetails}</title>
        </Helmet>
        <SnackbarWrapper
          visible={openSnackBar}
          onClose={() => setOpenSnackbar(false)}
          variant={snackbarData.variant}
          message={snackbarData.message}
        />
        {editForm && (
          <div className="dropzone-div">
            <div className="overlay"></div>
            <div className="dropzone-dialog">
              <div className="dropzone-content">
                <div className="dropzone-body">
                  <h3 className="mb-4 text-center">{STRINGS.UPDATE_CHAMPIONSHIP}</h3>

                  <EditChampionshipReduxForm
                    onCancel={() => {
                      setEditForm(false);
                      setChampionshipData({
                        name: championshipDetails && championshipDetails.name ? championshipDetails.name : "",
                        description:
                          championshipDetails && championshipDetails.description ? championshipDetails.description : "",
                        startDate:
                          championshipDetails && championshipDetails.startDate
                            ? championshipDetails.startDate
                            : new Date(),
                        endDate:
                          championshipDetails && championshipDetails.endDate ? championshipDetails.endDate : new Date()
                      });
                    }}
                    onSubmit={(formProps) => {
                      let start = formProps["start-date"];
                      // start = start.setHours(0, 0, 0);
                      let end = formProps["end-date"];
                      // end = end.setHours(23, 59, 59);
                      startLoader();

                      updateChampionshipDetails(
                        {
                          championshipId: parameters.id,
                          ...championshipData,
                          name: formProps["championship-name"],
                          description: formProps["details"],
                          startDate: new Date(start),
                          endDate: new Date(end)
                        },
                        (response) => {
                          setEditForm(false);
                          setSnackBarData({
                            variant: response.status ? "success" : "error",
                            message: response.msg
                          });
                          fetchChampionshipDetails(
                            parameters.id,
                            () => {
                              stopLoader();
                              setOpenSnackbar(true);
                            },
                            () => {
                              setSnackBarData({
                                variant: response.status ? "success" : "error",
                                message: response.msg
                              });
                              stopLoader();
                              setOpenSnackbar(true);
                            }
                          );
                        },
                        (response) => {
                          setSnackBarData({
                            variant: response.status ? "success" : "error",
                            message: response.msg
                          });
                          stopLoader();
                          setOpenSnackbar(true);
                        }
                      );
                    }}
                    championshipName={championshipData.name}
                    championshipDescription={championshipData.description}
                    championshipStartDate={championshipData.startDate}
                    championshipEndDate={championshipData.endDate}
                    onNameChange={(value) => {
                      setChampionshipData({
                        ...championshipData,
                        name: value
                      });
                    }}
                    onDescriptionChange={(value) => {
                      setChampionshipData({
                        ...championshipData,
                        description: value
                      });
                    }}
                    onStartDateChange={(value) => {
                      setChampionshipData({
                        ...championshipData,
                        startDate: value
                      });
                    }}
                    onEndDateChange={(value) => {
                      setChampionshipData({
                        ...championshipData,
                        endDate: value
                      });
                    }}
                    championshipData={championshipData}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {addAdminVisible && (
          <div className="dropzone-div">
            <div className="overlay"></div>
            <div className="dropzone-dialog">
              <div className="dropzone-content">
                <div className="dropzone-body">
                  <h3 className="mb-4 text-center">{STRINGS.ADD_CHAMPIONSHIP_ADMIN}</h3>
                  <AdminReduxForm
                    onSubmit={() => {
                      setAddAdminVisible(false);
                      startLoader();
                      addChampionshipAdmin(
                        {
                          championshipId: parameters.id,
                          name: admin.values[STRINGS.NAME_INPUT],
                          email: admin.values[STRINGS.EMAIL_INPUT_NAME]
                        },
                        (response) => {
                          setSnackBarData({
                            variant: response.status ? "success" : "error",
                            message: response.msg
                          });
                          setAddAdminVisible(false);
                          fetchChampionshipDetails(
                            parameters.id,
                            () => {
                              stopLoader();
                              setOpenSnackbar(true);
                            },
                            () => {
                              setSnackBarData({
                                variant: response.status ? "success" : "error",
                                message: response.msg
                              });
                              stopLoader();
                              setOpenSnackbar(true);
                            }
                          );
                          stopLoader();
                          setOpenSnackbar(true);
                        },
                        (response) => {
                          setSnackBarData({
                            variant: response.status ? "success" : "error",
                            message: response.msg
                          });
                          stopLoader();
                          setOpenSnackbar(true);
                        }
                      );
                    }}
                    name={credentials.name}
                    email={credentials.email}
                    role={credentials.role}
                    onEmailChange={(value) => {
                      setCredentials({
                        ...credentials,
                        email: value
                      });
                    }}
                    onNameChange={(value) => {
                      setCredentials({
                        ...credentials,
                        name: value
                      });
                    }}
                    onRoleChange={(value) => {
                      setCredentials({
                        ...credentials,
                        role: value
                      });
                    }}
                    onCancel={() => setAddAdminVisible(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="main-panel d-flex flex-column">
          <div className="content-wrapper">
            <DecisionPopup
              modalVisibility={popupVisible}
              dialogContent={popupData.popupContent}
              dialogTitle={popupData.popupTitle}
              confirmButtonTitle={popupData.confirmButton}
              rejectButtonTitle={popupData.rejectButton}
              toggleDialogModal={() => setPopVisible(!popupVisible)}
              onConfirmation={popupData.onConfirmation}
              onRejection={() => setPopVisible(false)}
            />

            <div className="card-title align-items-center justify-content-between flex-column flex-md-row bread_style">
              <h5>
                <label
                  onClick={() => {
                    history.replace(ROUTES.CHAMPIONSHIPS);
                  }}
                >
                  {STRINGS.CHAMPIONSHIP_LIST}
                </label>{" "}
                <span>{STRINGS.DETAILS}</span>
              </h5>
            </div>
            {true && (
              <div className={"card border-0 shadow height-vh"}>
                <div className={"card-body p-0"}>
                  {championshipDetails && (
                    <React.Fragment>
                      <div className="px-4 pt-4">
                        <div className="row align-items-center mb-2 flex-row-reverse">
                          <div className="col-md-6 text-center text-md-right">
                            {/********* Delete championship button ********/}
                            <button
                              type={"button"}
                              className={"btn btn-outline-secondary mx-2 mb-2 mb-sm-0"}
                              onClick={() => {
                                setPopupData({
                                  popupContent: STRINGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE,
                                  popupTitle: STRINGS.DELETE_CHAMPIONSHIP,
                                  onConfirmation: () => {
                                    setPopVisible(false);
                                    handleDeleteChampionship();
                                  }
                                });
                                setPopVisible(true);
                              }}
                            >
                              {STRINGS.DELETE_CHAMPIONSHIP}
                            </button>
                            {/********* ************************* ********/}
                            <button
                              type={"button"}
                              className={"btn btn-outline-secondary mx-2 mb-2 mb-sm-0"}
                              onClick={() => {
                                updateChampionshipStatus(
                                  {
                                    championshipId: parameters.id,
                                    status: championshipDetails.status ? 0 : 1
                                  },
                                  (response) => {
                                    setSnackBarData({
                                      variant: response.status ? "success" : "error",
                                      message: response.msg
                                    });
                                    stopLoader();
                                    fetchChampionshipDetails(
                                      parameters.id,
                                      () => {
                                        stopLoader();
                                        setOpenSnackbar(true);
                                      },
                                      () => {
                                        setSnackBarData({
                                          variant: response.status ? "success" : "error",
                                          message: response.msg
                                        });
                                        stopLoader();
                                        setOpenSnackbar(true);
                                      }
                                    );
                                  },
                                  (response) => {
                                    setSnackBarData({
                                      variant: response.status ? "success" : "error",
                                      message: response.msg
                                    });
                                    setOpenSnackbar(true);
                                    stopLoader();
                                  }
                                );
                              }}
                            >
                              {championshipDetails.status ? STRINGS.DISABLE_CHAMPIONSHIP : STRINGS.ENABLE_CHAMPIONSHIP}
                            </button>
                            <button
                              className="btn btn-outline-secondary mb-2 mb-sm-0 edit_btn"
                              onClick={() => {
                                setChampionshipData({
                                  name: championshipDetails && championshipDetails.name ? championshipDetails.name : "",
                                  description:
                                    championshipDetails && championshipDetails.description
                                      ? championshipDetails.description
                                      : "",
                                  startDate:
                                    championshipDetails && championshipDetails.startDate
                                      ? championshipDetails.startDate
                                      : new Date(),
                                  endDate:
                                    championshipDetails && championshipDetails.endDate
                                      ? championshipDetails.endDate
                                      : new Date()
                                });
                                setEditForm(true);
                              }}
                            >
                              <i className="edit-icon mr-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27.034"
                                  height="26.999"
                                  viewBox="0 0 27.034 26.999"
                                >
                                  <path
                                    id="Forma_1"
                                    d="M1738.813,731.506l5.5,5.519L1730.389,751l-5.493-5.519Zm9.675-1.331-2.451-2.462a2.433,2.433,0,0,0-3.442,0l-2.351,2.358,5.5,5.519,2.741-2.75A1.9,1.9,0,0,0,1748.489,730.175Zm-26.474,23.06a.629.629,0,0,0,.76.747l6.133-1.491-5.5-5.52Z"
                                    transform="translate(-1722 -727)"
                                    fill="#f42634"
                                    fill-rule="evenodd"
                                  />
                                </svg>
                              </i>{" "}
                              {STRINGS.EDIT}
                            </button>
                          </div>
                          <div className="col-md-6">
                            <h4 className="mt-2 mt-md-0">{championshipDetails.name}</h4>
                          </div>
                        </div>

                        <div className={"row champ_summary mb-3"}>
                          <div className={"col-md-12"}>
                            <h6>{STRINGS.DESCRIPTION}</h6>
                            <p>{championshipDetails.description ? championshipDetails.description : STRINGS.NA}</p>
                          </div>
                        </div>

                        <hr />
                        <div className={"dates-controls"}>
                          <div className={"dates-col"}>
                            <div className={"row"}>
                              <div className={"col-md-4"}>
                                <h6>{STRINGS.START_DATE}</h6>
                                <span>{moment(new Date(championshipDetails.startDate)).format("DD-MMM-YYYY")}</span>
                              </div>
                              <div className={"col-md-4"}>
                                <h6>{STRINGS.END_DATE}</h6>
                                <span>{moment(new Date(championshipDetails.endDate)).format("DD-MMM-YYYY")}</span>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>

                      <div className={"admin-wrappers"}>
                        <div className={"px-4"}>
                          <div className="card-title align-items-center justify-content-between flex-column flex-md-row flex-md-row-reverse">
                            <div className="group_btn mb-2 mb-md-0">
                              <button
                                className={"btn btn-lg btn-primary text-capitalize"}
                                onClick={() => {
                                  setAddAdminVisible(true);
                                }}
                              >
                                {STRINGS.ADD_ADMIN}
                              </button>
                            </div>
                            <h5>{STRINGS.ADMINS}</h5>
                          </div>
                        </div>

                        <div className={"admin-table"}>
                          <div className="table-responsive">
                            <CustomTable
                              rows={championshipDetails.admins}
                              rowsPerPage={championshipDetails.admins.length}
                              columns={LAYOUTS.adminTable}
                              activePage={1}
                              TABLE_ARROW_UP={TABLE_ARROW_UP}
                              TABLE_ARROW_DOWN={TABLE_ARROW_DOWN}
                              emptyDataMessage={MESSAGES.noRecordsFound}
                              EDIT_ICON={DELETE_ICON}
                              onEditClick={(id, index) => {
                                setPopupData({
                                  popupContent: STRINGS.DELETE_ADMIN_DESCRIPTION,
                                  popupTitle: STRINGS.DELETE_ADMIN,
                                  onConfirmation: () => {
                                    setPopVisible(false);
                                    startLoader();
                                    deleteChampionshipAdmin(
                                      {
                                        championshipId: parameters.id,
                                        adminId: id
                                      },
                                      (response) => {
                                        setSnackBarData({
                                          variant: response.status ? "success" : "error",
                                          message: response.msg
                                        });
                                        stopLoader();
                                        fetchChampionshipDetails(
                                          parameters.id,
                                          () => {
                                            stopLoader();
                                            setOpenSnackbar(true);
                                          },
                                          () => {
                                            setSnackBarData({
                                              variant: response.status ? "success" : "error",
                                              message: response.msg
                                            });
                                            stopLoader();
                                            setOpenSnackbar(true);
                                          }
                                        );
                                        setOpenSnackbar(true);
                                      },
                                      (response) => {
                                        setSnackBarData({
                                          variant: response.status ? "success" : "error",
                                          message: response.msg
                                        });
                                        stopLoader();
                                        setOpenSnackbar(true);
                                      }
                                    );
                                  }
                                });
                                setPopVisible(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
}