import { connect } from 'react-redux';
import { Screen } from "./screen";
const { defaultConfig: { PLATFORM } } = require(`../../../../../../config/default`);
const {
    stopLoader,
    startLoader,
    createEvent,
    deleteEvent,
    fetchEventsList,
    fetchChampionships
} = require(`../../../../../../redux/${PLATFORM}/actions`)

const mapStateToProps = (state) => {
    return ({
        eventList: state.EventReducer.eventList,
        platformType: state.CommonReducer.platformType,
        championshipData: state.CommonReducer.championshipData
    });
}
const mapDispatchToProps = (dispatch) => {
    return {
        createEvent: (data, success, failure) => dispatch(createEvent(data, success, failure)),
        fetchEventsList: (data, success, failure) => dispatch(fetchEventsList(data, success, failure)),
        fetchChampionships: (data, success, failure) => dispatch(fetchChampionships(data, success, failure)),
        deleteEvent: (data, success, failure) => dispatch(deleteEvent(data, success, failure)),
        startLoader: () => dispatch(startLoader()),
        stopLoader: () => dispatch(stopLoader())
    }
}
export const EventScreen = connect(mapStateToProps, mapDispatchToProps)(Screen);