import { takeLatest, all, put, delay } from "redux-saga/effects";

import {
    CREATE_EVENT,
    DELETE_EVENT,
    UPDATE_EVENT,
    FETCH_EVENT_LIST,
    FETCH_EVENT_DETAILS,
    FETCH_ATTENDEES_LIST,
    saveEventList,
    saveEventDetails,
    setAuthorization,
    saveAttendeesList
} from '../actions';
const { defaultConfig: { PLATFORM, LOCATION } } = require(`../../../config/default`);
const api = require(`../../../shared/${PLATFORM}/api`);
const {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest
} = require(`../../../helpers/${PLATFORM}`);
const { STATUS_CODE } = require(`../../../shared/${PLATFORM}/constants`);

function* createEvent({ data, success, failure }) {
    try {
        const response = yield postRequest({ API: `${api.URL.EVENT}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* fetchEventsList({ data, success, failure }) {
    try {
        if (!data.searchString) {
            delete data.searchString;
        }
        if (!data.championshipId) {
            delete data.championshipId;
        }
        const response = yield postRequest({ API: `${api.URL.EVENT_LIST}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data);
        }
        else {
            yield put(saveEventList(response.data.data))
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* deleteEvent({ data, success, failure }) {
    try {
        const response = yield deleteRequest({ API: `${api.URL.EVENT}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* fetchEventDetail({ id, success, failure }) {
    try {
        const response = yield getRequest({ API: `${api.URL.EVENT}/${id}` });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            yield put(saveEventDetails(response.data.data));
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
};

function* fetchEventAttendees({ data, success, failure }) {
    try {
        if (!data.searchString) {
            delete data.searchString;
        }
        if (!data.filterRole) {
            delete data.filterRole;
        }
        const response = yield postRequest({ API: `${api.URL.EVENT_ATTENDEES}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data);
        }
        else {
            yield put(saveAttendeesList(response.data.data))
            success(response.data);
        }
    }
    catch (error) {
        return;
    }
}

function* updateEventDetails({ data, success, failure }) {
    try {
        const response = yield putRequest({ API: `${api.URL.EVENT}`, DATA: data });
        if (response.status === STATUS_CODE.unAuthorized) {
            yield put(setAuthorization(null));
            return;
        }
        if (response.status !== STATUS_CODE.successful) {
            failure(response.data)
        }
        else {
            success(response.data)
        }
    }
    catch (error) {
        return;
    }
}

function* EventSaga() {
    yield all([
        takeLatest(CREATE_EVENT, createEvent),
        takeLatest(FETCH_EVENT_LIST, fetchEventsList),
        takeLatest(DELETE_EVENT, deleteEvent),
        takeLatest(FETCH_EVENT_DETAILS, fetchEventDetail),
        takeLatest(FETCH_ATTENDEES_LIST, fetchEventAttendees),
        takeLatest(UPDATE_EVENT, updateEventDetails)
    ]);
}

export default EventSaga;
